<template>
  <div>
    <div class="name">
      <span>项目名称：</span><span>{{data.projectName}}</span>
    </div>
    <div class="panel">
      <div class="label">标段名称</div>
      <div class="value3">
        {{ data.name }}
      </div>

      <div class="label no-border-bottom">开标地点</div>
      <div class="value1 no-border-bottom">
        {{ data.bidAddress}}
      </div>

      <div class="label no-border-bottom">开标时间</div>
      <div class="value2 no-border-bottom">
        {{ moment(data.bidDate).format('YYYY-MM-DD')}}
      </div>
    </div>

    <a-table bordered :dataSource="registers" :pagination="false">
        <a-table-column title="序号" width="50px" align="center">
        <template slot-scope="text, row, index">
            {{ index + 1 }}
        </template>
        </a-table-column>
        <a-table-column title="投标单位名称" align="center">
        <template slot-scope="text">
            <FileTableDialog
              title="报名文件"
              :msg="fileList"
              >
              <span class="user">
                {{text.user}}测试一下
              </span>
            </FileTableDialog>
          </template>
        </a-table-column>
        <a-table-column title="报价/万元" data-index="quoteFirst" align="center"/>
        <a-table-column title="二次报价/万元" data-index="quoteSec" align="center"/>
        <a-table-column title="付款方式是否相应" align="center">
          <template slot-scope="text">
            {{text.isPaymentResponse === true ? '是' : '否'}}
          </template>
        </a-table-column>
        <a-table-column title="资信承诺是否符合" align="center">
          <template slot-scope="text">
            {{text.isQualification === true ? '是' : '否'}}
          </template>
        </a-table-column>
        <a-table-column title="供货范围是否符合" align="center">
          <template slot-scope="text">
            {{text.isSupplyScope === true ? '是' : '否'}}
          </template>
        </a-table-column>
        <a-table-column title="商务/技术要求是否符合" align="center">
          <template slot-scope="text">
            {{text.isTecBusiness === true ? '是' : '否'}}
          </template>
        </a-table-column>
        <a-table-column title="投标单位答疑回复" data-index="responseQuestion" align="center"/>
        <a-table-column title="请选择中标公司" align="center">
          <template slot-scope="text">
            <a-icon type="check" v-if="text.ischecked"/>
          </template>
        </a-table-column>
    </a-table>

    <div class="panel" style="border-top: none;">
        <div class="value4">
            <div class="sign">
                评标意见汇总: {{data.juryRemarks}}
            </div>
            <div class="sign">
                评标委员签字:
            </div>
        </div>

        <div class="value2">
            纪监审计代表签字:
        </div>
        <div class="label">中标通知书</div>
        <div class="value3">
            <div v-if="Array.isArray(data.windBidFileList)">
                <a
                    v-for="(item) in data.windBidFileList"
                    :key="item.id"
                    target="_blank"
                    :href="item.completePath" style="display:block;"
                >
                    {{item.name}}
                </a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import FileTableDialog from '@/views/bidding/components/file-table-dialog'

import { mapState } from "vuex";
import moment from 'moment';
export default {
  components:{
    FileTableDialog
  },
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      console.log(this.detail.extra)
      
      return this.detail.extra ? this.detail.extra : {};
    },
    registers(){
      if(this.detail.extra.registers.find(item => item.userId === this.detail.extra.winbidUser)){
        this.detail.extra.registers.find(item => item.userId === this.detail.extra.winbidUser).ischecked = true;
      }
      return this.detail.extra.registers ? this.detail.extra.registers : [];
    },
    fileList() {
      return [
        {
          name: '投标文件',
          list: this.detail.extra.registers.filesBusinessList
        },
        {
          name: '投标保证金缴纳凭证',
          list: this.detail.extra.registers.filesBidBondCertList
        },
        {
          name: '标书购买凭证',
          list: this.detail.extra.registers.filesBidBuyCertList
        },
        {
          name: '其他文件',
          list: this.detail.extra.registers.filesOtherList
        },
      ];
    }
  },
  
  methods: {
    moment() {
      return moment();
    },
  }
};
</script>


<style lang="less" scoped>
.name{
    margin-bottom: 20px;
    span{
        font-weight: 800;
        font-size: 16px;
    }
    span:last-child{
        color: #1890ff;
    }
}

.user{
  cursor: pointer;
  color: #1890ff;
  font-weight: 800;
}
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div{
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }
  .no-border-bottom{
    border-bottom: none;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value4{
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    grid-column-start: 1;
    grid-column-end: 5;
  }
  .sign{
    margin-bottom: 30px;
  }
}

</style>

