<template>
    <div>
        <div class="panel">


            <div class="label">申请人</div>
            <div class="value1">{{ extra.name }}</div>
            <div class="label">申请部门</div>
            <div class="value2">{{ extra.deptName }}</div>

            <div class="label">补卡时间</div>
            <div class="value1">{{ extra.checkTime }}</div>

            <div class="label">补卡类型</div>
            <div class="value2">
                <a-tag v-if="extra.checkType === 'OnDuty'" color="blue">
                    上班
                </a-tag>
                <a-tag v-if="extra.checkType === 'OffDuty'" color="cyan">
                    下班
                </a-tag>
            </div>

            <div class="label">补卡原因</div>
            <div class="value3">
                {{ extra.remark }}
            </div>

        </div>
        <Padding />
    </div>
</template>
  
<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("approval", ["detail"]),
        extra() {
            return this.detail.extra ? this.detail.extra : {}
        },
    },
};
</script>
  
  
<style lang="less" scoped>
.panel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid #e8e8e8;

    &>div {
        padding: 8px;
        border-bottom: 1px solid #e8e8e8;
    }

    .label {
        background-color: #fafafa;
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
    }

    .value1 {
        grid-column-start: 2;
        grid-column-end: 4;
    }

    .value2 {
        grid-column-start: 5;
        grid-column-end: 7;
        border-right: 1px solid #e8e8e8;
    }

    .value3 {
        grid-column-start: 2;
        grid-column-end: 7;
        border-right: 1px solid #e8e8e8;
    }
}
</style>
  
  