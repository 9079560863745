<template>
  <div>
    <lend-info />
    <lend-table />
  </div>
</template>

<script>
import LendInfo from "./lend-info.vue";
import LendTable from "./lend-table.vue";
export default {
  components: {
    LendInfo,
    LendTable,
  },
};
</script>