<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">申领人</div>
      <div class="">
        {{ detail.extra.userName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">申领部门</div>
      <div class="">
        {{ detail.extra.deptName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">申领总金额(元)</div>
      <div class="" style="color: #1890ff">
        {{ detail.extra.totalPrice }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">申领事由</div>
      <div class="">
        {{ detail.extra.remarks }}
      </div>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
  },
};
</script>

