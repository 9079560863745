<template>
  <div v-if="isDelayed">
    <Title>延期申请</Title>
    <a-form-model
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 22 }"
      :colon="false"
    >
      <a-form-model-item label="申请日期">
        <a-date-picker
          :allowClear="false"
          :disabled="reject"
          style="width: 100%"
          placeholder=""
          :value="delayedDateObj"
          @change="handleDateChange"
        />
      </a-form-model-item>
      <a-form-model-item label="申请说明">
        <a-textarea
          :disabled="reject"
          :auto-size="{ minRows: 3 }"
          :value="delayRemarks"
          @change="handleChange"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import Title from "../Title";
import moment from "moment";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    Title,
  },

  computed: {
    ...mapState("approval", ["detail"]),
    ...mapGetters("approval", [
      "isDelayed",
      "disabled",
      "isSuperviseWorker",
      "delayedDate",
      "delayRemarks",
    ]),

    delayedDateObj() {
      if (this.delayedDate) {
        return moment(this.delayedDate);
      } else {
        return null;
      }
    },

    reject() {
      // 延期和完成情况都得是工作者去修改，发起者不能改，只能审核
      return this.disabled || !this.isSuperviseWorker;
    },
  },

  methods: {
    ...mapMutations("approval", ["setDelayedDate", "setDelayRemarks"]),

    handleDateChange(date) {
      if (date) {
        this.setDelayedDate(date.format("YYYY-MM-DD HH:mm:ss"));
      }
    },
    handleChange(e) {
      this.setDelayRemarks(e.target.value);
    },
  },
};
</script>