<template>
  <div>
    <Title>移交档案清单</Title>
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="档案名称" data-index="name"> </a-table-column>

      <a-table-column title="类型">
        <template slot-scope="text">
          {{ getTypeText(text.type) }}
        </template>
      </a-table-column>
      <a-table-column title="保密级别" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="secretLevel" :dictValue="text.level" />
        </template>
      </a-table-column>

      <a-table-column title="页数" data-index="pageNumber"> </a-table-column>

      <a-table-column title="保管期限(月)">
        <template slot-scope="text">
          {{ text.duration ? text.duration : "长期" }}
        </template>
      </a-table-column>
      <a-table-column title="关键词" data-index="keyWords"> </a-table-column>
      <a-table-column key="file" title="附件">
        <template slot-scope="text">
          <DownloadLink :list="text.fileList" />
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import archiveType from "@/mixins/archive-type";
import Title from "../Title";
import DownloadLink from "@/components/download-link";
import { mapState } from "vuex";

export default {
  mixins: [archiveType],

  components: {
    Title,
    DownloadLink,
  },

  computed: {
    ...mapState("approval", ["detail"]),

    list() {
      return this.detail.extra?.archives ?? [];
    },
  },
};
</script>