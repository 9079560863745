<template>
  <div class="upload">
    <input
      ref="upload"
      type="file"
      accept="image/*"
      @change="onChange"
      :multiple="true"
      style="display: none"
    />
    <div class="item" v-for="(item,index) in list" :key="item">
      <img :src="item" class="image" alt />
      <div class="remove" @click="remove(index)">
        <a-icon type="close" />
      </div>
    </div>
    <div class="item" @click="onClick">
      <div class="add">
        <a-icon :type="loading ? 'loading' : 'plus'" />
      </div>
    </div>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      this.loading = true;

      uploadFile(data)
        .then(res => {
          console.log("upload res", res);
          if (Array.isArray(res)) {
            this.list.push(...res.map(item => item.completePath));
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },

    remove(index) {
      this.list.splice(index, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .item {
    border: 1px solid #e8e8e8;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    height: 120px;
    width: 120px;

    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    .remove {
      position: absolute;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      background-color: rgba(0, 0, 0, 0.3);
      display: none;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
    }
  }
  .item:hover {
    .remove {
      display: flex;
    }
  }
  .add {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
}
</style>