<template>
  <div class="description">
    <template v-for="(item, index) in filteredList">
      <div v-if="item.assignee !== 'none'" class="label" :key="index + item.name">{{ item.name }}</div>
      <div v-if="item.assignee !== 'none'" class="value" :key="index + item.userName">
        <div class="header">
          <div class="name">{{ item.userName }}</div>
          <div class="status">
            <DataDictFinder
              dictType="taskStatus"
              :dictValue="item.status"
              iconType="badge"
              badgeType="color"
            />
          </div>
        </div>

        <div class='between footer'>
          <div v-if='!isPrinting'>
            <span v-if="item.comment === '同意'">同意</span>
            <a href='#' v-else-if='item.comment' @click.prevent='openDetail(item)'>审批意见</a>
          </div>
     <div class="footer" :class="isPrinting ? 'print' : ''">
          {{ item.endTime ? item.endTime : item.startTime }}
        </div>
        </div>
   
      </div>
    </template>

      <a-modal :visible="visible" :title="activeItem.name + '-' + activeItem.userName" :footer="null"  @cancel="visible = false">
        {{activeItem.comment}}
  </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    type: {
      type: String,
      default: "all",
    },
    isPrinting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      visible: false,
      activeItem: {},
    }
  },

  computed: {
    ...mapState("approval", ["history"]),
    list() {
      return this.history;
    },
    filteredList() {
      if (this.type === "all") {
        return this.list;
      } else {
        return this.list.filter((item) => item.status === "COMPLETED");
      }
    },
  },

  methods: {
    openDetail(item) {
      this.visible = true;
      this.activeItem = item;
    }
  }
};
</script>

<style lang="less" scoped>
.description {
  border: 1px solid #e8e8e8;
  border-bottom-width: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    padding: 12px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .value {
    grid-column-start: 2;
    grid-column-end: 4;
    padding: 12px;
    border-bottom: 1px solid #e8e8e8;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4px;
      .name {
        font-weight: bold;
      }
      .status {
        font-weight: bold;
      }
    }
    .footer {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>