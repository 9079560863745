<template>
  <div>
    <div class="panel">
      <div class="label">申请人</div>
      <div class="value1">{{ extra.name }}</div>
      <div class="label">申请部门</div>
      <div class="value2">{{ extra.deptName }}</div>
      <template v-if="extra.type == 'overtime' || extra.type == 'days_off'">
        <div class="label">时间段</div>
        <div class="value3">
          <div v-for="item in JSON.parse(extra.extra)" style="margin-bottom: 10px;">
            <span style="margin-right: 10px;">{{ item.date }}</span> {{ item.startTime }} ~ {{ item.endTime }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="label">开始日期</div>
        <div class="value1">{{ extra.startTime }}</div>
        <div class="label">结束日期</div>
        <div class="value2">{{ extra.endTime }}</div>
      </template>
      <div class="label">类型</div>
      <div class="value1">
        <a-tag color="green">{{ getName(extra.type) }}</a-tag>
      </div>
      <div class="label">总计时长</div>
      <div class="value2" style="color: #1890ff" v-if="extra.type == 'overtime' || extra.type == 'days_off'">
        {{ (extra.duration/60).toFixed(2) }}小时
      </div>
      <div class="value2" style="color: #1890ff" v-else>
        {{ extra.duration }}
        {{ extra.durationUnit === "hour" ? "小时" : "天" }}
      </div>

      <template v-if="extra.option">
        <div class="label">备注</div>
        <div class="value3">{{ extra.option }}</div>
      </template>

      <template v-if="extra.type === 'on_business_out'">
        <div class="label">交通工具(往)</div>
        <div class="value1">{{ extra.trafficTo }}</div>
        <div class="label">交通工具(返)</div>
        <div class="value2">{{ extra.trafficBack }}</div>

        <div class="label">费用预算</div>
        <div class="value3">{{ extra.cost }}元</div>

        <div class="label">同行人员</div>
        <div class="value3">{{ renderStaff(extra.staff) }}</div>
      </template>

      <template
        v-if="
          extra.type === 'on_business_in' || extra.type === 'on_business_out'
        "
      >
        <div class="label">
          {{ extra.type === "on_business_out" ? "出差地点" : "公出地点" }}
        </div>
        <div class="value3">{{ extra.targetAddr }}</div>
      </template>

      <div class="label">
        {{
          extra.type === "on_business_out"
            ? "出差事由"
            : extra.type === "on_business_in"
            ? "公出事由"
            : "情况说明"
        }}
      </div>
      <div class="value3">{{ extra.remark }}</div>
      <div class="label">是否驻场</div>
      <div class="value3">
        <span v-if="extra.isResident">是</span>
        <span v-else>否</span>
      </div>
      <div class="label">附件</div>
      <div class="value3">
        <span v-for="(file, index) in extra.attachments" :key="file">
          <a :href="file" target="_blank">{{ $getFileName(file) }}</a>
          <span
            style="color: #1890ff"
            v-if="index !== extra.attachments.length - 1"
            >,</span
          >
        </span>
      </div>
    </div>
    <Padding />
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { getName } from "@/utils/clock";

export default {
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra
        ? {
            ...this.detail.extra,
            attachments: this.detail.extra.attachments
              ? this.detail.extra.attachments.split(",")
              : [],
          }
        : {};
    },
  },
  methods: {
    getName,

    renderStaff(str) {
      let msg = "";
      try {
        const list = JSON.parse(str);
        msg = list.map((item) => item.userName).join("，");
      } catch (error) {
        msg = str;
      }

      return msg;
    },
  },
};
</script>
  
  
<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>
  
  