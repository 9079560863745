<template>
  <div>
    <div class="panel">
      <div class="label">工程名称</div>
      <div class="value1">
        {{ data.projectName }}
      </div>

      <div class="label">项目编号</div>
      <div class="value2">
        {{ data.designCode}}
      </div>

      <div class="label">工程名称</div>
      <div class="value3">
        {{ data.constructionName}}
      </div>

      <div class="label">受罚单位</div>
      <div class="value1">
        {{ data.penaltyCompany }}
      </div>

      <div class="label">违章地点</div>
      <div class="value2">
        {{ data.accidentSite}}
      </div>

      <div class="label">罚款金额（万）</div>
      <div class="value1">
        {{ data.penaltyAmount }}
      </div>

      <div class="label">违章（单位）人员</div>
      <div class="value2">
        {{ data.accidentObject}}
      </div>

      <div class="label">附件</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachmentList)">
          <a v-for="(item) in data.attachmentList"
             :key="item.id"
             target="_blank"
             :href="item.completePath"
             style="display:block;">
            {{item.name}}
          </a>
        </div>
      </div>
    </div>

    <approval-detail></approval-detail>

  </div>
</template>

<script>
import ApprovalDetail from "./components/approval-detail.vue";
import { mapState } from "vuex";

export default {
  components: {
    ApprovalDetail,
  },

  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.constructionData
        ? this.detail.extra.constructionData
        : {};
    },
    attachmentList() {
      // 原表单的附件列表
      return this.data.attachmentList.filter(
        (item) => item.type !== "approvalDetail"
      );
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }
  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>

