//计算比例，且格式化为两位小数.
// 参数val为分子，sum为分母
import accurate from "accurate";
import _ from 'lodash'

export function formatRatio(val, sum) {
      //若计算结果为NaN或Infinity，置为0.否则保留两位小数
  let res = accurate.expr((val / sum) * 100);
  if (isNaN(res) || res === Infinity || res === -Infinity) {
    return 0;
  }
  return _.round(res,2); //四舍五入保留两位小数
}