<template>
  <div>
    <a-button size="small" @click="visible = true">批量添加发票</a-button>

    <a-modal :visible="visible" title="开票" :footer="null" @cancel="cancel">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="发票号码">
          <a-input
            v-decorator="[
              'invoiceCode',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="发票数量(个)">
          <a-input-number
            :formatter="$inputNumberFormat"
            v-decorator="[
              'num',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="发票金额(元)">
          <a-input-number
            :formatter="$inputNumberFormat"
            v-decorator="[
              'invoicePrice',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="税点">
          <a-input-number
            :min="0"
            :max="100"
            :formatter="$ratioFormatter"
            v-decorator="[
              'taxRate',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="旧发票号码">
          <InvoiceSelector
            @getValue="
              (obj) => {
                selectedInvoice = obj;
              }
            "
            :record="selectedInvoice"
          />
        </a-form-item>

        <a-form-item label="业务内容">
          <a-input
            v-decorator="[
              'business',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item>
          <span slot="label" class="">附件</span>
          <FileUpload @uploaded="uploaded">
            <a-button type="primary" icon="upload"> 上传附件 </a-button>
          </FileUpload>

          <div class="link-list">
            <div class="link" v-for="(item, index) in fileList" :key="item">
              <a target="_blank" download :href="item">{{
                $getFileName(item)
              }}</a>
              <div class="control" @click="deleteFile(index)">
                <a-icon type="delete" />
              </div>
            </div>
          </div>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import InvoiceSelector from "@/components/invoice-selector";
import FileUpload from "@/components/file-upload";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    InvoiceSelector,
    FileUpload,
  },

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      selectedInvoice: {}, // 选中的红冲票
      fileList: [],
    };
  },

  computed: {
    ...mapGetters("approval", ["invoiceList"]),
  },

  methods: {
    ...mapMutations("approval", ["addInvoice"]),

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // const templateCode = values.invoiceCode.substring(0, values.invoiceCode.length -1)
          // const index = parseInt( values.invoiceCode[values.invoiceCode.length -1])

          const code = parseInt(values.invoiceCode);

          const arr = [];
          for (let i = 0; i < values.num; i++) {
            arr.push({
              ...values,
              invoiceCode: String(code + i).padStart(8, "0"),
              oldInvoiceCode: this.selectedInvoice.invoiceCode,
              attachments: this.fileList.join(","),
            });
          }
          this.addInvoice([...this.invoiceList, ...arr]);
          this.cancel();
        }
      });
    },

    uploaded(list) {
      this.fileList = [
        ...this.fileList,
        ...list.map((item) => item.completePath),
      ];
    },

    deleteFile(index) {
      this.fileList.splice(index, 1);
    },

    cancel() {
      this.visible = false;
      this.form.resetFields();
      this.selectedInvoice = {};
      this.fileList = [];
    },
  },
};
</script>


<style lang="less" scoped>
.link-list {
  .link {
    margin: 4px 0;
    padding: 0 12px;
    background-color: #f9f9f9;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .control {
      height: 16px;
      width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .control:hover {
      color: #1890ff;
    }
  }
}
</style>