<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal title="开票详情" :visible="visible" @cancel="cancel" :footer="null" width="800px">
      <a-table bordered :data-source="filteredList" rowKey="uniqKey" :pagination="false">
        <a-table-column title="发票号码" data-index="code" align="center"></a-table-column>

        <a-table-column title="发票类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="invoiceType" :dictValue="text.type" />
          </template>
        </a-table-column>
        <a-table-column title="开票日期" align="center">
          <template slot-scope="text">{{ text.date ? text.date : '--' }}</template>
        </a-table-column>

        <a-table-column title="税点" data-index="ratio" align="right">
          <template slot-scope="ratio">{{ ratio }}%</template>
        </a-table-column>
        <a-table-column title="合计金额(元)" align="center">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ $numberFormat(text.amount) }}</span>
          </template>
        </a-table-column>

        <a-table-column title="发票附件" align="center">
          <template slot-scope="text">
            <span v-for="(item, index) in getAttachments( text)" :key="index">
              <a :href="item.completePath" target="_blank">{{item.name}}</a>
              <span v-if="index !== getAttachments(text).length - 1">,</span>
            </span>
          </template>
        </a-table-column>
      </a-table>

      <div
        style="font-weight: bold;margin-top: 16px;font-size: 14px;"
      >合计金额(元)：{{ $numberFormat(total) }}</div>
    </a-modal>
  </div>
</template>

<script>
import accurate from "accurate";
import { mapState } from "vuex";
export default {
  props: {
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    balanceData() {
      return this.detail.extra.balanceData ? this.detail.extra.balanceData : {};
    },
    contract() {
      return this.balanceData.contract ? this.balanceData.contract : {};
    },

    invoiceList() {
      return Array.isArray(this.contract.invoiceList)
        ? this.contract.invoiceList
        : [];
    },

    filteredList() {
      return this.invoiceList.filter(item => item.category === this.type);
    },

    total() {
      let sum = 0;
      this.filteredList.forEach(item => {
        sum = accurate.add(sum, item.amount);
      });
      return sum;
    }
  },

  methods: {
    getAttachments(text) {
      try {
        return JSON.parse(text.attachments);
      } catch (error) {
        return [];
      }
    },
    cancel() {
      this.visible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.input-tip {
  font-size: 12px;
  cursor: pointer;
  color: #1890ff;
}
</style>
