<template>
  <div>
    <div class="file-title">
      <div class="line"></div>
      <div class="title">审批详情</div>
    </div>

    <a-form-item :label-col="{ span: 3 }"
                 :wrapper-col="{ span: 21 }"
                 class="file-container">
      <span slot="label"
            class="required">经决会意见</span>
      <div class="link-list">
        <a-checkbox-group :value="selectedFileList"
                          @change="onSelectFile">
          <div class="link"
               v-for="item in approvalList"
               :key="item.id">
            <a-checkbox :value="item.id">
              <a target="_blank"
                 download
                 :href="item.completePath">{{
                  item.name
                }}</a>
            </a-checkbox>
          </div>
        </a-checkbox-group>

        <div class="control-bar">
          <FileUpload @uploaded="uploaded">
            <div class="control">
              <a-icon type="upload" />
            </div>
          </FileUpload>

          <div class="control"
               @click="deleteFile">
            <a-icon type="delete" />
          </div>
        </div>
      </div>
    </a-form-item>
  </div>
</template>
<script>
import FileUpload from "@/components/file-upload";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      selectedFileList: [],
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    ...mapState("approvalDetail", ["approvalList"]), // 审批详情的附件列表
    data() {
      return this.detail.extra.designData ||
        this.detail.extra.constructionData ||
        this.detail.extra.planningData
        ? this.detail.extra.designData ||
            this.detail.extra.constructionData ||
            this.detail.extra.planningData
        : {};
    },
  },
  mounted() {
    // 回显审批详情
    console.log(this.data);

    // 返回只包含type为approvalDetail的元素
    let fileList =
      this.data.attachmentList?.filter((item) => {
        return item.type === "approvalDetail";
      }) || [];
    this.setAttachment(fileList);
  },
  methods: {
    ...mapMutations("approvalDetail", ["setAttachment", "delApprovalFile"]),
    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      console.log(list);
      let fileList = [...this.approvalList, ...list];
      this.setAttachment(fileList);
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.approvalList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.delApprovalFile(index);
        }
      });
      this.selectedFileList = [];
    },
  },
  destroyed() {
    this.setAttachment([]);
  },
};
</script>
<style lang="less" scoped>
.file-container {
  display: flex;
  align-items: center;
}
.file-title {
  width: 15%;
  display: flex;
  margin: 20px 0px 10px;
  .line {
    width: 5px;
    margin-right: 10px;
    background-color: #1890ff;
  }
}
</style>
