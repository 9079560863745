<template>
  <div class="wrapper">
    <total-output-value
      :value="projectInfo.totalAmount"
      :cost="projectInfo.js3Cost"
    />
    <StageList :list="majorList" />
    <DepartmentList :list="deptList" />
    <MajorList :list="subMajorList" :majorList="majorList" />
    <StaffList :list="staffOutputList" />
    <MajorFileList :list="fileList" />
    <FileList :list="fileList" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TotalOutputValue from "./components/total-output-value.vue";
import StaffList from "./components/staff-list.vue";
import StageList from "./components/stage-list.vue";
import DepartmentList from "./components/department-list.vue";
import MajorList from "./components/major-list.vue";
import MajorFileList from "./components/major-file-list.vue";
import FileList from "./components/file-list.vue";

export default {
  components: {
    TotalOutputValue,
    StaffList,
    StageList,
    MajorList,
    MajorFileList,
    FileList,
    DepartmentList,
  },

  computed: {
    ...mapState("approval", ["detail"]),

    projectInfo() {
      if (this.detail.extra && this.detail.extra.staffConfigData) {
        return this.detail.extra.staffConfigData;
      }
      return {};
    }, // 项目的基本信息

    majorList() {
      if (
        this.detail.extra &&
        this.detail.extra.staffConfigData &&
        Array.isArray(this.detail.extra.staffConfigData.majorList)
      ) {
        return this.detail.extra.staffConfigData.majorList;
      }
      return [];
    }, // 按照子阶段分类的可分配数值列表
    staffOutputList() {
      if (
        this.detail.extra &&
        this.detail.extra.staffConfigData &&
        Array.isArray(this.detail.extra.staffConfigData.staffOutputList)
      ) {
        return this.detail.extra.staffConfigData.staffOutputList;
      }
      return [];
    }, // 每个人的可分配数值列表
    subMajorList() {
      if (
        this.detail.extra &&
        this.detail.extra.staffConfigData &&
        Array.isArray(this.detail.extra.staffConfigData.subMajorList)
      ) {
        return this.detail.extra.staffConfigData.subMajorList;
      }
      return [];
    }, // 按照专业分类的可分配数值列表
    deptList() {
      if (
        this.detail.extra &&
        this.detail.extra.staffConfigData &&
        Array.isArray(this.detail.extra.staffConfigData.deptList)
      ) {
        return this.detail.extra.staffConfigData.deptList;
      }
      return [];
    }, // 部门可分配数值汇总
    fileList() {
      if (
        this.detail.extra &&
        this.detail.extra.staffConfigData &&
        Array.isArray(this.detail.extra.staffConfigData.progressList)
      ) {
        return this.detail.extra.staffConfigData.progressList;
      }
      return [];
    },
  },
};
</script>