<template>
  <div>
    <div class="panel">
      <div class="label">工程名称</div>
      <div class="value1">
        {{ data.projectName }}
      </div>

      <div class="label">项目编号</div>
      <div class="value2">
        {{ data.designCode }}
      </div>

      <div class="label">合同名称</div>
      <div class="value1">
        {{ subpackPay.contractName }}
      </div>

      <div class="label">合同编号</div>
      <div class="value2">
        {{ subpackPay.contractCode }}
      </div>

      <div class="label">分包方</div>
      <div class="value1">
        {{ subpackPay.contractor }}
      </div>

      <div class="label">合同额(万)</div>
      <div class="value2">
        {{ subpackPay.contractAmt }}
      </div>

      <div class="label">付款类型</div>
      <div class="value3">
        <DataDictFinder dictType="epc_pay_type" :dictValue="subpackPay.payType" iconType="text" />
      </div>
    </div>

    <subRable title="总承包项目分包付款表" :defaultData="subPayList" :sumObj="sumObj" />

    <div class="panel" style="margin-top:10px">
      <div class="label">附件</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachmentList)">
          <a v-for="(item) in data.attachmentList" :key="item.id" target="_blank" :href="item.completePath"
            style="display:block;">
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import subRable from "./components/table.vue";
import { mapState } from "vuex";
export default {
  components: {
    subRable,
  },
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.balanceData ? this.detail.extra.balanceData : {};
    },
    subpackPay() {
      return this.data.subpackPay;
    },
    subPayList() {
      return this.subpackPay.subPayList || [];
    },
    sumObj() {
      var paidAmt = this.subPayList.reduce((pre, cur) => {
        return pre + (cur["paidAmt"] || 0);
      }, 0);

      return {
        paidAmt,
        paidRatio: (paidAmt / this.subpackPay.contractAmt) * 100,
        totalPaidAmt: this.subpackPay.totalPaidAmt || 0,
        totalPaidRatio: this.subpackPay.totalPaidRatio || 0,
      };
    },
  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;
  margin-bottom: 8px;

  &>div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .title {
    grid-column-start: 1;
    grid-column-end: 7;
    background-color: #afc9ed;
    text-align: center;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>
