<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <div class="extra-content">
          <div @click="logDetailVisible = true">
            <img
              src="https://s.upapi.cn/2022/07/08/f7a3a1fc434d393999ce688ab33a3540/progress.png"
              alt
            />
            <div>流程跟踪</div>
          </div>
          <div @click="download">
            <img src="https://s.upapi.cn/2023/08/31/18981de54cbe84031c8acb6151da5274/下载.png" alt />
            <div style="color: #00d96c">下载</div>
          </div>
          <div @click="print">
            <img src="https://s.upapi.cn/2022/07/08/f7a3a1fc434d393999ce688ab33a3540/print.png" alt />
            <div style="color: #ff8000">打印</div>
          </div>
        </div>
      </template>
    </Pane>

    <LogDetail :visible="logDetailVisible" @cancel="logDetailVisible = false" />

    <div class="container" id="container" :class="isPrinting ? 'print' : ''">
      <div class="detail">
        <Header />

        <a-skeleton :loading="loading" :paragraph="{ rows: 4 }">
          <div
            class="num"
          >流水号：{{ detail.extra && detail.extra.seqNum ? detail.extra.seqNum : "--" }}</div>

          <ContractInfoTable v-if="isContract || isProgress || isAllocate || isAdjust" />
        </a-skeleton>

        <div v-if="loading">
          <Padding />
        </div>

        <a-skeleton :loading="loading" :paragraph="{ rows: 4 }">
          <ContractInfo v-if="isContract" />
          <Progress v-if="isProgress" />
          <Phase v-if="isAllocate" />
          <template v-if="isSupervise">
            <SuperviseInfoTable />
            <EditSuperviseTask />
            <Delay />
          </template>
          <template v-if="isInvoice">
            <Invoice />
          </template>

          <ArchiveBorrow v-if="isArchiveBorrow" />
          <ArchiveTransfer v-if="isArchiveTransfer" />
          <Material v-if="isMaterial" />

          <ContractRegister v-if="isContractRegister" />
          <ContractAdjust v-if="isContractAdjust" />
          <SubpackageRegister v-if="isSubpackageRegister" />
          <FrameWorkContractRegister v-if="isFrameworkContractRegister" />

          <!-- epc相关 -->
          <EpcOrganize v-if="isEpcOrganize" />
          <EpcBidBudget v-if="isEpcBidbudget" />
          <BudgetComment v-if="isBudgetComment" />

          <ManageObjective v-if="isManageObject" />
          <ManagePlan v-if="isManagePlan" />
          <ManagePlanChange v-if="isManagePlanChange" />
          <ConstructionSchedule v-if="isConstructionSchedule" />
          <ConstructionScheduleChange v-if="isConstructionScheduleChange" />
          <ConstructionBudget v-if="isConstructionBudget" />
          <PlanBudgetComment v-if="isPlanBudgetComment" />

          <ResultConfirm v-if="isResultConfirm" />
          <ChangeReview v-if="isChangeReview" />
          <ChangeApproval v-if="isChangeApproval" />
          <OrganizeDesign v-if="isOrganizeDesign" />
          <QualityInspection v-if="isQualityInspection" />
          <SafeInspection v-if="isSafeInspection" />
          <DataInspection v-if="isDataInspection" />
          <Claim v-if="isClaim" />
          <CounterClaim v-if="isCounterClaim" />
          <Visa v-if="isVisa" />
          <Accident v-if="isAccident" />
          <Violation v-if="isViolation" />
          <TrailOperationPlan v-if="isTrailOperationPlan" />
          <PerformanceAssess v-if="isPerformAssess" />

          <MasterPlan v-if="isMasterPlan" />
          <PlanAdjust v-if="isPlanAdjust" />
          <PublicBidding v-if="isPublicBidding" />
          <InviteBidding v-if="isInviteBidding" />
          <CompetitionArgue v-if="isCompetitionArgue" />
          <PreBidding v-if="isPreBidding" />
          <NotPreBidding v-if="isNotPreBidding" />
          <Inquiry v-if="isInquiry" />
          <EmergancyPurchase v-if="isEmergancyPurchase" />
          <Transfer v-if="isTransfer" />
          <ReturnToFactory v-if="isReturnToFactory" />

          <BalanceAccount v-if="isBalanceAccount" />
          <RemMatHandle v-if="isRemMatHandle" />

          <ComSettle v-if="isComSettle" />
          <ComSettleComment v-if="isComSettleComment" />
          <SubSettle v-if="isSubSettle" />
          <SubSettleComment v-if="isSubSettleComment" />
          <CosExeRep v-if="isCosExeRep" />
          <CosExeRepComment v-if="isCosExeRepComment" />

          <SubpackPay v-if="isSubpackPay" />
          <SubpackPayApp v-if="isSubpackPayApp" />

          <!-- 招采相关 -->
          <BiddingResult v-if="isBiddingResult" />
          <BiddingConfirm v-if="isBiddingConfirm" />
          <BiddingRegister v-if="isBiddingRegister" />
          <AddBidding v-if="isAddBidding" />

          <BidRequestEPCLeader
            v-if="isPutOnFileRequest || isBidRequestEPCLeaderNot || isBidRequestEPCLeader || isBidRequestDesign"
          />

          <!-- 考勤 -->
          <Leave v-if="isLeave" />
          <Replace v-if="isReplace" />

          <!-- 技术质量-安全生产 -->
          <SecurityCheckPlan v-if="ofc_quality_plan" />
          <SecurityCheckWork v-if="ofc_quality_work" />
          <SecurityCheckRectify v-if="ofc_quality_rectify" />
          <SecurityImportBudget v-if="ofc_quality_fee_budget" />
          <SecurityImportMoney v-if="ofc_quality_fee_use" />
          <!-- 部门协作单 -->
          <Collaborate v-if="safety_collaborate" />

          <DineCard v-if="dine_card_temp" />
          
          <RiskEstimate v-if="ofc_quality_risk" />
          <SafeDailyWork v-if="ofc_quality_meeting_issue" />
          <SafeDailyMeeting v-if="ofc_quality_meeting_decision" />
          <Car v-if="oa_use_car" />
          <Resource v-if="oa_technical_information_grant" />
          <Intern v-if="user_intern" />
        </a-skeleton>
      </div>

      <div class="tool">
        <div class="form" v-if="!isPrinting">
          <Card :icon="approval4Image" title="审批">
            <a-skeleton :loading="loading">
              <Form @close="close" />
            </a-skeleton>
          </Card>
        </div>

        <div class="process" :class="isPrinting ? 'all-rows' : ''">
          <Card :icon="approval1Image" title="审批意见">
            <template v-slot:extra>
              <a-space>
                <a
                  href="#"
                  @click.prevent="logType = 'all'"
                  :class="logType === 'all' ? 'active' : ''"
                >全部</a>
                <a
                  href="#"
                  @click.prevent="logType = 'pass'"
                  :class="logType === 'pass' ? 'active' : ''"
                >通过</a>
              </a-space>
            </template>
            <a-skeleton :loading="loading">
              <!-- <History /> -->
              <Log :type="logType" :isPrinting="isPrinting" />
            </a-skeleton>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";

import Header from "../components/Header";
import ContractInfoTable from "../components/ContractInfoTable";
import ContractInfo from "../components/ContractInfo";
import Progress from "../components/Progress";
import Phase from "../components/Phase";

import SuperviseInfoTable from "../components/SuperviseInfoTable";
import EditSuperviseTask from "../components/EditSuperviseTask";
import Delay from "../components/Delay";

import Invoice from "../components/Invoice";

import ArchiveBorrow from "../components/archives/borrow.vue";
import ArchiveTransfer from "../components/archives/transfer.vue";

import Material from "../components/material";
import ContractRegister from "../components/contract-register";
import ContractAdjust from "../components/contract-adjust";
import SubpackageRegister from "../components/subpackage-register";
import FrameWorkContractRegister from "../components/framework-contract-register";

// epc项目
import EpcOrganize from "../components/epc/project-start/organize";
import EpcBidBudget from "../components/epc/project-start/bidbudget";
import BudgetComment from "../components/epc/project-start/budgetComment";

import ManageObjective from "../components/epc/plan/manage-objective";
import ManagePlan from "../components/epc/plan/manage-plan";
import ManagePlanChange from "../components/epc/plan/manage-plan-change";
import ConstructionSchedule from "../components/epc/plan/construction-schedule";
import ConstructionScheduleChange from "../components/epc/plan/construction-schedule-change";
import ConstructionBudget from "../components/epc/plan/budget";
import PlanBudgetComment from "../components/epc/plan/BudgetComment";

import ResultConfirm from "../components/epc/design/result-confirm";
import ChangeApproval from "../components/epc/design/change-approval";
import ChangeReview from "../components/epc/design/change-review";
import OrganizeDesign from "../components/epc/construction/organize-design";
import QualityInspection from "../components/epc/construction/quality-inspection";
import SafeInspection from "../components/epc/construction/safe-inspection";
import DataInspection from "../components/epc/construction/data-inspection";
import Claim from "../components/epc/construction/claim";
import CounterClaim from "../components/epc/construction/counter-claim";
import Visa from "../components/epc/construction/subpackaged-visa";
import Accident from "../components/epc/construction/accident";
import Violation from "../components/epc/construction/violation";
import MasterPlan from "../components/epc/purchase/MasterPlan";
import PlanAdjust from "../components/epc/purchase/PlanAdjust";
import PublicBidding from "../components/epc/purchase/PublicBidding";
import InviteBidding from "../components/epc/purchase/InviteBidding";
import CompetitionArgue from "../components/epc/purchase/CompetitionArgue";
import PreBidding from "../components/epc/purchase/PreBidding";
import NotPreBidding from "../components/epc/purchase/NotPreBidding";
import Inquiry from "../components/epc/purchase/Inquiry";
import EmergancyPurchase from "../components/epc/purchase/EmergancyPurchase";
import Transfer from "../components/epc/purchase/Transfer";
import ReturnToFactory from "../components/epc/purchase/ReturnToFactory";
import TrailOperationPlan from "../components/epc/complete/trial-operation-plan";
import PerformanceAssess from "../components/epc/complete/perform-assess";
// 收支

import BalanceAccount from "../components/epc/income-expense/BalanceAccount";
import RemMatHandle from "../components/epc/income-expense/RemMatHandle";
import ComSettle from "../components/epc/income-expense/ComSettle";
import ComSettleComment from "../components/epc/income-expense/ComSettleComment";
import SubSettle from "../components/epc/income-expense/SubSettle";
import SubSettleComment from "../components/epc/income-expense/SubSettleComment";
import CosExeRep from "../components/epc/income-expense/CosExeRep";
import CosExeRepComment from "../components/epc/income-expense/CosExeRepComment";

import SubpackPay from "../components/epc/income-expense/SubpackPay";
import SubpackPayApp from "../components/epc/income-expense/SubpackPayApp";
// import SubpackagePay from "../components/epc/income-expense/subpackage-pay";

// 招采平台
import BiddingResult from "../components/bidding/bidding-result";
import BiddingConfirm from "../components/bidding/bidding-confirm";
import BiddingRegister from "../components/bidding/bidding-register";
import AddBidding from "../components/bidding/add-bidding";

// scrm
import BidRequestEPCLeader from "../components/scrm/bid-request-epc-leader.vue";

// 考勤
import Leave from "../components/clock/leave.vue";
import Replace from "../components/clock/replace.vue";

import SecurityCheckPlan from "../components/security-check/plan.vue";
import SecurityCheckWork from "../components/security-check/work.vue";
import SecurityCheckRectify from "../components/security-check/rectify.vue";
import SecurityImportBudget from "../components/security-check/budget.vue"; 
import SecurityImportMoney from "../components/security-check/money.vue"; 
import Collaborate from "../components/collaborate/index.vue";

import DineCard from "../components/clock/dine-card.vue"; 

import RiskEstimate from "../components/Risk/risk-estimate.vue"; 
import SafeDailyWork from "../components/technical-quality/safeDailyWork.vue";
import SafeDailyMeeting from "../components/technical-quality/SafeDailyMeeting.vue";

import Car from "../components/car";
import Resource from "../components/resource";
import Intern from "../components/Intern";
import Card from "../components/Card";
import Form from "../components/Form";
// import History from "../components/History";
import Log from "../components/log";
import LogDetail from "../components/log/detail";

import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import approval1Image from "@/assets/approval1.png";
import approval4Image from "@/assets/approval4.png";

import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";

import { saveAs } from "file-saver";
import Vue from "vue";

export default {
  name: "approvalTaskDetail",

  mixins: [watermark],

  components: {
    Header,
    ContractInfoTable,
    ContractInfo,
    Progress,
    Phase,
    SuperviseInfoTable,
    EditSuperviseTask,
    Delay,
    Invoice,
    ArchiveBorrow,
    ArchiveTransfer,
    Material,
    ContractRegister,
    ContractAdjust,
    SubpackageRegister,
    FrameWorkContractRegister,
    EpcOrganize,
    EpcBidBudget,
    BudgetComment,
    ManageObjective,
    ManagePlan,
    ManagePlanChange,
    ConstructionSchedule,
    ConstructionScheduleChange,
    ConstructionBudget,
    PlanBudgetComment,
    ResultConfirm,
    ChangeApproval,
    ChangeReview,
    OrganizeDesign,
    QualityInspection,
    SafeInspection,
    DataInspection,
    Claim,
    CounterClaim,
    Visa,
    Accident,
    Violation,
    TrailOperationPlan,
    PerformanceAssess,
    MasterPlan,
    PlanAdjust,
    PublicBidding,
    InviteBidding,
    CompetitionArgue,
    PreBidding,
    NotPreBidding,
    Inquiry,
    EmergancyPurchase,
    Transfer,
    ReturnToFactory,
    // 收支
    BalanceAccount,
    RemMatHandle,
    ComSettle,
    ComSettleComment,
    SubSettle,
    SubSettleComment,
    CosExeRep,
    CosExeRepComment,
    SubpackPay,
    SubpackPayApp,
    // 招采相关
    BiddingResult,
    BiddingConfirm,
    BiddingRegister,
    AddBidding,
    // scrm
    BidRequestEPCLeader,
    // 考勤
    Leave,
    DineCard,
    Replace,

    // 安全生产
    SecurityCheckPlan,
    SecurityCheckWork,
    SecurityCheckRectify,
    SecurityImportBudget,
    SecurityImportMoney,
    Collaborate,
    RiskEstimate,
    SafeDailyWork,
    SafeDailyMeeting,
    Car,
    Resource,
    Intern,
    Card,
    Form,
    // History,
    Log,
    LogDetail
  },

  data() {
    return {
      // 图标
      approval1Image,
      approval4Image,

      isPrinting: false, // 正在打印
      logDetailVisible: false, // 日志详情弹窗显示隐藏
      logType: "all" // 右下角日志显示类型：全部和通过的
    };
  },

  activated() {
    const { query } = this.$route;
    const { taskId, instanceId } = query || {};

    if (!taskId || !instanceId) {
      return;
    }

    this.setTaskId(taskId);
    this.setInstanceId(instanceId);
    this.getDetail();

    // 设置水印
    const detail = document.querySelector(".detail");
    const form = document.querySelector(".form");
    const process = document.querySelector(".process");
    if (detail) {
      this.setWatermark(detail);
      this.setWatermark(form);
      this.setWatermark(process);
    }
  },

  computed: {
    ...mapState("approval", ["taskId", "loading", "detail"]),
    ...mapGetters("approval", [
      "isContract",
      "isProgress",
      "isAllocate",
      "isSupervise",
      "isAdjust",
      "isInvoice",

      "isArchiveBorrow", // 是否是档案出借
      "isArchiveTransfer", // 是否是档案移交
      "isMaterial", // 是否是物料申领审批

      "isContractRegister", // 是否是合同登记审批
      "isContractAdjust", // 合同调整审批
      "isSubpackageRegister", // 是否是分包登记审批
      "isFrameworkContractRegister", // 是否是框架类合同登记审批

      // epc
      "isEpcOrganize", // 是否是组织机构建立
      "isBudgetComment", // 是否是投标控制预算审批表
      "isEpcBidbudget", // 是否是投标控制预算审核意见表

      "isManageObject", // 是否是管理目标责任书
      "isManagePlan", // 是否是管理计划
      "isManagePlanChange", // 是否是管理计划变更
      "isConstructionSchedule", // 是否是施工进度计划
      "isConstructionScheduleChange", // 是否是施工进度计划变更
      "isConstructionBudget", // 是否是实施控制预算审批
      "isPlanBudgetComment", // 是否是实施控制预算审核

      "isResultConfirm", // 是否是设计成果确认
      "isChangeReview", // 是否是设计变更审批-符合审计原则
      "isChangeApproval", // 是否是设计变更审批-不符合审计原则
      "isOrganizeDesign", // 是否是施工组织设计审批
      "isQualityInspection", // 是否是质量检查记录表
      "isSafeInspection", // 是否是安全记录检查表
      "isDataInspection", // 是否是资料检查记录表
      "isClaim", // 是否是索赔申请
      "isCounterClaim", // 是否是反索赔申请
      "isVisa", // 是否是分包签证审批
      "isAccident", // 是否是生产安全事故快报审批
      "isViolation", // 是否是安全违章审批
      "isTrailOperationPlan", // 是否是试运行方案审批
      "isPerformAssess", // 是否是组织绩效考核审批

      "isMasterPlan", // 是否是组织绩效考核审批
      "isPlanAdjust", // 是否是组织绩效考核审批
      "isPublicBidding", // 是否是组织绩效考核审批
      "isInviteBidding", // 是否是组织绩效考核审批
      "isCompetitionArgue", // 是否是组织绩效考核审批
      "isPreBidding", // 是否是组织绩效考核审批
      "isNotPreBidding", // 是否是组织绩效考核审批
      "isInquiry", // 是否是组织绩效考核审批
      "isEmergancyPurchase", // 是否是组织绩效考核审批
      "isTransfer", // 是否是组织绩效考核审批
      "isReturnToFactory", // 是否是组织绩效考核审批

      "isBalanceAccount", // 是否是收支台账审批
      "isRemMatHandle", // 是否是组织绩效考核审批
      "isComSettle", // 是否是竣工结算审批
      "isComSettleComment", // 是否是竣工结算审核意见审批
      "isSubSettle", // 是否是分包结算审批
      "isSubSettleComment", // 是否是分包结算审批审核意见审批
      "isCosExeRep", // 是否是成本执行报告审批
      "isCosExeRepComment", // 是否是成本执行报告审核意见审批

      "isSubpackPay", // 是否是组织绩效考核审批
      "isSubpackPayApp", // 是否是组织绩效考核审批

      "isBiddingRegister", // 是否是招标采购网注册审批
      "isAddBidding", // 是否是招标采购网注册审批

      "isBiddingResult", // 是否是评标结果会签审批
      "isBiddingConfirm", // 是否是中标通知确认审批

      "isPutOnFileRequest",
      "isBidRequestEPCLeader", // 项目投标申请(EPC-牵头人)
      "isBidRequestEPCLeaderNot",
      "isBidRequestDesign",

      "isLeave", // 请假，出差，公出
      "isReplace", // 补卡

      "ofc_quality_plan", // 安全生产-策划表
      "ofc_quality_work", // 安全生产-工作表
      "ofc_quality_rectify", // 安全生产-整改工作单

      "safety_collaborate", // 部门协作单
      "dine_card_temp", //餐卡借用审批
      "ofc_quality_fee_budget",
      "ofc_quality_fee_use",
      "ofc_quality_risk",
      "ofc_quality_meeting_issue",
      "ofc_quality_meeting_decision",
      "oa_use_car",
      "oa_technical_information_grant",
      "user_intern" //实习生审批
    ])
  },

  methods: {
    ...mapMutations("approval", ["setTaskId", "setInstanceId"]),
    ...mapActions("approval", ["getDetail"]),
    close() {
      this.$close(this.$route.path);
    },

    download() {
      if (this.isPrinting) {
        return;
      }
      this.isPrinting = true;
      this.logType = "pass";

      const that = this;
      Vue.nextTick(function() {
        html2canvas(document.getElementById("container"))
          .then(canvas => {
            canvas.toBlob(blob => {
              saveAs(blob, `审批协同.png`);
              that.isPrinting = false;
              that.logType = "all";
            });
          })
          .catch(err => {
            console.log("html2canvas err", err);

            domtoimage
              .toBlob(document.getElementById("container"))
              .then(function(blob) {
                saveAs(blob, "审批协同.png");
                that.isPrinting = false;
                that.logType = "all";
              })
              .catch(err => {
                console.log("dom-to-image err", err);
                that.$message.error(
                  "下载失败，此浏览器不支持此功能，请使用新款或其他浏览器操作，或者手动截图"
                );
                that.isPrinting = false;
                that.logType = "all";
              });
          });
      });
    },

    print() {
      if (this.isPrinting) {
        return;
      }
      this.isPrinting = true;
      this.logType = "pass";

      const that = this;
      Vue.nextTick(function() {
        html2canvas(document.getElementById("container"))
          .then(canvas => {
            const url = canvas.toDataURL();
            that.printImage(url);

            that.isPrinting = false;
            that.logType = "all";
          })
          .catch(err => {
            console.log("html2canvas err", err);

            domtoimage
              .toPng(document.getElementById("container"))
              .then(function(dataUrl) {
                that.printImage(dataUrl);
                that.isPrinting = false;
                that.logType = "all";
              })
              .catch(err => {
                console.log("dom-to-image err", err);
                that.$message.error(
                  "下载失败，此浏览器不支持此功能，请使用新款或其他浏览器操作，或者手动截图"
                );
                that.isPrinting = false;
                that.logType = "all";
              });
          });
      });
    },

    printImage(url) {
      const img = `
        <img src="${url}" />
`;

      var iframe = window.document.createElement("iframe");
      iframe.setAttribute(
        "style",
        "position:absolute;width:0px;height:0px;left:-500px;top:-500px;"
      );
      window.document.body.appendChild(iframe);
      var doc = iframe.contentWindow.document;
      doc.write(img);

      var ys = `body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      img {
        width: 98%;
      }
      div{text-align: center;
        font-size: 8px;
      }`;
      var style = window.document.createElement("style");
      style.innerText = ys;
      doc.getElementsByTagName("head")[0].appendChild(style);
      // doc.close();

      setTimeout(() => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }, 0);
    }
  }
};
</script>

<style lang="less" scoped>
.extra-content {
  flex: 1;
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 24px;
    cursor: pointer;

    font-size: 12px;
    font-weight: 400;
    color: #1890ff;
  }

  img {
    height: 18px;
    margin-bottom: 6px;
  }
}

.container {
  background-color: #f0f2f5;
  width: 100%;
  display: flex;
  gap: 8px;

  .num {
    text-align: right;
    padding-bottom: 8px;
  }

  .detail {
    flex: 2;
    background-color: #fff;
    padding: 12px;
  }

  .tool {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .form {
    position: relative;
    background-color: #fff;
    padding: 12px;
    margin-bottom: 8px;
  }

  .process {
    flex: 1;
    background-color: #fff;
    padding: 12px;

    .active {
      font-weight: bold;
    }
  }

  .all-rows {
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
</style>
