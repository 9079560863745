<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">申请部门</div>
      <div>
        {{ info.applicantDeptName }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">申请人</div>
      <div>
        {{ info.applicantName }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">生产负责人部门</div>
      <div>
        {{ info.liquidatorDeptName }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">生产负责人</div>
      <div>
        {{ info.liquidatorName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">区域负责人部门</div>
      <div>
        <span v-if="info.areaName">
          {{ info.areaName }}/{{ info.subAreaName }}
        </span>
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">区域负责人</div>
      <div>
        {{ info.areaMasterName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">申请类型</div>
      <div>
        <DataDictFinder dictType="applyType" :dictValue="info.applyType" />
      </div>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>