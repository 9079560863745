<template>
  <div>
    <div class="panel">
      <div class="label">申请人</div>
      <div class="value1">{{ extra.applicant }}</div>
      <div class="label">申请部门</div>
      <div class="value2">{{ extra.applicantDept }}</div>

      <div class="label">申请时间</div>
      <div class="value1">{{ extra.applyTime }}</div>
      <div class="label">预计用卡时间</div>
      <div class="value2">{{ extra.useDate }}</div>

      <div class="label">职能部门</div>
      <div class="value1">{{ extra.functionDept }}</div>
      <div class="label"></div>
      <div class="value2"></div>
      <div class="label">申请项目或事由</div>
      <div class="value3">{{ extra.content }}</div>
    </div>
    <Padding />
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { getName } from "@/utils/clock";

export default {
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra;
    }
  },
  methods: {
    getName,

    renderStaff(str) {
      let msg = "";
      try {
        const list = JSON.parse(str);
        msg = list.map(item => item.userName).join("，");
      } catch (error) {
        msg = str;
      }

      return msg;
    }
  }
};
</script>
  
  
<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>
  
  