<template>
  <div>
    <div class="panel">
      <div class="label">工程名称</div>
      <div class="value1">
        {{ data.designCode }}
      </div>

      <div class="label">项目编号</div>
      <div class="value2">
        {{ data.contractCode}}
      </div>

      <div class="label">事故时间</div>
      <div class="value1">
        {{ data.accTime }}
      </div>

      <div class="label">事故地点</div>
      <div class="value2">
        {{ data.accLocation }}
      </div>

      <div class="label">事故单位</div>
      <div class="value3">
        {{ data.accOrg }}
      </div>

      <div class="label">事故现场负责人</div>
      <div class="value1">
        {{ data.accSceneRpName }}
      </div>

      <div class="label">联系方式</div>
      <div class="value2">
        {{ data.accSceneRpPhone }}
      </div>

      <div class="label">事故单位负责人</div>
      <div class="value1">
        {{ data.accOrgRpName }}
      </div>

      <div class="label">联系方式</div>
      <div class="value2">
        {{ data.accOrgRpPhone }}
      </div>

      <div class="label">死亡人数</div>
      <div class="value4">
        {{ data.deadNum }}
      </div>
      <div class="label">失踪人数</div>
      <div class="value5">
        {{ data.missingNum }}
      </div>
      <div class="label">重伤人数</div>
      <div class="value6">
        {{ data.injuredNum }}
      </div>

      <div class="label">事故简要经过</div>
      <div class="value3">
        {{ data.accProcessDesc }}
      </div>
      <div class="label">事故现场情况及救援采取的主要措施</div>
      <div class="value3">
        {{ data.accSceneDesc }}
      </div>
      <div class="label">其他情况</div>
      <div class="value3">
        {{ data.accRemark }}
      </div>

      <div class="label">相关附件</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachmentList)">
          <a v-for="(item) in data.attachmentList"
             :key="item.id"
             target="_blank"
             :href="item.completePath"
             style="display:block;">
            {{item.name}}
          </a>
        </div>
      </div>
    </div>
    <Padding />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      console.log(this.detail);
      return this.detail.extra.securityAccidentData
        ? this.detail.extra.securityAccidentData
        : {};
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value4 {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .value5 {
    grid-column-start: 4;
    grid-column-end: 5;
  }
  .value6 {
    grid-column-start: 6;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>
