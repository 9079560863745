<template>
  <div class="list">
    <div class="item">
      <div class="label">附件名称</div>
    </div>
    <div class="item" v-for="(text, index) in list" :key="index">
      <div>
           <Download :path="text.name" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  border-top: 1px solid #e8e8e8;

  .item {
    display: grid;
    grid-template-columns: 1fr;

    & > div {
      padding: 8px;
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
    }
    & > div:first-child {
      border-left: 1px solid #e8e8e8;
    }

    .label {
      font-weight: bold;
      background-color: #fafafa;
    }
  }
}
</style>