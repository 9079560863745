<template>
  <div>
    <div class="panel">
      <div class="label">项目编号</div>
      <div class="value1">
        {{ data.designCode }}
      </div>

      <div class="label">合同编号</div>
      <div class="value2">
        {{ data.contractCode }}
      </div>

      <div class="label">工程名称</div>
      <div class="value3">
        {{ data.projectName }}
      </div>

      <div class="label">实施控制预算(万元)</div>
      <div class="value1">
        {{ data.budget }}
      </div>

      <div class="label">合同价(万元)</div>
      <div class="value2">
        {{ data.contractPrice }}
      </div>

      <div class="label">主要内容</div>
      <div class="value3">
        {{ data.content }}
      </div>

      <div class="label">相关附件</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachmentList)">
          <a v-for="(item) in data.attachmentList" :key="item.id" target="_blank" :href="item.completePath"
            style="display:block;">
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
    <Padding />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.planningData
        ? this.detail.extra.planningData
        : {};
    },
  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  &>div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>

