import { mapGetters } from 'vuex'
import { flatList } from "@/utils/flat";
export default {

    computed: {
        ...mapGetters("setting", ["findDataDict"]),

        typeList() {
            return this.findDataDict("arcType");
        },

        flatTypeList() {
            let result = [];
            flatList(this.typeList, result);
            return result;
        },

        levelList() {
            return this.findDataDict("secretLevel");
        },
    },

    methods: {
        getTypeText(type) {
            if (typeof type === "string") {
                const arr = type.split(",");
                return arr
                    .map((item) => {
                        const obj = this.flatTypeList.find(
                            (element) => element.value === item
                        );
                        return obj ? obj.name : "";
                    })
                    .join(" - ");
            } else {
                return "";
            }
        },
    }
}