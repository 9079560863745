<template>
  <div>
    <div class="panel">
      <div class="label">标段名称</div>
      <div class="value3">
        {{ data.name }}
      </div>
      <div class="label">开标地点</div>
      <div class="value3">
        {{ data.bidAddress }}
      </div>
      <div class="label">招标方式</div>
      <div class="value1">
        <DataDictFinder
          dictType="bid_method"
          :dictValue="data.bidMethod"
        />
      </div>
      <div class="label">标底价(万元)</div>
      <div class="value2">
        {{ data.basePrice }}
      </div>
      <div class="label">所属地区</div>
      <div class="value1">
        {{ data.area }}
      </div>
      <div class="label">招标编号</div>
      <div class="value2">
        {{ data.code }}
      </div>
      <div class="label">招标业主</div>
      <div class="value1">
        {{ data.bidOwner }}
      </div>
      <div class="label">招标阶段</div>
      <div class="value2">
        <DataDictFinder
          dictType="bid_item_status"
          :dictValue="data.status"
        />
      </div>
      <div class="label">开标日期</div>
      <div class="value1">
        {{ moment(data.bidDate).format('YYYY-MM-DD HH:mm:ss') }}
      </div>
      <div class="label">截标日期</div>
      <div class="value2">
        {{ moment(data.deadline).format('YYYY-MM-DD HH:mm:ss') }}
      </div>
      <div class="label">是否epc项目</div>
      <div class="value1">
        {{ data.isEpc ? '是' : '否' }}
      </div>
      <div class="label">项目编号</div>
      <div class="value2">
        {{ data.projectName }}
      </div>
      <div class="label">联系人</div>
      <div class="value1">
        {{ data.contact }}
      </div>

      <div class="label">联系方式</div>
      <div class="value2">
        {{ data.contactMobile}}
      </div>
      <div class="label">供应商</div>
      <div class="value3">
        {{ invitedCompany}}
      </div>

      <div class="label">附件</div>
      <div class="value3">
        <div v-if="Array.isArray(bidFiles)">
            <a
                v-for="(item) in bidFiles"
                :key="item.id"
                target="_blank"
                :href="item.completePath" style="display:block;"
            >
                {{item.name}}
            </a>
        </div>
      </div>
    </div>
    <Padding />
    <div class="panel">
        <div class="label">采购项（标段）</div>
        <div class="value3">
            {{ data.purchaseItem }}
        </div>

        <div class="label">主要内容</div>
        <div class="value3">
            {{ data.purchaseContent }}
        </div>

        <div class="label">数量及单位</div>
        <div class="value3">
            {{data.purchaseNumber}}
        </div>

        <div class="label">要求到货时间</div>
        <div class="value3">
            {{ moment(data.purchaseArrivalDate).format('YYYY-MM-DD HH:mm:ss') }}
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from 'moment'

export default {
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra ? this.detail.extra : {};
    },
    invitedCompany() {
      let invitedCompanyList = JSON.parse(this.data.invitedCompany)
      return invitedCompanyList.length ? invitedCompanyList.map(item => item.company).join('、') : ''
    },
    bidFiles(){
      return JSON.parse(this.data.bidFiles);
    }
  },
  methods: {
    moment(value) {
      return moment(value)
    }
  }
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

</style>

