<template>
  <div>
    <div class="panel">
      <div class="label">项目编号</div>
      <div class="value1">
        {{ data.designCode }}
      </div>

      <div class="label">符合审计原则</div>
      <div class="value2">
        {{ suitAudit}}
      </div>

      <div class="label">工程名称</div>
      <div class="value3">
        {{data.projectName}}
      </div>

      <div class="label">索赔情况说明</div>
      <div class="value3">
        {{data.content}}
      </div>

      <div class="label">相关附件</div>
      <div class="value3">
        <div v-if="Array.isArray(attachmentList)">
          <a v-for="(item) in attachmentList"
             :key="item.id"
             target="_blank"
             :href="item.completePath"
             style="display:block;">
            {{item.name}}
          </a>
        </div>
      </div>
    </div>

    <div v-if="isApplicant && suitAudit === '否'">
      <approval-detail></approval-detail>
    </div>
  </div>
</template>

<script>
import ApprovalDetail from "@/views/approval/task/components/epc/components/ApprovalDetail.vue";
import { mapState } from "vuex";

export default {
  components: {
    ApprovalDetail,
  },
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.constructionData
        ? this.detail.extra.constructionData
        : {};
    },
    isApplicant() {
      return (
        this.detail.taskDefinitionKey ===
        "general_contract_proj_dept_prj_manager"
      );
    },
    attachmentList() {
      // 原表单的附件列表
      return this.data.attachmentList.filter(
        (item) => item.type !== "approvalDetail"
      );
    },
    suitAudit() {
      return this.data.suitAudit === "1" ? "是" : "否";
    },
  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }
  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>

