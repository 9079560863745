<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">设计编号</div>
      <div class="">
        {{ detail.extra.projectData.designCode }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">合同名称</div>
      <div class="">
        {{ detail.extra.projectData.name }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">部门名称</div>
      <div class="">{{ detail.extra.applicantDeptName }}</div>
    </a-descriptions-item>
    <a-descriptions-item :span="1">
      <div slot="label" class="center">申请人</div>
      <div class="">
        {{ detail.extra.applicant }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1" v-if="!isContract">
      <div slot="label" class="center">合同金额</div>
      <div class="" v-if="detail.extra.projectData.prjAmount">
        <Money :money="detail.extra.projectData.prjAmount" />
        <span>万元</span>
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1" v-if="!isContract">
      <div slot="label" class="center">项目已完成进度</div>
      <div class="" v-if="typeof detail.extra.projectData.progress === 'number'">
        {{ detail.extra.projectData.progress }}%
      </div>
    </a-descriptions-item>

    <!-- <a-descriptions-item :span="1">
      <div slot="label" class="center">所属区域</div>
      <div class="">
        {{ detail.extra.projectData.areaName }}
      </div>
    </a-descriptions-item> -->

    <a-descriptions-item :span="1">
      <div slot="label" class="center">申请事项</div>
      <div class="">
        {{ detail.extra.applyDetails }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2" v-if="isAdjust">
      <div slot="label" class="center">申请内容</div>
      <div class="">
        {{ detail.extra.adjustConfigData.remarks }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2" v-if="detail.extra.remarks">
      <div slot="label" class="center">说明</div>
      <div class="">
        {{ detail.extra.remarks }}
      </div>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
    ...mapGetters("approval", ["isContract", "isAdjust"]),
  },
};
</script>

<style lang="less" scoped>
.center {
  min-width: 4em;
  text-align: center;
}
</style>