<template>
  <div>
    <div class="panel">
      <div class="label">工程名称</div>
      <div class="value3">
        {{ data.name }}
      </div>

      <div class="label">项目编号</div>
      <div class="value1">
        {{ data.designCode}}
      </div>

      <div class="label">管理类别</div>
      <div class="value2">
        <DataDictFinder dictType="epc_prj_type"
                        :dictValue="data.type" />
      </div>

      <div class="label">情况说明</div>
      <div class="value3">
        {{data.remark}}
      </div>

      <div class="label">相关附件</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachmentList)">
          <a v-for="(item) in data.attachmentList"
             :key="item.id"
             target="_blank"
             :href="item.completePath"
             style="display:block;">
            {{item.name}}
          </a>
        </div>
      </div>
    </div>
    <Padding />
    <div>
      <div class="title">人员分配</div>

      <div v-for="(item, idx) in memberList"
           :key="idx">
        <div class="table-header"
             :style="{borderTop: idx === 0 ? '1px solid #efefef' : 'none' }">{{item.deptName}}</div>
        <a-descriptions bordered
                        id="organize-desc">
          <a-descriptions-item :label="ele.roleName"
                               v-for="(ele, index) in item.tempList"
                               :key="index"
                               class="center">{{ele.userName}}</a-descriptions-item>
          <a-descriptions-item v-if="item.tempList.length <2"></a-descriptions-item>
          <a-descriptions-item v-if="item.tempList.length <3"></a-descriptions-item>
        </a-descriptions>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.projectData ? this.detail.extra.projectData : {};
    },
    members() {
      return this.data.members || [];
    },
  },
  data() {
    return {
      memberList: [],
    };
  },

  mounted() {
    this.setMember(this.members);
  },
  methods: {
    setMember(memberList) {
      const deptList = [...new Set(memberList.map((item) => item.deptName))];

      this.memberList = deptList.map((item) => {
        const arr = [];
        memberList.forEach((element) => {
          if (element.deptName === item) {
            arr.push({
              ...element,
            });
          }
        });
        return {
          deptName: item,
          tempList: arr,
        };
      });
    },
  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
.title {
  border-left: 4px solid #1890ff;
  padding-left: 5px;
  margin: 16px 0 10px;
}
.table-header {
  padding: 8px 24px;
  background-color: #fff;
  color: #000;
  text-align: center;
  font-weight: 500;
  font-size: 1.15em;
  border: 1px solid #efefef;
  border-bottom: none;
  border-top: none;
}
</style>


<style lang="less">
#organize-desc .ant-descriptions-item-label,
#organize-desc .ant-descriptions-item-content {
  font-size: 1.15em;
  padding: 10px 24px;
  width: 100px;
}

#organize-desc .item {
  font-size: 20px;
}
</style>