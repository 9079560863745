<template>
  <div>
    <div class="panel">
      <div class="label">发放人</div>
      <div class="value1">{{ extra.grantName }}</div>
      <div class="label">发放部门</div>
      <div class="value2">{{ extra.grantDeptName }}</div>
      <div class="label">接收人</div>
      <div class="value1">{{ extra.receiveName }}</div>
      <div class="label">接收部门</div>
      <div class="value2">{{ extra.deptName }}</div>
      <div class="label">发放时间</div>
      <div class="value3">{{ extra.grantTime }}</div>
    </div>
    <Padding />
    <div style="margin-top: 20px;">
      <div>发放资料情况</div>
      <a-table bordered :data-source="extra.dataList" :pagination="false">
        <a-table-column title="资料名称" data-index="name"> </a-table-column>
        <a-table-column title="资料数量" data-index="count"> </a-table-column>
      </a-table>
    </div>
  </div>
</template>
    
  <script>
  import { mapState } from "vuex";
  import { getName } from "@/utils/clock";
  
  export default {
    computed: {
      ...mapState("approval", ["detail"]),
      extra() {
        return this.detail.extra
          ? {
              ...this.detail.extra,
            }
          : {};
      },
    },
    methods: {
      getName,
  
      renderStaff(str) {
        let msg = "";
        try {
          const list = JSON.parse(str);
          msg = list.map((item) => item.userName).join("，");
        } catch (error) {
          msg = str;
        }
  
        return msg;
      },
    },
  };
  </script>
    
    
  <style lang="less" scoped>
  .panel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid #e8e8e8;
  
    & > div {
      padding: 8px;
      border-bottom: 1px solid #e8e8e8;
    }
  
    .label {
      background-color: #fafafa;
      border-right: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
  
    .value1 {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  
    .value2 {
      grid-column-start: 5;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  
    .value3 {
      grid-column-start: 2;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  }
  </style>
    
    