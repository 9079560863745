<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">发票送达方式</div>
      <div>
        <a-select style="width: 100%" @change="onWayChange">
          <a-select-option
            v-for="item in deliveryWayList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">快递公司</div>
      <div>
        <a-select style="width: 100%" @change="onExpressCompanyChange">
          <a-select-option
            v-for="item in expressCompanyList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">单据号码</div>
      <div>
        <a-input @change="handleNoChange" />
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">签收单位</div>
      <div>
        <a-input @change="handleCompanyChange" />
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">签收人</div>
      <div>
        <a-input @change="handlePersonChange" />
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">附件</div>
      <div>
        <FileUpload @uploaded="uploaded">
          <a-button type="primary">
            {{ filePath ? "已上传" : "上传" }}
          </a-button>
        </FileUpload>
      </div>
    </a-descriptions-item>
        <a-descriptions-item :span='2'>
      <div slot="label" class="center">发票签收模板</div>
      <div>
        <a
          target="_blank"
          href="https://s.upapi.cn/njszy/2022/6/6/47da45a174017757461fb5bd8d35b48f_发票签收单1空白模板.xlsx"
          >查看</a
        >
      </div>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import FileUpload from "@/components/file-upload";

export default {
  components: {
    FileUpload,
  },

  data() {
    return {
      filePath: "",
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    deliveryWayList() {
      return this.findDataDict("deliveryWay");
    },
    expressCompanyList() {
      return this.findDataDict("expressCompany");
    },
    booleanList() {
      return this.findDataDict("boolean");
    },
  },

  methods: {
    ...mapMutations("approval", ["setExpress"]),

    // 送达方式
    onWayChange(value) {
      this.setExpress({
        deliveryWay: value,
      });
    },

    // 选择快递公司
    onExpressCompanyChange(value) {
      this.setExpress({
        courierCompany: value,
      });
    },

    // 修改单据号码
    handleNoChange(e) {
      this.setExpress({
        deliveryNo: e.target.value,
      });
    },
    // 修改签收单位
    handleCompanyChange(e) {
      this.setExpress({
        signCompany: e.target.value,
      });
    },
    // 修改签收人
    handlePersonChange(e) {
      this.setExpress({
        signPeople: e.target.value,
      });
    },
    // 上传文件
    uploaded(list) {
      this.filePath = list[0].completePath;
      this.setExpress({
        attachment: this.filePath,
      });
    },
  },
};
</script>