<template>
  <div v-if="list.length > 0">
    <div class="title">合同付款条款</div>

    <a-table bordered :dataSource="list" :pagination="false">
      <a-table-column title="编号" align="center">
        <template slot-scope="text">
          <span>{{ text.code }}</span>
        </template>
      </a-table-column>
      <a-table-column title="收款项名称">
        <template slot-scope="text">
          <span>{{ text.name }}</span>
        </template>
      </a-table-column>
      <a-table-column title="收款比例" align="right">
        <template slot-scope="text">
          <span>{{ text.ratio }}%</span>
        </template>
      </a-table-column>
      <a-table-column title="收款金额(万元)" align="right">
        <template slot-scope="text">
          <Money :money="parseFloat(text.planReceipt)" />
        </template>
      </a-table-column>

      <a-table-column title="进度确认" align="center">
        <template slot-scope="text">
          <a-icon type="check" v-if="text.isFinished" />
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    list() {
      if (this.info.projectInfo && this.info.projectInfo.planReceipt) {
        return [this.info.projectInfo.planReceipt];
      } else if (Array.isArray(this.info.projectInfo.planReceipts)) {
        return this.info.projectInfo.planReceipts.filter(item => !!item)
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  font-size: 17px;
  margin: 12px 0;
}
</style>