<template>
  <div id="flow"></div>
</template>

<script>
import { Graph } from "@antv/x6";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("approval", ["log"]),
  },

  watch: {
    log() {
      this.processData();
    },
  },

  mounted() {
    Graph.registerNode(
      "event",
      {
        inherit: "circle",
        attrs: {
          body: {
            strokeWidth: 2,
            stroke: "#5F95FF",
            fill: "#FFF",
          },
        },
      },
      true
    );

    Graph.registerNode(
      "old-activity",
      {
        inherit: "rect",
        markup: [
          {
            tagName: "rect",
            selector: "body",
          },
          {
            tagName: "text",
            selector: "label",
          },
        ],
        attrs: {
          body: {
            rx: 6,
            ry: 6,
            stroke: "#5F95FF",
            fill: "#EFF4FF",
            strokeWidth: 1,
          },

          label: {
            fontSize: 12,
            lineHeight: 18,
            fill: "#262626",
          },
        },
      },
      true
    );

    Graph.registerNode(
      "activity",
      {
        inherit: "rect",
        markup: [
          {
            tagName: "rect",
            selector: "body",
          },
          {
            tagName: "text",
            selector: "label",
          },
        ],
        attrs: {
          body: {
            rx: 6,
            ry: 6,
            stroke: "#e8e8e8",
            fill: "#f0f0f0",
            strokeWidth: 1,
          },

          label: {
            fontSize: 12,
            lineHeight: 18,
            fill: "#999",
          },
        },
      },
      true
    );

    Graph.registerNode(
      "next-activity",
      {
        inherit: "rect",
        markup: [
          {
            tagName: "rect",
            selector: "body",
          },
          {
            tagName: "text",
            selector: "label",
          },
        ],
        attrs: {
          body: {
            rx: 6,
            ry: 6,
            stroke: "#00FF7F",
            fill: "#F5FFFA",
            strokeWidth: 1,
          },

          label: {
            fontSize: 12,
            lineHeight: 18,
            fill: "#333",
          },
        },
      },
      true
    );

    Graph.registerNode(
      "gateway",
      {
        inherit: "polygon",
        attrs: {
          body: {
            refPoints: "0,10 10,0 20,10 10,20",
            strokeWidth: 2,
            stroke: "#5F95FF",
            fill: "#EFF4FF",
          },
          label: {
            text: "x",
            fontSize: 20,
            fill: "#5F95FF",
          },
        },
      },
      true
    );

    Graph.registerNode(
      "gateway2",
      {
        inherit: "polygon",
        attrs: {
          body: {
            refPoints: "0,10 10,0 20,10 10,20",
            strokeWidth: 2,
            stroke: "#5F95FF",
            fill: "#EFF4FF",
          },
          label: {
            text: "+",
            fontSize: 20,
            fill: "#5F95FF",
          },
        },
      },
      true
    );

    Graph.registerEdge(
      "bpmn-edge",
      {
        inherit: "edge",
        attrs: {
          line: {
            stroke: "#A2B1C3",
            strokeWidth: 2,
            targetMarker: {
              name: "classic",
              offset: 0,
            },
          },
        },
      },
      true
    );

    this.processData();
  },

  destroyed() {
    this.graph?.dispose();
  },
  methods: {
    processData() {
      const flow = document.getElementById("flow");

      if (!flow) return;

      if (!this.graph) {
        this.graph = new Graph({
          container: flow,
          // width: 800,
          height: 400,
          scroller: {
            enabled: true,
            pannable: true,
          },
          mousewheel: {
            enabled: true,
            // modifiers: ['ctrl', 'meta'],
          },
        });
      }

      const { allNodes, allLines, historyNodes, waitingTodoNodes } = this.log;
      let list = [];
      if (Array.isArray(allNodes)) {
        allNodes.forEach((item, index) => {

          if(allNodes[index - 1]){
            const pro = allNodes[index - 1]
            if(item.x == pro.x && item.y == pro.y){
              item.x = item.x + 200;
            }
          }
          
          if (item.type === "StartEvent" || item.type === "EndEvent") {
            list.push({
              id: item.key,
              shape: "event",
              width: 40,
              height: 40,
              position: {
                x: item.x,
                y: item.y,
              },
            });
          }
          if (item.type === "ExclusiveGateway") {
            list.push({
              id: item.key,
              shape: "gateway",
              width: 40,
              height: 40,
              position: {
                x: item.x,
                y: item.y,
              },
            });
          }
          if (item.type === "ParallelGateway") {
            list.push({
              id: item.key,
              shape: "gateway2",
              width: 40,
              height: 40,
              position: {
                x: item.x,
                y: item.y,
              },
            });
          } else {
            let label = item.name;
            if (item.assigneeName) {
              label += `\n${item.assigneeName}`;
            }

            const isOld = historyNodes.find(
              (element) => element.key === item.key
            );
            const isNext = waitingTodoNodes.find(
              (element) => element.key === item.key
            );

            console.log("isnext", isNext, item.assigneeName);

            if (isOld) {
              list.push({
                id: item.key,
                shape: "old-activity",
                label,
                width: 120,
                height: 48,
                position: {
                  x: item.x,
                  y: item.y,
                },
              });
              return;
            }
            if (isNext) {
              list.push({
                id: item.key,
                shape: "next-activity",
                label,
                width: 120,
                height: 48,
                position: {
                  x: item.x,
                  y: item.y,
                },
              });
              return;
            }
            list.push({
              id: item.key,
              shape: "activity",
              label,
              width: 120,
              height: 48,
              position: {
                x: item.x,
                y: item.y,
              },
            });
          }
        });
      }

      if (Array.isArray(allLines)) {
        allLines.forEach((item) => {
          let label;
          if (item.name) {
            label = item.name;
          }

          const nodeX = allNodes.find((element) => element.key === item.from);
          const nodeY = allNodes.find((element) => element.key === item.to);

          if (nodeX && nodeY) {
            list.push({
              id: item.key,
              shape: "bpmn-edge",
              source: {
                cell: item.from,
                // anchor: "center",
              },
              target: {
                cell: item.to,
                anchor: "nodeCenter",
                connectionPoint: {
                  name: "bbox",
                  args: {
                    // sticky: false,
                    // insideout: false,
                    offset: -2,
                    stroked: true,
                  },
                },
              },
              // target: item.to,
              // source: nodeX,
              // target: nodeY,
              label,
              vertices:
                Array.isArray(item.locations) && item.locations.length > 2
                  ? item.locations.map((item) => ({
                      x: item.x,
                      y: item.y,
                    }))
                  : [],
              router: "normal",
            });
          }
        });
      }

      const cells = [];
      list.forEach((item) => {
        if (item.shape === "bpmn-edge") {
          cells.push(this.graph?.createEdge(item));
        } else {
          cells.push(this.graph?.createNode(item));
        }
      });
      this.graph?.resetCells(cells);
      this.graph?.zoomToFit();

      // return list;
    },
  },
};
</script>