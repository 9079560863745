<template>
  <div>
    <AssetaTable v-for="(item,index) in value" :key="index" :detail="item"></AssetaTable>
  </div>
</template>

<script>
import AssetaTable from "./assets-table.vue";
export default {
  components: {
    AssetaTable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  }
};
</script>
