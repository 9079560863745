<template>
  <div>
    <Title>完成情况</Title>
    <a-form-model
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 22 }"
      :colon="false"
    >
      <a-form-model-item label="完成说明">
        <a-textarea
          :auto-size="{ minRows: 3 }"
          :value="executeRemarks"
          @change="handleChange"
          :disabled="reject"
        />
      </a-form-model-item>

      <a-form-model-item>
        <span slot="label" class="">说明附件</span>
        <div
          class="link-list"
          :style="{ backgroundColor: reject ? '#f5f5f5' : '#fff' }"
        >
          <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
            <div class="link" v-for="item in executeAttachments" :key="item.id">
              <a-checkbox :value="item.id">
                <a target="_blank" download :href="item.completePath">{{
                  item.name
                }}</a>
              </a-checkbox>
            </div>
          </a-checkbox-group>

          <div class="control-bar" v-if="!reject">
            <FileUpload @uploaded="uploaded">
              <div class="control">
                <a-icon type="upload" />
              </div>
            </FileUpload>

            <div class="control" @click="deleteFile">
              <a-icon type="delete" />
            </div>
          </div>
        </div>
      </a-form-model-item>

      <!-- 延期字段后台默认给0 -->
      <a-form-model-item :wrapper-col="{ offset: 2 }">
        <a-space>
          <a-button :disabled="reject" @click="storage" :loading="loading"
            >暂存</a-button
          >
          <a-button :disabled="reject" @click="addDelay">
            {{ isDelayed ? "取消延期" : "申请延期" }}
          </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import Title from "../Title";
import FileUpload from "@/components/file-upload";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  components: {
    Title,
    FileUpload,
  },

  data() {
    return {
      selectedFileList: [],
      loading: false,
    };
  },

  computed: {
    ...mapState("approval", ["detail"]),
    ...mapGetters("approval", [
      "executeRemarks",
      "executeAttachments",
      "isDelayed",
      "disabled",
      "isSuperviseWorker",
    ]),

    reject() {
      // 延期和完成情况都得是工作者去修改，发起者不能改，只能审核
      return this.disabled || !this.isSuperviseWorker;
    },
  },

  methods: {
    ...mapMutations("approval", [
      "setDetail",
      "setExecuteRemarks",
      "setExecuteAttachments",
      "setIsDelayed",
      "setDelayedDate",
      "setDelayRemarks",
    ]),
    ...mapActions("approval", ["storageTask"]),

    handleChange(e) {
      this.setExecuteRemarks(e.target.value);
    },

    uploaded(list) {
      this.setExecuteAttachments(list);
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },

    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.executeAttachments.findIndex(
          (file) => file.id === item
        );
        if (index > -1) {
          this.setExecuteAttachments(
            this.executeAttachments.filter((item, index) => index !== index)
          );
        }
      });
    },

    addDelay() {
      if (this.isDelayed === 0) {
        this.setIsDelayed(1);
      } else {
        this.setIsDelayed(0);
        this.setDelayedDate(null);
        this.setDelayRemarks("");
      }
    },

    // 暂存
    storage() {
      this.storageTask({
        extra: JSON.stringify({
          ...this.detail.extra,
          superviseTaskData: {
            ...this.detail.extra.superviseTaskData,
            isDelayed: 0,
            delayedDate: null,
            delayRemarks: "",
          },
        }),
      });
    },
  },
};
</script>