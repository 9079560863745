<!-- 收支台账 -->
<template>
  <div>
    <div class="panel">
      <div class="label">项目编号</div>
      <div class="value3">{{ data.designCode }}</div>

      <div class="label">工程名称</div>
      <div class="value3">{{ data.projectName }}</div>

      <!-- 
      <div class="label">合同编号</div>
      <div class="value2">{{ contract.contractCode }}</div>-->
    </div>

    <div class="title">合同详情</div>

    <div class="panel">
      <div class="label">合同编号</div>
      <div class="value1">{{ contract.contractCode }}</div>

      <div class="label">合同名称</div>
      <div class="value2">{{ contract.contractName }}</div>

      <div class="label">业主单位</div>
      <div class="value3">{{ contract.org }}</div>

      <div class="label">付款条件</div>
      <div class="value3">{{ contract.payCondition }}</div>

      <div class="label">合同金额(元)</div>
      <div class="value1">{{ $numberFormat(contract.contractAmt) }}</div>

      <div class="label">结算审计金额(元)</div>
      <div class="value2">
        {{ $numberFormat(contract.receivedRatio)
        }}
      </div>

      <div class="label">累计开票金额(元)</div>
      <div class="value1">
        {{
        $numberFormat(contract.sumInvoiceAmt)
        }}
      </div>

      <div class="label">累计回款金额(元)</div>
      <div class="value2">
        {{
        $numberFormat(contract.sumPayedAmt)
        }}
      </div>

      <div class="label">累计开票比例(%)</div>
      <div class="value1">{{ contract.sumInvoiceR }}</div>

      <div class="label">累计回款比例(%)</div>
      <div class="value2">{{ contract.sumPayR }}</div>
    </div>

    <div class="title">合同金额明细</div>

    <ContractAssets style="width: 100%" :value="amtCateList"></ContractAssets>

    <div class="title">分包合同</div>

    <div v-for="(item, index) in nameList" :key="index">
      <div class="subpack-title">{{item.name}}</div>

      <SubpackageDetail
        style="width: 100%; margin-bottom: 20px"
        v-for="(element) in item.list"
        :key="element.id"
        :detail="element"
      ></SubpackageDetail>
    </div>
  </div>
</template>

<script>
import ContractAssets from "./components/contract-assets.vue";
import SubpackageDetail from "./components/subpackage-detail.vue";

import { mapState } from "vuex";

export default {
  components: {
    ContractAssets,
    SubpackageDetail
  },

  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.balanceData ? this.detail.extra.balanceData : {};
    },
    contract() {
      let sumInvoiceR = (
        (this.data.contract.sumInvoiceAmt / this.data.contract.contractAmt ||
          0) * 100
      ).toFixed(2);
      let sumPayR = (
        (this.data.contract.sumPayedAmt / this.data.contract.contractAmt || 0) *
        100
      ).toFixed(2);

      return {
        ...this.data.contract,
        sumInvoiceR,
        sumPayR
      };
    },

    amtCateList() {
      return Array.isArray(this.contract.amtCateList)
        ? this.contract.amtCateList
        : [];
    },

    // 分包信息
    subContractList() {
      return Array.isArray(this.contract.subContractList)
        ? this.contract.subContractList
        : [];
    },
    nameList() {
      const names = this.subContractList
        .map(item => item.type)
        .filter((item, index, self) => self.indexOf(item) === index);

      return names.map(name => {
        return {
          name,
          list: this.subContractList.filter(item => item.type === name)
        };
      });
    }
  }
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.title {
  border-left: 4px solid #1890ff;
  padding-left: 5px;
  margin: 16px 0 10px;
}

.subpack-title {
  // font-size: 1.15em;
  font-weight: 800;
  margin-bottom: 10px;
}
</style>
