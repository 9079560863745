<template>
  <div>
    <div class="header">
      <img :src="icon1" alt="" />
      <span>基本信息</span>
    </div>

    <div class="panel">
      <div class="label">经办部门</div>
      <div class="value1">
        {{ contractData.operationDeptName }}
      </div>

      <div class="label">经办人</div>
      <div class="value2">
        {{ contractData.operatorName }}
      </div>

      <div class="label">关联投标及项目委托</div>
      <div class="value3">
        {{ contractData.bidApprovalPname }}
      </div>

      <div class="label">合同编号</div>
      <div class="value1">
        {{ contractData.code }}
      </div>
      <div class="label">合同金额(万元)</div>
      <div class="value2">
        <Money :money="contractData.contractAmount" />
      </div>
      <div class="label">合同名称</div>
      <div class="value3">
        {{ contractData.name }}
      </div>

      <div class="label">业主方</div>
      <div class="value3">
        {{ contractData.partyAname }}
      </div>

      <div class="label">关联合同</div>
      <div class="value3">
        {{
                contractData.relationContractName
                ? contractData.relationContractName
                : "--"
                }}
      </div>

      <div class="label">用章名称</div>
      <div class="value3 left">
        <span v-for="(item, index) in sealType" :key="item" class="left">
          <DataDictFinder dictType="sealType" :dictValue="item" />
          <span style="padding: 0 2px" v-if="index !== sealType.length - 1">
            /
          </span>
        </span>
      </div>

      <div class="label" v-if="contractData.bidApprovalPname === '无'">
        说明
      </div>
      <div class="value3" v-if="contractData.bidApprovalPname === '无'">
        {{ contractData.bidApprovalInstruction }}
      </div>
    </div>

    <div class="header">
      <img :src="icon2" alt="" />
      <span>项目信息</span>
    </div>

    <div class="panel">
      <div class="label">责任部门</div>
      <div class="value1">
        {{ contractData.productionUnitName }}
      </div>

      <div class="label">项目负责人</div>
      <div class="value2">
        {{ contractData.chargerName }}
      </div>

      <div class="label">所属区域</div>
      <div class="value1">
        {{ contractData.areaName }}/{{ contractData.subAreaName }} /{{
                contractData.l3AreaName
                }}
      </div>

      <div class="label">是否集团项目</div>
      <div class="value2">
        <span v-if="contractData.isGroup === 1">是</span>
        <span v-if="contractData.isGroup === 0">否</span>
      </div>

      <div class="label">合同性质</div>
      <div class="value1">
        {{ renderProperty(contractData.property) }}
      </div>

      <div class="label">联合体单位</div>
      <div class="value2">
        {{ contractData.unionType ? contractData.unionType : "--" }}
      </div>

      <div class="label">项目地点</div>
      <div class="value1">
        {{ contractData.belongProvince }}
        <span v-if="contractData.belongCity">/</span>
        {{ contractData.belongCity }}
        <span v-if="contractData.district">/</span>
        {{ contractData.district }}
      </div>

      <div class="label">国外</div>
      <div class="value2">
        {{ contractData.isAbroad ? contractData.isAbroad : "--" }}
      </div>

      <div class="label">总投资(万元)</div>
      <div class="value3">
        <Money :money="contractData.investment" />
      </div>

      <div class="label">服务内容</div>
      <div class="value3 left">
        <span v-for="(item, index) in phase" :key="item" class="left">
          <DataDictFinder dictType="phase" :dictValue="item" />
          <span style="padding: 0 2px" v-if="index !== phase.length - 1">
            /
          </span>
        </span>
      </div>
    </div>

    <div class="header">
      <img :src="icon3" alt="" />
      <span>专业规模</span>
    </div>

    <div class="panel">
      <div class="label">工程概况</div>
      <div class="value3">
        {{ contractData.scale }}
      </div>
    </div>

    <Padding />

    <ScaleList :list="scaleList" />

    <div class="header">
      <img :src="icon4" alt="" />
      <span>合同收款项</span>
    </div>

    <div class="collection">
      <div class="item">
        <div class="label">收款进度编号</div>
        <div class="label">收款项名称</div>
        <div class="label">收款比例</div>
        <div class="label">收款金额(万元)</div>
      </div>

      <div class="item" v-for="(item, index) in collectionList" :key="index">
        <div>{{ item.code }}</div>
        <div>{{ item.name }}</div>
        <div class="">{{ item.ratio }}%</div>
        <div class="">
          <Money :money="item.planReceipt" />
        </div>
      </div>
    </div>

    <div class="header">
      <img :src="icon5" alt="" />
      <span>附件</span>
    </div>

    <FileList :list="fileList" />
  </div>
</template>

<script>
import ScaleList from "./scale-list";
import FileList from "./file-list";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    ScaleList,
    FileList,
  },

  data() {
    return {
      icon1: require("@/assets/contract-register/icon-info.png"),
      icon2: require("@/assets/contract-register/icon-project.png"),
      icon3: require("@/assets/contract-register/icon-scale.png"),
      icon4: require("@/assets/contract-register/icon-collection.png"),
      icon5: require("@/assets/contract-register/icon-file.png"),
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra ? this.detail.extra : {};
    },
    contractData() {
      return this.extra.contractData ? this.extra.contractData : {};
    },
    sealType() {
      return typeof this.contractData.sealType === "string"
        ? this.contractData.sealType.split(",")
        : [];
    },
    phase() {
      return typeof this.contractData.phase === "string"
        ? this.contractData.phase.split(",")
        : [];
    },
    scaleList() {
      return Array.isArray(this.contractData.scaleList)
        ? this.contractData.scaleList
        : [];
    },
    collectionList() {
      return Array.isArray(this.contractData.receiptList)
        ? this.contractData.receiptList
        : [];
    },
    fileList() {
      return typeof this.contractData.attachments === "string"
        ? this.contractData.attachments.split(",").map((item) => {
            return {
              name: item,
            };
          })
        : [];
    },

    ...mapGetters("setting", ["findDataDict"]),

    contractCategoryList() {
      return this.findDataDict("contractCategory");
    },
  },

  methods: {
    renderProperty(value) {
      let name = "";
      for (let i = 0; i < this.contractCategoryList.length; i++) {
        const item = this.contractCategoryList[i];
        if (Array.isArray(item.children)) {
          const obj = item.children.find((element) => element.value === value);
          if (obj) {
            name = obj.name;
            break;
          }
        }
      }
      return name;
    },
  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  &>div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    font-weight: bold;
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
  color: #1890ff;
  font-weight: bold;
  font-size: 14px;

  img {
    width: 14px;
    margin-right: 4px;
  }
}

.collection {
  border-top: 1px solid #e8e8e8;

  .item {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr;

    &>div {
      padding: 8px;
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
    }

    &>div:first-child {
      border-left: 1px solid #e8e8e8;
    }

    .label {
      font-weight: bold;
      background-color: #fafafa;
    }
  }
}
</style>

