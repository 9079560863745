<template>
  <div>
    <div class="title">
      <img :src="icon" alt="" />
      <span>{{ title }}</span>

      <div class="extra">
        <slot name="extra"></slot>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: ["icon", "title"],
};
</script>

<style lang="less" scoped>
.title {
  color: #1890ff;
  font-weight: 400;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e9e9e9;

  display: flex;
  align-items: center;

  img {
    height: 15px;
  }

  & > span {
    margin-left: 4px;
  }

  .extra {
    margin-left: auto;
  }
}
</style>