<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">会议名称</div>
      <div class="">
        {{ info.meetName }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">督办事项</div>
      <div class="">
        {{ info.name }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">办结时限</div>
      <div class="">
        <div v-if="info.delayedDate">
          <span style="padding-right: 4px">
            {{ info.delayedDate }}
          </span>
          <a-tooltip>
            <template slot="title">
              原定办结时限为{{ info.originalDate }}
            </template>
            <a-tag color="red"> 延期 </a-tag>
          </a-tooltip>
        </div>
        <div v-else>
          {{ info.originalDate }}
        </div>
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">督办类型</div>
      <div class="left">
        <DataDictFinder dictType="dblx" :dictValue="info.type" />
        <span> / </span>
        {{ getSubType(info.type, info.subType) }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">执行人</div>
      <div class="">
        {{ info.assigneeName }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">部门名称</div>
      <div class="">{{ info.assigneeDeptName }}</div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">办结要求</div>
      <div class="">
        {{ info.remarks }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">相关附件</div>
      <div class="">
        <a-space v-if="Array.isArray(info.fileList)">
          <a
            v-for="(item, index) in info.fileList"
            :key="index"
            :href="item.completePath"
            download
            target="_blank"
            >{{ item.name }}</a
          >
        </a-space>
      </div>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
    info() {
      if (this.detail.extra && this.detail.extra.superviseTaskData) {
        return this.detail.extra.superviseTaskData;
      } else {
        return {};
      }
    },

    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("dblx");
    },
  },

  methods: {
    // 查询子类型
    getSubType(type, subType) {
      const obj = this.typeList.find((item) => item.value === type);
      return obj && Array.isArray(obj.children)
        ? obj.children.find((item) => item.value === subType).name
        : "";
    },
  },
};
</script>