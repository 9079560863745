var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._t("default")],2),_c('a-modal',{attrs:{"title":"加签","visible":_vm.visible,"footer":null},on:{"cancel":_vm.show}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"选择加签方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'signType',
            {
              initialValue: _vm.signType,
              rules: [
                {
                  required: true,
                  message: '请选择',
                },
              ],
            },
          ]),expression:"[\n            'signType',\n            {\n              initialValue: signType,\n              rules: [\n                {\n                  required: true,\n                  message: '请选择',\n                },\n              ],\n            },\n          ]"}],on:{"change":_vm.onRadioSelect}},_vm._l((_vm.signTypeList),function(item){return _c('a-radio',{key:item.value,staticStyle:{"margin-bottom":"10px"},attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" "),_c('img',{staticStyle:{"width":"120px","height":"50px","margin-left":"30px"},attrs:{"src":item.url}})])}),1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSet),expression:"isSet"}],attrs:{"label":"审批后是否结束任务"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'onSet',
            {
              initialValue: false,
              valuePropName: 'checked',
            },
          ]),expression:"[\n            'onSet',\n            {\n              initialValue: false,\n              valuePropName: 'checked',\n            },\n          ]"}],attrs:{"checked-children":"是","un-checked-children":"否"}})],1),_c('EmployeeSelector',{attrs:{"single":false,"value":_vm.assignees},on:{"change":_vm.onPersonSelect}},[_c('a-button',{staticClass:"button",attrs:{"type":"primary","size":"small"}},[_vm._v(" 选择成员"+_vm._s(_vm.assigneesLen > 0 ? `(${_vm.assigneesLen})` : "")+" ")]),(_vm.assigneesLen > 0)?_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.assigneesName))]):_vm._e()],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("确定")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }