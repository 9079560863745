<template>
  <div>
    <Title>人员可分配数值汇总</Title>
    <table class="table" cellspacing="0">
      <tr>
        <th>部门</th>
        <th>人员</th>
        <th class="text-right">占比</th>
        <th class="text-right">成本(万元)</th>
        <th class="text-right">可分配数值(万元)</th>
        <th class="text-right">调整可分配总数值(万元)</th>
      </tr>

      <template v-if="withTotalList.length > 0">
        <tr v-for="item in withTotalList" :key="item.userId">
          <td>
            {{ item.deptUniqueName }}
          </td>
          <td>
            {{ item.userName }}
          </td>
          <td class="text-right">{{ item.typeRatio }}%</td>
          <td class="text-right money-color">
            <Money :money="item.costAmount" />
          </td>
          <td class="text-right money-color">
            <Money :money="item.allocateAmount" />
          </td>
          <td class="text-right money-color">
            <Money :money="item.money" />
          </td>
        </tr>
      </template>
      <tr v-else>
        <td colspan="6" style="padding: 24px 0">
          <a-empty />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Title from "../../Title";

export default {
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    listSortByDepartment() {
      if (this.list.length > 0) {
        const arr = [...this.list].sort((x, y) => {
          if (x.deptSort < y.deptSort) {
            return -1;
          } else if (x.deptSort > y.deptSort) {
            return 1;
          } else {
            return 0;
          }
        });

        return arr.map((item) => {
          //             三院、四院、京津的人的可分配数值+成本=调整后总可分配数值
          // 非三院、四院、京津的人的可分配数值-成本=调整后总可分配数值
          const str =
            "1001A4100000003TY4YP,1001A4100000003TY4YW,1001A410000000FM0UC9"; // 三、四、京津分院的部门ID
          let money = 0; // 调整后总可分配数值
          if (str.includes(item.deptId)) {
            money = item.costAmount
              ? item.costAmount + item.allocateAmount
              : item.allocateAmount;
          } else {
            money = item.costAmount
              ? item.allocateAmount - item.costAmount
              : item.allocateAmount;
          }

          return {
            ...item,
            money,
          };
        });
      } else {
        return [];
      }
    },

    withTotalList() {
      if (this.listSortByDepartment.length > 0) {
        let typeRatio = 0;
        let costAmount = 0;
        let allocateAmount = 0;
        let money = 0;

        this.listSortByDepartment.forEach((element) => {
          if (typeof element.typeRatio === "number") {
            typeRatio += element.typeRatio;
          }
          if (typeof element.costAmount === "number") {
            costAmount += element.costAmount;
          }
          if (typeof element.allocateAmount === "number") {
            allocateAmount += element.allocateAmount;
          }
          if (typeof element.money === "number") {
            money += element.money;
          }
        });

        return [
          ...this.listSortByDepartment,
          {
            userId: "total",
            deptUniqueName: "合计",
            userName: "--",
            typeRatio: typeRatio.toFixed(2),
            costAmount: Math.round(costAmount),
            allocateAmount: Math.round(allocateAmount),
            money: Math.round(money),
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
}
th,
td {
  padding: 8px;
  border: 1px solid #d9d9d9;
  min-width: 100px;
}
th {
  background-color: #f9f9f9;
}
.text-right {
  text-align: right;
}
</style>