<template>
    <div>
        <div class="panel">
            <template v-for="(item, index) in formData">
                <template v-if="index % 2 === 0">
                    <div class="label" :key="item.label">{{ item.label }}</div>
                    <div class="value1" :key="item.label + index">
                        <template v-if="Array.isArray(item._value)">
                            <span v-for="(file, fileIndex) in item._value" :key="file.id">
                                <a :href="file.url" target="_blank">{{ file.name
                                }} </a>
                                <span style="color: #1890ff" v-if="fileIndex !== item._value.length - 1">, </span>
                            </span>
                        </template>
                        <template v-else>
                            <a v-if="item.label === '项目名称' && extra.url" target="_blank" :href="extra.url">{{ item._value }}</a>
                            <span v-else>{{ item._value }}</span>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="label" :key="item.label">{{ item.label }}</div>
                    <div class="value2" :key="item.label + index">
                        <template v-if="Array.isArray(item._value)">
                            <span v-for="(file, fileIndex) in item._value" :key="file.id">
                                <a :href="file.url" target="_blank">{{ file.name
                                }} </a>
                                <span style="color: #1890ff" v-if="fileIndex !== item._value.length - 1">, </span>
                            </span>

                        </template>
                        <template v-else>
                            <a v-if="item.label === '项目名称' && extra.url" target="_blank" :href="extra.url">{{ item._value }}</a>
                            <span v-else>{{ item._value }}</span>
                        </template>
                    </div>
                </template>
            </template>
        </div>
        <Padding />
    </div>
</template>
  
<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("approval", ["detail"]),
        extra() {
            return this.detail.extra ? this.detail.extra : {}
        },
        formData() {

            if (Array.isArray(this.extra)) {
                return this.extra.map(item => {
                    return {
                        ...item,
                        _value: this.getValue(item)
                    }
                })
            } else {

                  return Array.isArray(this.extra.formData) ? this.extra.formData.map(item => {

                    return {
                        ...item,
                        _value: this.getValue(item)
                    }

                }) : []
            }

        }
    },

    methods: {
        getValue(item) {
            if (item.value && item.value.type && item.value[item.value.type]) {
                const obj = item.value[item.value.type];
                if (typeof obj === 'string') {
                    return obj;
                }
                if (obj && obj.targetName) {
                    return obj.targetName;
                }
                if (Array.isArray(obj)) {
                    return obj
                }
                return '--'
            } else {
                return '--'
            }
        }
    }
};
</script>
  
  
<style lang="less" scoped>
.panel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid #e8e8e8;

    &>div {
        padding: 8px;
        border-bottom: 1px solid #e8e8e8;
    }

    .label {
        background-color: #fafafa;
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
    }

    .value1 {
        grid-column-start: 2;
        grid-column-end: 4;
    }

    .value2 {
        grid-column-start: 5;
        grid-column-end: 7;
        border-right: 1px solid #e8e8e8;
    }

    .value3 {
        grid-column-start: 2;
        grid-column-end: 7;
        border-right: 1px solid #e8e8e8;
    }
}
</style>
  
  