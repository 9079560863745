import { render, staticRenderFns } from "./change-approval.vue?vue&type=template&id=00a715aa&scoped=true"
import script from "./change-approval.vue?vue&type=script&lang=js"
export * from "./change-approval.vue?vue&type=script&lang=js"
import style0 from "./change-approval.vue?vue&type=style&index=0&id=00a715aa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a715aa",
  null
  
)

export default component.exports