<template>
  <EmployeeSelector
    :disabled="disabled"
    :single="false"
    @change="onSelect"
    :value="noticeList"
  >
    <a-button :disabled="disabled" type="primary" size="small" class="button">
      <img class="icon" :src="approval6Image" alt="">
      知会{{ noticeList.length > 0 ? `(${noticeList.length})` : "" }}
    </a-button>
  </EmployeeSelector>
</template>

<script>
import approval6Image from '@/assets/approval6.png'

import EmployeeSelector from "@/components/employee-selector";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    noticeList: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    EmployeeSelector,
  },

  data() {
    return {
      approval6Image,
    }
  },

  methods: {
    onSelect(arr) {
      this.$emit("setNoticeList", arr);
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  display: flex;
  align-items: center;
}
.icon {
  height: 10px;
  margin-right: 2px;
}
</style>