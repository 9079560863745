<template>
  <a-space>
    <a
      v-for="item in filteredList"
      :key="item.id"
      :href="item.completePath"
      target="_blank"
      download
    >
      {{ item.name }}
    </a>
  </a-space>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // 防止后端给出 [null] 报错
    filteredList() {
      return Array.isArray(this.list) ? this.list.filter((item) => item) : [];
    },
  },
};
</script>