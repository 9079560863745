<template>
  <div>
    <Info />
    <Table />
  </div>
</template>

<script>
import Info from "./info.vue";
import Table from "./table.vue";
export default {
  components: {
    Info,
    Table,
  },
};
</script>