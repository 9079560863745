<template>
  <div>
    <Title>申领物料清单</Title>
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="酒水品名" data-index="name"> </a-table-column>
      <a-table-column title="单价(元)" data-index="unitPrice"> </a-table-column>
      <a-table-column title="数量" data-index="quantity"> </a-table-column>
      <a-table-column title="总价(元)" data-index="sumPrice"> </a-table-column>
    </a-table>
  </div>
</template>

<script>
import Title from "../Title";
import { mapState } from "vuex";

export default {
  components: {
    Title,
  },

  computed: {
    ...mapState("approval", ["detail"]),

    list() {
      return this.detail.extra?.materials ?? [];
    },
  },
};
</script>