var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.visible = true}}},[_vm._v("批量添加发票")]),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"开票","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"发票号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'invoiceCode',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'invoiceCode',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"发票数量(个)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'num',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'num',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1),_c('a-form-item',{attrs:{"label":"发票金额(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'invoicePrice',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'invoicePrice',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1),_c('a-form-item',{attrs:{"label":"税点"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'taxRate',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'taxRate',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":100,"formatter":_vm.$ratioFormatter}})],1),_c('a-form-item',{attrs:{"label":"旧发票号码"}},[_c('InvoiceSelector',{attrs:{"record":_vm.selectedInvoice},on:{"getValue":(obj) => {
              _vm.selectedInvoice = obj;
            }}})],1),_c('a-form-item',{attrs:{"label":"业务内容"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'business',
            { rules: [{ required: true, message: '请输入！' }] },
          ]),expression:"[\n            'business',\n            { rules: [{ required: true, message: '请输入！' }] },\n          ]"}]})],1),_c('a-form-item',[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('a-button',{attrs:{"type":"primary","icon":"upload"}},[_vm._v(" 上传附件 ")])],1),_c('div',{staticClass:"link-list"},_vm._l((_vm.fileList),function(item,index){return _c('div',{key:item,staticClass:"link"},[_c('a',{attrs:{"target":"_blank","download":"","href":item}},[_vm._v(_vm._s(_vm.$getFileName(item)))]),_c('div',{staticClass:"control",on:{"click":function($event){return _vm.deleteFile(index)}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)])}),0)],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("提交")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }