<template>
    <div>
      <div class="panel">
        <div class="label">台账单号</div>
        <div class="value1">{{ extra.code }}</div>
        <div class="label">费用性质</div>
        <div class="value2">{{ getFeeNature() }}</div>
        <div class="label">编制单位</div>
        <div class="value1">{{ extra.unitName }}</div>
        <div class="label">归属部门</div>
        <div class="value2">{{ extra.deptName }}</div>
        <div class="label">填报人</div>
        <div class="value3">{{ extra.creatorName }}</div>
      </div>
      <div class="card-title">费用预算详情：</div>

      <a-table bordered :data-source="[...extra.itemList, listAll]" :pagination="false">
      <a-table-column title="序号" width="50px" align="center">
        <template slot-scope="text, row, index">
            {{ index === extra.itemList.length ? '合计' : index + 1 }}
        </template>
      </a-table-column>
      <a-table-column title="时间" data-index="createAt"></a-table-column>
      <a-table-column title="费用类型" data-index="feeType"></a-table-column>
      <a-table-column title="摘要" data-index="summary"></a-table-column>
      <a-table-column title="金额(元)" data-index="amount"></a-table-column>
      <a-table-column title="备注" data-index="remark"></a-table-column>
    </a-table>
      <Padding />
    </div>
  </template>
    
  <script>
  import { mapGetters } from "vuex";
  import { mapState } from "vuex";
  
  export default {
    computed: {
      ...mapGetters("setting", ["findDataDict"]),
      ...mapState("approval", ["detail"]),
      extra() {
        return this.detail.extra;
      },
      listAll() {
        let amount = 0;
        this.detail.extra.itemList.forEach(item => {
            if (typeof item.amount === 'number') {
              amount += item.amount;
            }
        });
        return {
          amount,
        }
      },
    },
    methods: {
      getFeeNature() {
      const result = this.findDataDict("qualitySafety.feeNature").filter(v=>v.value == this.extra.feeNatureCode)
      if (result.length > 0) {
        return result[0].name;
      }else{
        return ''
      }
    },
    }
  };
  </script>
    
    
  <style lang="less" scoped>
  .card-title {
    padding: 12px 0;
    font-weight: bold;
  }
  .panel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid #e8e8e8;
  
    & > div {
      padding: 8px;
      border-bottom: 1px solid #e8e8e8;
    }
  
    .label {
      background-color: #fafafa;
      border-right: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
  
    .value1 {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  
    .value2 {
      grid-column-start: 5;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  
    .value3 {
      grid-column-start: 2;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  }
  </style>
    
    