<template>
  <div class="major">
    <Title>专业可分配数值汇总</Title>

    <table cellspacing="0">
      <tr>
        <th>专业名称</th>
        <th class="text-right">专业占比</th>
        <th class="text-right">专业可分配数值(万元)</th>
        <th class="text-right">人员</th>
        <th class="text-right">角色名称</th>
        <th class="text-right">角色可分配数值(万元)</th>
        <th class="text-right">人员可分配数值汇总(万元)</th>
      </tr>

      <template v-if="withTotalList.length > 0">
        <template v-for="(item, index) in withTotalList">
          <template v-for="(staff, staffIndex) in item.staffList">
            <tr
              v-for="(role, roleIndex) in staff.roles"
              :key="`${index}/${staffIndex}/${roleIndex}`"
            >
              <td
                :rowspan="rowspan(item)"
                v-if="staffIndex === 0 && roleIndex === 0"
              >
                <span>
                  <DataDictFinder
                    dictType="majorTypes"
                    :dictValue="item.majorCode"
                  />
                  <DataDictFinder
                    dictType="sgphlx"
                    :dictValue="item.majorCode"
                  />
                  {{ item.all }}
                </span>
              </td>

              <td
                class="text-right"
                :rowspan="rowspan(item)"
                v-if="staffIndex === 0 && roleIndex === 0"
              >
                <span> {{ item.typeRatio }}% </span>
              </td>

              <td
                class="text-right money-color"
                :rowspan="rowspan(item)"
                v-if="staffIndex === 0 && roleIndex === 0"
              >
                <span>
                  <Money :money="item.majorAmount" />
                </span>
              </td>

              <td
                class="text-right"
                :rowspan="staff.roles.length"
                v-if="roleIndex === 0"
              >
                {{ staff.userName }}
              </td>

              <td class="text-right">
                <DataDictFinder
                  dictType="prjAllocateRole"
                  :dictValue="role.roleCode"
                />
                <span v-if="!role.roleCode">--</span>
              </td>

              <td class="text-right">
                <span class="money-color">
                  <Money :money="role.roleAllocateAmount" />
                </span>
              </td>

              <td
                class="text-right money-color"
                :rowspan="staff.roles.length"
                v-if="roleIndex === 0"
              >
                <Money :money="staff.money" />
              </td>
            </tr>
          </template>
        </template>
      </template>

      <tr v-else>
        <td colspan="7" style="padding: 24px 0">
          <a-empty />
        </td>
      </tr>
    </table>
  </div>
</template>


<script>
import Title from "../../Title";

export default {
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    majorList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    arr() {
      let staffList = [];

      this.list.forEach((item) => {
        if (Array.isArray(item.roleOutputList)) {
          item.roleOutputList.forEach((role) => {
            if (Array.isArray(role.staffOutputList)) {
              role.staffOutputList.forEach((staff) => {
                staffList.push({
                  majorCode: item.majorCode,
                  typeRatio: item.typeRatio,
                  majorAmount: item.majorAmount,
                  roleCode: role.roleCode,
                  roleAllocateAmount: staff.allocateAmount,
                  userId: staff.userId,
                  userName: staff.userName,
                  allocateAmount: staff.allocateAmount,
                });
              });
            }
          });

          if (item.roleOutputList.length === 0) {
            // 说明这是施工配合和其他
            if (Array.isArray(item.staffOutputList)) {
              item.staffOutputList.forEach((staff) => {
                staffList.push({
                  majorCode: item.majorCode,
                  typeRatio: item.typeRatio,
                  majorAmount: item.majorAmount,
                  roleCode: "",
                  roleAllocateAmount: 0,
                  userId: staff.userId,
                  userName: staff.userName,
                  allocateAmount: staff.allocateAmount,
                });
              });
            }
          }
        }
      });

      let arr = [];

      staffList.forEach((staff) => {
        const obj = arr.find((item) => item.majorCode === staff.majorCode);

        if (obj) {
          const obj2 = obj.staffList.find(
            (element) => element.userId === staff.userId
          );

          if (obj2) {
            obj2.money += staff.allocateAmount;
            obj2.roles.push({
              roleCode: staff.roleCode,
              roleAllocateAmount: staff.roleAllocateAmount,
            });
          } else {
            obj.staffList.push({
              userId: staff.userId,
              userName: staff.userName,
              allocateAmount: staff.allocateAmount,
              money: staff.allocateAmount,
              roles: [
                {
                  roleCode: staff.roleCode,
                  roleAllocateAmount: staff.roleAllocateAmount,
                },
              ],
            });
          }
        } else {
          arr.push({
            majorCode: staff.majorCode,
            typeRatio: staff.typeRatio,
            majorAmount: staff.majorAmount,
            staffList: [
              {
                userId: staff.userId,
                userName: staff.userName,
                allocateAmount: staff.allocateAmount,
                money: staff.allocateAmount,
                roles: [
                  {
                    roleCode: staff.roleCode,
                    roleAllocateAmount: staff.roleAllocateAmount,
                  },
                ],
              },
            ],
          });
        }
      });

      return arr;
    },

    withTotalList() {
      if (this.arr.length > 0) {
        let typeRatio = 0;
        let majorAmount = 0;
        let roleAllocateAmount = 0; // 角色可分配数值
        let money = 0; // 人员汇总可分配数值
        this.arr.forEach((item) => {
          if (item.typeRatio) {
            typeRatio += item.typeRatio;
          }
          if (item.majorAmount) {
            majorAmount += item.majorAmount;
          }

          if (Array.isArray(item.staffList)) {
            item.staffList.forEach((staff) => {
              if (staff.money) {
                money += staff.money;
              }

              if (Array.isArray(staff.roles)) {
                staff.roles.forEach((role) => {
                  if (role.roleAllocateAmount) {
                    roleAllocateAmount += role.roleAllocateAmount;
                  }
                });
              }
            });
          }
        });

        return [
          ...this.arr,
          {
            all: "合计",
            typeRatio: Math.floor(typeRatio),
            majorAmount,

            staffList: [
              {
                userName: "--",
                money,
                roles: [
                  {
                    roleAllocateAmount: Math.round(roleAllocateAmount),
                  },
                ],
              },
            ],
          },
        ];
      } else {
        return [];
      }
    },
  },

  methods: {
    // 占几行
    rowspan(item) {
      let num = 0;

      item.staffList.forEach((staff) => {
        // if (staff.roles.length > 0) {
        num += staff.roles.length;
        // }
      });
      console.log("row span ", item.majorCode, num);
      return num;
    },
    // 什么时候显示
    renderIndex(item) {
      let index = this.arr.findIndex(
        (element) => element.majorCode === item.majorCode
      );

      console.log("index ", index);

      if (index !== 0) {
        let num = 0;
        for (let i = 0; i < index; i++) {
          num += this.arr[i].roles.length;
        }
        return num;
      }

      return index;
    },
  },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
}
th,
td {
  padding: 8px;
  border: 1px solid #d9d9d9;
  min-width: 100px;
}
th {
  background-color: #f9f9f9;
}
.text-right {
  text-align: right;
}

.small-table {
  background-color: #fff;

  th,
  td {
    border: 1px solid #d9d9d9;
    padding: 2px 4px;
    font-size: 12px;
  }
}
</style>