<template>
  <div>
    <a-descriptions bordered size="small" :column="2">
      <a-descriptions-item>
        <div slot="label" class="center">销方名称</div>
        <div>{{ salesUnitName }}</div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">购方名称</div>
        <div>
          {{ info.buyerInfo ? info.buyerInfo.name : '' }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">开票人</div>
        <div>{{ drawer }}</div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">开票日期</div>
        <div>{{ drawDate }}</div>
      </a-descriptions-item>
    </a-descriptions>

    <Padding />

    <a-table bordered :data-source="invoiceList" :pagination="false">
      <a-table-column title="发票号码" data-index="invoiceCode" align="center">
      </a-table-column>
      <a-table-column
        title="发票金额(元)"
        data-index="invoicePrice"
        align="right"
      >
        <template slot-scope="invoicePrice">
          <span>
            {{ invoicePrice }}
          </span>
        </template>
      </a-table-column>
      <a-table-column title="税点" data-index="taxRate" align="right">
        <template slot-scope="taxRate">{{ taxRate }}%</template>
      </a-table-column>

      <a-table-column title="关联发票号码" align="center">
        <template slot-scope="text">
          <span style="color: #1890ff; cursor: pointer">{{
            text.relatedInvoiceCode
          }}</span>
        </template>
      </a-table-column>

      <a-table-column title="业务内容" data-index="business"> </a-table-column>
      <a-table-column title="发票附件" align="center">
        <template slot-scope="text">
          <div v-if="typeof text.attachments === 'string'">

              <span 
                 v-for="(item, index) in text.attachments.split(',')"
                :key="index" style="padding-right: 4px">
                  <Download :path="item" />
              </span>
          </div>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapGetters("approval", [
      "salesUnitName",
      "drawer",
      "drawDate",
      "invoiceList",
    ]),
  },
};
</script>