


<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">发票送达方式</div>
      <div>
        <DataDictFinder
          dictType="deliveryWay"
          :dictValue="sendInfo.deliveryWay"
        />
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">快递公司</div>
      <div>
        <DataDictFinder
          dictType="expressCompany"
          :dictValue="sendInfo.courierCompany"
        />
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">单据号码</div>
      <div>
        {{ sendInfo.deliveryNo }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">签收单位</div>
      <div>
        {{ sendInfo.signCompany }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">签收人</div>
      <div>
        {{ sendInfo.signPeople }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">附件</div>
      <div>
        <a
          target="_blank"
          v-if="sendInfo.attachment"
          :href="sendInfo.attachment"
          >查看</a
        >
      </div>
    </a-descriptions-item>
    <a-descriptions-item :span='2'>
      <div slot="label" class="center">发票签收模板</div>
      <div>
        <a
          target="_blank"
          href="https://s.upapi.cn/njszy/2022/6/6/47da45a174017757461fb5bd8d35b48f_发票签收单1空白模板.xlsx"
          >查看</a
        >
      </div>
    </a-descriptions-item>
    <!-- <a-descriptions-item :span="2">
      <div slot="label" class="center">部门负责人确认送达</div>
      <div>
        <a-radio-group
          :disabled="!isConfirmer || disabled"
          @change="handleConfirm"
          :value="isSent"
        >
          <a-radio
            v-for="item in booleanList"
            :key="item.value"
            :value="item.value"
            >{{ item.name }}</a-radio
          >
        </a-radio-group>
      </div>
    </a-descriptions-item> -->
  </a-descriptions>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("approval", [
      "sendInfo",
      "disabled",
      // "isConfirmer",
      // "isSent",
    ]),

    // ...mapGetters("setting", ["findDataDict"]),

    // booleanList() {
    //   return this.findDataDict("boolean");
    // },
  },

  methods: {
    // ...mapMutations("approval", ["setExpress"]),
    // // 确认送达
    // handleConfirm(e) {
    //   this.setExpress({
    //     isSent: e.target.value,
    //   });
    // },
  },
};
</script>