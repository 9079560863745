<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">出借人</div>
      <div class="">
        {{ detail.extra.userName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">出借部门</div>
      <div class="">
        {{ detail.extra.deptName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">申请日期</div>
      <div class="">
        {{ detail.extra.applyDate }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">最高保密级别</div>
      <div class="">
        <DataDictFinder
          dictType="secretLevel"
          :dictValue="detail.extra.topLevel"
        />
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">预计归还日期</div>
      <div class="">
        {{ detail.extra.returnDate }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">出借事由</div>
      <div class="">
        {{ detail.extra.remarks }}
      </div>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
  },
};
</script>

