<template>
  <a-table :data-source="list" bordered :pagination="false">
    <a-table-column title="" width="40px" align="center">
      <template slot-scope="text">
        <a-radio :checked="text.id === selectedId" />
      </template>
    </a-table-column>
    <a-table-column title="分包名称" data-index="name" />
    <a-table-column
      title="分包方"
      data-index="companyName"
      width="240px"
      ellipsis="true"
    ></a-table-column>

    <a-table-column title="分包属性" align="center">
      <template slot-scope="text">
        <span v-if="text.isneeded === 1">设计类-必要分包</span>
        <span v-if="text.isneeded === 0">设计类-非必要分包</span>
        <span v-if="text.isneeded === 2">总承包类分包</span>
      </template>
    </a-table-column>

    <a-table-column title="分包类别" align="center">
      <template slot-scope="text">
        <DataDictFinder
          v-if="text.isneeded === 1"
          dictType="subType"
          :dictValue="text.type"
        />
        <DataDictFinder
          v-if="text.isneeded === 0"
          dictType="notNeedSubType"
          :dictValue="text.type"
        />
        <DataDictFinder
          v-if="text.isneeded === 2"
          dictType="generalSubType"
          :dictValue="text.type"
        />
      </template>
    </a-table-column>

    <a-table-column title="专业类型" align="center">
      <template slot-scope="text">
        <DataDictFinder
          dictType="majorTypes"
          :dictValue="text.major"
          v-if="text.major"
        />
        <span v-else>--</span>
      </template>
    </a-table-column>

    <a-table-column title="预计分包金额(万元)" align="right">
      <template slot-scope="text">
        <Money :money="text.planAmount" />
      </template>
    </a-table-column>

    <a-table-column title="状态" align="center">
      <template slot-scope="text">
        <div>
          <span v-if="text.id === selectedId">
            <a-badge status="processing" text="签订中" />
          </span>
          <span
            v-else-if="text.status === 'notReady' || text.status === 'ready'"
          >
            <a-badge status="processing" text="待签" />
          </span>
          <span
            v-else-if="
              text.status === 'reviewing' ||
              text.status === 'approved' ||
              text.status === 'signing'
            "
          >
            <a-badge status="processing" text="签订中" />
          </span>
          <span v-else>
            <DataDictFinder
              dictType="taskReviewStatus"
              :dictValue="text.status"
              iconType="badge"
            />
          </span>
        </div>
      </template>
    </a-table-column>

    <a-table-column title="创建时间" align="center">
      <template slot-scope="text">
        {{ text.createAt }}
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: String,
      default: "",
    },
  },
};
</script>

