<template>
  <div class="list">
    <div class="item">
      <div class="label">专业</div>
      <div class="label">子专业</div>
      <div class="label">规模类型</div>
    </div>
    <div class="item" v-for="(text, index) in list" :key="index">
      <div>
        <span>{{ findMajor(text.major) }}</span>
      </div>
      <div>
        <span>{{ findSubMajor(text.major, text.majorSub) }}</span>
      </div>
      <div>
        <span>{{ findSize(text.major, text.majorSub, text.scale) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    majorList() {
      return this.findDataDict("contract_scale");
    },
  },

  methods: {
    findMajor(value) {
      const obj = this.majorList.find((item) => item.value === value);
      return obj ? obj.name : "";
    },
    findSubMajor(major, value) {
      let name = "";
      const majorObj = this.majorList.find((item) => item.value === major);
      if (majorObj) {
        if (majorObj && majorObj.children) {
          const subMajorObj = majorObj.children.find(
            (item) => item.value === value
          );
          if (subMajorObj) {
            name = subMajorObj.name;
          }
        }
      }

      return name;
    },
    findSize(major, subMajor, value) {
      let name = "";
      const majorObj = this.majorList.find((item) => item.value === major);
      if (majorObj) {
        if (majorObj && majorObj.children) {
          const subMajorObj = majorObj.children.find(
            (item) => item.value === subMajor
          );
          if (subMajorObj && subMajorObj.children) {
            const sizeObj = subMajorObj.children.find(
              (item) => item.value === value
            );
            if (sizeObj) {
              name = sizeObj.name;
            }
          }
        }
      }

      return name;
    },
  },
};
</script>


<style lang="less" scoped>
.list {
  border-top: 1px solid #e8e8e8;

  .item {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr;

    & > div {
      padding: 8px;
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
    }
    & > div:first-child {
      border-left: 1px solid #e8e8e8;
    }

    .label {
      font-weight: bold;
      background-color: #fafafa;
    }
  }
}
</style>