<template>
    <div>
        <div class="title">建筑施工类</div>
        <a-table bordered :data-source="extra?.building" :pagination="false">
            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index + 1 }}
                </template>
            </a-table-column>
            <a-table-column title="风险点" data-index="point"></a-table-column>
            <a-table-column title="风险描述" data-index="riskDesc"></a-table-column>
            <a-table-column title="风险类型" data-index="type">
                <template slot-scope="type">
                    <DataDictFinder dictType="qualitySafety.riskType" :dictValue="type" />
                </template>
            </a-table-column>
            <a-table-column title="风险等级" data-index="level">
                <template slot-scope="level">
                    <DataDictFinder dictType="qualitySafety.riskLevel" :dictValue="level" />
                </template>
            </a-table-column>
            <a-table-column title="管控措施" data-index="measure"></a-table-column>
            <a-table-column title="责任人" data-index="managerName"></a-table-column>
            <a-table-column title="责任部门" data-index="deptName"></a-table-column>
            <a-table-column title="备注" data-index="remark"></a-table-column>
        </a-table>
        <div class="title">办公日常类</div>
        <a-table bordered :data-source="extra?.officeRoutine" :pagination="false">
            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index + 1 }}
                </template>
            </a-table-column>
            <a-table-column title="风险点" data-index="point"></a-table-column>
            <a-table-column title="风险描述" data-index="riskDesc"></a-table-column>
            <a-table-column title="风险类型" data-index="type">
                <template slot-scope="type">
                    <DataDictFinder dictType="qualitySafety.riskType" :dictValue="type" />
                </template>
            </a-table-column>
            <a-table-column title="风险等级" data-index="level">
                <template slot-scope="level">
                    <DataDictFinder dictType="qualitySafety.riskLevel" :dictValue="level" />
                </template>
            </a-table-column>
            <a-table-column title="管控措施" data-index="measure"></a-table-column>
            <a-table-column title="责任人" data-index="managerName"></a-table-column>
            <a-table-column title="责任部门" data-index="deptName"></a-table-column>
            <a-table-column title="备注" data-index="remark"></a-table-column>
        </a-table>
        <Padding />
    </div>
</template>
  
<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("approval", ["detail"]),
        extra() {
            return this.detail.extra.riskDetail;
        },
    },
};
</script>
  
  
<style lang="less" scoped>
.title {
  font-size: 14px;
  color: #1890ff;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: 8px;
}
.card-title {
    padding: 12px 0;
    font-weight: bold;
}

.panel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid #e8e8e8;

    &>div {
        padding: 8px;
        border-bottom: 1px solid #e8e8e8;
    }

    .label {
        background-color: #fafafa;
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
    }

    .value1 {
        grid-column-start: 2;
        grid-column-end: 4;
    }

    .value2 {
        grid-column-start: 5;
        grid-column-end: 7;
        border-right: 1px solid #e8e8e8;
    }

    .value3 {
        grid-column-start: 2;
        grid-column-end: 7;
        border-right: 1px solid #e8e8e8;
    }
}
</style>
  
  