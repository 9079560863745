<template>
  <div>
    <TransferInfo />
    <TransferTable />
  </div>
</template>

<script>
import TransferInfo from "./transfer-info.vue";
import TransferTable from "./transfer-table.vue";
export default {
  components: {
    TransferInfo,
    TransferTable,
  },
};
</script>