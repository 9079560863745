<template>
  <a-table bordered :dataSource="list" :pagination="false">
    <a-table-column title="设计编号" width="140px">
      <template slot-scope="text">
        {{ text.designCode }}
      </template>
    </a-table-column>
    <a-table-column title="子项目名称">
      <template slot-scope="text">
        {{ text.name }}
      </template>
    </a-table-column>
    <a-table-column title="原子项目金额(万元)" align="right" width="150px">
      <template slot-scope="text">
        <Money :money="text.amountOld" />
      </template>
    </a-table-column>
    <a-table-column title="变更后子项目金额(万元)" align="right" width="150px">
      <template slot-scope="text">
        <Money :money="text.amountNew" />
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>