<template>
  <div>
    <div class="header">
      <img :src="icon1" alt="" />
      <span>基本信息</span>
    </div>

    <div class="panel">
      <div class="label">经办部门</div>
      <div class="value1">
        {{ subConRegisterData.operationDeptName }}
      </div>

      <div class="label">经办人</div>
      <div class="value2">
        {{ subConRegisterData.operatorName }}
      </div>

      <div class="label">合同编号</div>
      <div class="value1">
        {{ subConRegisterData.contractCode }}
      </div>
      <div class="label">合同名称</div>
      <div class="value2">
        {{ subConRegisterData.contractName }}
      </div>

      <div class="label">工程概况</div>
      <div class="value3">
        {{ subConRegisterData.scale }}
      </div>

      <div class="label">分包名称</div>
      <div class="value3">
        {{ subConRegisterData.name }}
      </div>

      <div class="label">分包方</div>
      <div class="value3">
        {{ subConRegisterData.companyName }}
      </div>

      <div class="label">合同金额(万元)</div>
      <div class="value1">
        <Money :money="subConRegisterData.actualAmount" />
      </div>

      <div class="label">用章名称</div>
      <div class="value2 left">
        <span v-for="(item, index) in sealType" :key="item" class="left">
          <DataDictFinder dictType="sealType" :dictValue="item" />
          <span style="padding: 0 2px" v-if="index !== sealType.length - 1">
            /
          </span>
        </span>
      </div>
    </div>

    <div class="header">
      <img :src="icon2" alt="" />
      <span>分包信息</span>
    </div>

    <div class="panel">
      <div class="label">所属区域</div>
      <div class="value3">
        {{ subConRegisterData.areaName }}
        <span v-if="subConRegisterData.subAreaName">/</span>
        {{ subConRegisterData.subAreaName }}
        <span v-if="subConRegisterData.l3AreaName">/</span>
        {{ subConRegisterData.l3AreaName }}
      </div>

      <div class="label">项目地点</div>
      <div class="value1">
        {{ subConRegisterData.belongProvince }}
        <span v-if="subConRegisterData.belongCity">/</span>
        {{ subConRegisterData.belongCity }}
        <span v-if="subConRegisterData.district">/</span>
        {{ subConRegisterData.district }}
      </div>

      <div class="label">国外</div>
      <div class="value2">
        {{ subConRegisterData.isAbroad ? subConRegisterData.isAbroad : "--" }}
      </div>

      <div class="label">分包属性</div>
      <div class="value1">
        <span v-if="subConRegisterData.isneeded === 1">设计类-必要分包</span>
        <span v-if="subConRegisterData.isneeded === 0">设计类-非必要分包</span>
        <span v-if="subConRegisterData.isneeded === 2">总承包类分包</span>
      </div>
      <div class="label">分包类别</div>
      <div class="value2">
        <span v-if="subConRegisterData.isneeded === 1">
          <DataDictFinder dictType="subType" :dictValue="subConRegisterData.type" />
        </span>
        <span v-if="subConRegisterData.isneeded === 0">
          <DataDictFinder dictType="notNeedSubType" :dictValue="subConRegisterData.type" />
        </span>
        <span v-if="subConRegisterData.isneeded === 2">
          <DataDictFinder dictType="generalSubType" :dictValue="subConRegisterData.type" />
        </span>
      </div>

      <div class="label">专业类型</div>
      <div class="value3">
        <DataDictFinder dictType="majorTypes" :dictValue="subConRegisterData.major" v-if="subConRegisterData.major" />
        <span v-else>--</span>
      </div>

      <div class="label">支付方式</div>
      <div class="value3">
        {{ subConRegisterData.paymentMode }}
      </div>
    </div>

    <div class="header">
      <img :src="icon3" alt="" />
      <span>项目分包明细(含预测)</span>

      <span style='margin-left: auto' class='left'>主合同额：
        <Money :money="subConRegisterData.contractAmount" />
        万元
      </span>
    </div>

    <List :list="subpackageList" :selectedId="selectedId" />

    <div class="header">
      <img :src="icon5" alt="" />
      <span>附件</span>
    </div>

    <a-table bordered :dataSource="fileList" :pagination="false">
      <a-table-column title="附件名称">
        <template slot-scope="text">
          <Download :path="text.name" />
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import List from "./list";
import { mapState } from "vuex";
export default {
  components: {
    List,
  },

  data() {
    return {
      icon1: require("@/assets/contract-register/icon-info.png"),
      icon2: require("@/assets/contract-register/icon-project.png"),
      icon3: require("@/assets/contract-register/icon-scale.png"),
      icon5: require("@/assets/contract-register/icon-file.png"),
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra ? this.detail.extra : {};
    },
    subConRegisterData() {
      return this.extra.subConRegisterData ? this.extra.subConRegisterData : {};
    },
    sealType() {
      return typeof this.subConRegisterData.sealType === "string"
        ? this.subConRegisterData.sealType.split(",")
        : [];
    },

    subpackageList() {
      return Array.isArray(this.subConRegisterData.subPackagedList)
        ? this.subConRegisterData.subPackagedList.map((item) => {
            return {
              ...item,
              key: item.id,
            };
          })
        : [];
    },
    selectedSubpackage() {
      return this.subConRegisterData.subPackaged
        ? this.subConRegisterData.subPackaged
        : {};
    },

    selectedId() {
      return this.subConRegisterData.subPackaged
        ? this.subConRegisterData.subPackaged.id
        : "";
    },

    fileList() {
      return typeof this.subConRegisterData.attachments === "string"
        ? this.subConRegisterData.attachments.split(",").map((item) => {
            return {
              name: item,
            };
          })
        : [];
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  &>div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    font-weight: bold;
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
  color: #1890ff;
  font-weight: bold;
  font-size: 14px;

  img {
    width: 14px;
    margin-right: 4px;
  }
}
</style>

