<template>
  <div>
    <Title>部门可分配数值汇总</Title>
    <table class="table" cellspacing="0">
      <tr>
        <th>部门名称</th>
        <th class="text-right">部门占比</th>
        <th class="text-right">可分配数值(万元)</th>
      </tr>
      <template v-if="withTotalList.length > 0">
        <tr v-for="(item, index) in withTotalList" :key="index">
          <td>
            {{ item.deptUniqueName }}
          </td>

          <td class="text-right">
            <span> {{ item.typeRatio }}% </span>
          </td>
          <td class="text-right money-color">
            <span>
              <Money :money="item.amount" />
            </span>
          </td>
        </tr>
      </template>
      <tr v-else>
        <td colspan="3" style="padding: 24px 0">
          <a-empty />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Title from "../../Title";

export default {
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filterList() {
      if (this.list.length > 0) {
        return [...this.list].sort((x, y) => {
          if (x.deptSort < y.deptSort) {
            return -1;
          } else if (x.deptSort > y.deptSort) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        return [];
      }
    },

    withTotalList() {
      if (this.filterList.length > 0) {
        let amount = 0;
        let typeRatio = 0;
        this.filterList.forEach((element) => {
          if (element.amount) {
            amount += element.amount;
            typeRatio += element.typeRatio;
          }
        });
        return [
          ...this.filterList,
          {
            deptUniqueName: "合计",
            amount: Math.round(amount),
            typeRatio: typeRatio.toFixed(2),
          },
        ];
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  th,
  td {
    padding: 8px;
    border: 1px solid #d9d9d9;
    min-width: 100px;
  }
  th {
    background-color: #f9f9f9;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
}
</style>