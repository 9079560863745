<template>
  <div>
    <div class="header">
      <img :src="icon1" alt="" />
      <span>合同信息</span>
    </div>

    <div class="panel">
      <div class="label">经办部门</div>
      <div class="value1">
        {{ contractData.operationDeptName }}
      </div>

      <div class="label">经办人</div>
      <div class="value2">
        {{ contractData.operatorName }}
      </div>

      <div class="label">合同名称</div>
      <div class="value3">
        {{ contractData.name }}
      </div>

      <div class="label">合同方</div>
      <div class="value3">
        {{ contractData.partyAname }}
      </div>

      <div class="label">所属区域</div>
      <div class="value3">
        {{ contractData.areaName }}
        <span v-if="contractData.subAreaName">/</span>
        {{ contractData.subAreaName }}
        <span v-if="contractData.l3AreaName">/</span>
        {{ contractData.l3AreaName }}
      </div>

      <div class="label">是否有金额或费用比例</div>
      <div class="value3">
        <span v-if="contractData.isKjContractExpense === 1">是</span>
        <span v-if="contractData.isKjContractExpense === 0">否</span>
      </div>

      <div class="label">用章名称</div>
      <div class="value3 left">
        <span v-for="(item, index) in sealType" :key="item" class="left">
          <DataDictFinder dictType="sealType" :dictValue="item" />
          <span style="padding: 0 2px" v-if="index !== sealType.length - 1">
            /
          </span>
        </span>
      </div>

      <div class="label">合同主要内容</div>
      <div class="value3">
        {{ contractData.remarks }}
      </div>
    </div>

    <Padding />

    <div class="header">
      <img :src="icon5" alt="" />
      <span>附件</span>
    </div>

    <FileList :list="fileList" />
  </div>
</template>

<script>
import FileList from "./file-list";
import { mapState } from "vuex";
export default {
  components: {
    FileList,
  },

  data() {
    return {
      icon1: require("@/assets/contract-register/icon-info.png"),
      icon5: require("@/assets/contract-register/icon-file.png"),
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra ? this.detail.extra : {};
    },
    contractData() {
      return this.extra.kjContractRegisterData
        ? this.extra.kjContractRegisterData
        : {};
    },
    sealType() {
      return typeof this.contractData.sealType === "string"
        ? this.contractData.sealType.split(",")
        : [];
    },

    fileList() {
      return typeof this.contractData.attachments === "string"
        ? this.contractData.attachments.split(",").map((item) => {
            return {
              name: item,
            };
          })
        : [];
    },
  },

  methods: {},
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  &>div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    font-weight: bold;
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
  color: #1890ff;
  font-weight: bold;
  font-size: 14px;

  img {
    width: 14px;
    margin-right: 4px;
  }
}
</style>

