<template>
  <div>
    <BaseInfo :info="info" />

    <template v-if="applyType === 'change' || applyType === 'refund'">
      <div class="title">退换票信息</div>
      <ChangeRefund :info="info" />
    </template>

    <div class="title">项目信息</div>
    <ProjectInfo :info="info" />

    <Collection :info="info" />

    <!-- <div class="title">分包信息</div>
    <SubPackage :info="info" /> -->

    <div class="title">购方信息</div>
    <Buyer :info="info" />

    <template v-if="isFinanceDrawer">
      <div class="title">财务开票</div>
      <div v-if="!disabled">
        <Draw :info="info" />
      </div>
      <div v-else>
        <Invoiced :info="info" />
      </div>
    </template>
    <template v-else>
      <div class="title">财务开票</div>
      <Invoiced :info="info" />
    </template>

    <template v-if="isPostMan">
      <div class="title">送达确认</div>
      <div v-if="!disabled">
        <WriteExpress />
      </div>
      <div v-else>
        <Express />
      </div>
    </template>
    <template v-else>
      <div class="title">送达确认</div>
      <Express />
    </template>

      <div class="title">申请材料</div>
             <a-table
                    bordered
                    :dataSource="fileList"
                    :pagination="false"
                
                
                  >
                    <a-table-column title="名称" >
                      <template slot-scope="text">
                        <a :href="text.completePath" target="_blank">{{ $getFileName( text.completePath) }}</a>
                      </template>
                    </a-table-column>
                 
                  </a-table>

  

  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BaseInfo from "./components/BaseInfo.vue"; // 开票基本信息
import ProjectInfo from "./components/ProjectInfo.vue"; // 项目信息
// import SubPackage from "./components/SubPackage.vue"; // 分包信息
import Collection from "./components/collection.vue";
import Buyer from "./components/Buyer.vue"; // 购方信息
import Draw from "./components/Draw"; // 开票填写
import Invoiced from "./components/Invoiced"; // 已填写好的开票信息

import WriteExpress from "./components/WriteExpress"; // 填写送达信息
import Express from "./components/Express"; // 已填写好的送达信息

import ChangeRefund from "./components/ChangeRefund"; // 退换票信息

export default {
  components: {
    BaseInfo,
    ProjectInfo,
    // SubPackage,
    Collection,
    Buyer,
    Draw,
    Invoiced,

    WriteExpress,
    Express,

    ChangeRefund,
  },

  computed: {
    ...mapState("approval", ["detail"]),

    info() {
      return this.detail.extra;
    },
    fileList() {
      return typeof (this.info.materials) ==='string' ? this.info.materials.split(',').map(item => {
        return{
          completePath: item,
        }
      }) : []
    },

    ...mapGetters("approval", [
      "disabled",
      "isFinanceDrawer",
      "isPostMan",
      "applyType",
    ]),
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
  font-size: 17px;
  margin: 12px 0;
}
</style>
