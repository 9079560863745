<template>
  <div>
    <div class="panel">
      <div class="label">实习部门</div>
      <div class="value1">{{ extra.deptName }}</div>
      <div class="label">对接人</div>
      <div class="value2">{{ extra.contact }}</div>
      <div class="label">实习时段</div>
      <div class="value3">{{ extra.internFrom }}至{{ extra.internTo }}</div>
    </div>
    <Padding />
    <div style="margin-top: 20px;">
      <div>发放资料情况</div>
      <div class="panel">
        <div class="label">实习生姓名</div>
        <div class="value1">{{ extra.name }}</div>
        <div class="label">实习生手机号</div>
        <div class="value2">{{ extra.mobile }}</div>
        <div class="label">出生年月</div>
        <div class="value1">{{ extra.birthday }}</div>
        <div class="label">性别</div>
        <div class="value2">
          <DataDictFinder dictType="sex" :dictValue="extra.sex" />
        </div>
        <div class="label">本科学校</div>
        <div class="value1">{{ extra.bachelorSchool }}</div>
        <div class="label">本科专业</div>
        <div class="value2">{{ extra.bachelorMajor }}</div>
        <div class="label">本科在校时段</div>
        <div class="value1">{{ extra.bachelorFrom.slice(0,7) }}至{{ extra.bachelorTo.slice(0,7) }}</div>
        <div class="label">硕士学校</div>
        <div class="value2">{{ extra.masterSchool }}</div>
        <div class="label">硕士专业</div>
        <div class="value1">{{ extra.masterMajor }}</div>
        <div class="label">硕士在校时段</div>
        <div class="value2">{{ extra.masterFrom.slice(0,7) }}至{{ extra.masterTo.slice(0,7) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getName } from "@/utils/clock";

export default {
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra
        ? {
          ...this.detail.extra,
        }
        : {};
    },
  },
  methods: {
    getName,

    renderStaff(str) {
      let msg = "";
      try {
        const list = JSON.parse(str);
        msg = list.map((item) => item.userName).join("，");
      } catch (error) {
        msg = str;
      }

      return msg;
    },
  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  &>div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>