<template>
  <div>
    <div class="header">
      <img :src="icon1" alt="" />
      <span>基本信息</span>
    </div>

    <div class="panel">
      <div class="label">申请部门</div>
      <div class="value1">
        {{ contractData.applyDeptName }}
      </div>

      <div class="label">申请人</div>
      <div class="value2">
        {{ contractData.applyUserName }}
      </div>

      <div class="label">合同名称</div>
      <div class="value3">
        {{ contractData.name }}
      </div>
      <div class="label">设计编号</div>
      <div class="value1">
        {{ contractData.code }}
      </div>
      <div class="label">原合同金额(万元)</div>
      <div class="value2">
        <Money :money="contractData.amountOld" />
      </div>

      <div class="label">变更后合同金额(万元)</div>
      <div class="value3">
        <Money :money="contractData.amountNew" />
      </div>
    </div>

    <div class="header">
      <img :src="icon2" alt="" />
      <span>子项目列表</span>
    </div>

    <List :list="list" />

    <div class="header">
      <img :src="icon3" alt="" />
      <span>内部资料</span>
    </div>
    <Files :list="internalFileList" />

    <div class="header">
      <img :src="icon3" alt="" />
      <span>外部资料</span>
    </div>
    <Files :list="externalFileList" />

  </div>
</template>

<script>
import List from "./list";
import Files from './files.vue'
import { mapState } from "vuex";
export default {
  components: {
    List,
    Files,
  },

  data() {
    return {
      icon1: require("@/assets/contract-register/icon-info.png"),
      icon2: require("@/assets/contract-register/icon-project.png"),
      icon3: require('@/assets/contract-register/icon-file.png')
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra ? this.detail.extra : {};
    },
    contractData() {
      return this.extra.contractChangeAmountData
        ? this.extra.contractChangeAmountData
        : {};
    },
    list() {
      return Array.isArray(this.contractData.projectList)
        ? this.contractData.projectList
        : [];
    },
    internalFileList() {
      return Array.isArray(this.contractData.internalFileList) ? this.contractData.internalFileList : []
    },
    externalFileList() {
      return Array.isArray(this.contractData.externalFileList) ? this.contractData.externalFileList : []
    },

  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  &>div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    font-weight: bold;
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
  color: #1890ff;
  font-weight: bold;
  font-size: 14px;

  img {
    width: 14px;
    margin-right: 4px;
  }
}
</style>

