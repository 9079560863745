<template>
  <div>
    <a-table bordered :data-source="showList" :pagination="false">
      <a-table-column title="资料类型" data-index="typeValue"> </a-table-column>
      <a-table-column key="file" title="文件名称">
        <template slot-scope="text">
              <Download :path="text.filePath" />
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    phaseCode: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    showList() {
      // 展示在页面上的文件列表，需要添加文件类型，从数据字典中查找
      const res = this.findDataDict("fileType");
      const typeObj = res.find((item) => item.value === this.phaseCode);
      if (typeObj && Array.isArray(typeObj.children)) {
        let newList = [];
        for (let i = 0; i < this.list.length; i++) {
          const element = this.list[i];
          const result = typeObj.children.find(
            (item) => item.value === element.type
          );
          if (result) {
            newList.push({
              ...element,
              typeValue: result.name,
            });
          }
        }
        return newList;
      } else {
        return this.list;
      }
    },
  },
};
</script>