import { render, staticRenderFns } from "./PublicBidding.vue?vue&type=template&id=23ff8830&scoped=true"
import script from "./PublicBidding.vue?vue&type=script&lang=js"
export * from "./PublicBidding.vue?vue&type=script&lang=js"
import style0 from "./PublicBidding.vue?vue&type=style&index=0&id=23ff8830&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ff8830",
  null
  
)

export default component.exports