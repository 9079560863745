<template>
  <div>
    <div class="panel">
      <div class="label">检查时间段</div>
      <div class="value3">{{ extra.startTime + " ~ " + extra.endTime }}</div>

      <div class="label">受检单位</div>
      <div class="value3">{{ extra.inspectUnitName }}</div>

      <div class="label">受检项目</div>
      <div class="value3">{{ extra.inspectProjectName }}</div>
    </div>

    <div class="card-title">问题与整改计划清单</div>
    <a-table bordered :data-source="problemList" :pagination="false" rowKey="id">
      <a-table-column title="问题类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="qualityProblemType" :dictValue="text.type" />
        </template>
      </a-table-column>
      <a-table-column title="问题描述" data-index="description" />
      <a-table-column title="整改要求" data-index="demand" />

      <a-table-column title="整改措施" data-index="measure" />
      <a-table-column title="整改情况" align="center">
        <template slot-scope="text">
          <a-badge v-if="text.confirmStatus === 1" status="success" text="已完成" />
          <a-badge v-if="text.confirmStatus === 0" status="error" text="未完成" />
        </template>
      </a-table-column>

      <a-table-column title="完成时间" data-index="finishTime" align="center" />

      <a-table-column title="问题照片">
        <template slot-scope="text">
          <span>
            <span v-if="typeof text.problemPicture === 'string'">
              <a
                v-for="item in text.problemPicture.split(',')"
                :key="item"
                :href="item"
                target="_blank"
                style="padding-right: 4px"
              >{{$getFileName(item)}}</a>
            </span>
          </span>
        </template>
      </a-table-column>

      <a-table-column title="整改后照片">
        <template slot-scope="text">
          <span>
            <span v-if="typeof text.rectifyPicture === 'string'">
              <a
                v-for="item in text.rectifyPicture.split(',')"
                :key="item"
                :href="item"
                target="_blank"
                style="padding-right: 4px"
              >{{$getFileName(item)}}</a>
            </span>
          </span>
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center" v-if="isChargeOfRectification && !disabled">
        <template slot-scope="text, row, index">
          <a href="#" @click.prevent="edit(text, index)">整改</a>
        </template>
      </a-table-column>
    </a-table>

    <a-modal title="整改" :visible="activeIndex !== -1" :footer="null" @cancel="cancel">
      <a-form :form="form" @submit="handleSubmit" :colon="false">
        <a-form-item label="整改措施">
          <a-input
            v-decorator="['measure',     {
          initialValue: activeText.measure,
           rules: [{ required: true, message: '请输入' }] }, ]"
          />
        </a-form-item>

        <a-form-item label="完成时间">
          <a-date-picker
            placeholder
            v-decorator="[
                  'finishTime',
                  { 
          initialValue: activeText.finishTime ? moment( activeText.finishTime) : undefined,

                    rules: [{ required: true, message: '请选择' }] },
                ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="整改情况">
          <a-radio-group
            v-decorator="[
                                'confirmStatus',
                                {
                                  initialValue: activeText.confirmStatus,
                                   rules: [{ required: true, message: '请选择' }] },
                              ]"
          >
            <a-radio :value="1">已完成</a-radio>
            <a-radio :value="0">未完成</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="整改后照片">
          <Images :list="images" />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button type="primary" html-type="submit">确认</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>

    <div class="card-title">整改依据</div>

    <a-textarea
      v-if="isChargeOfRectification && !disabled"
      :value="extra.evidence"
      @change="onChange"
      :autoSize="{minRows: 3}"
    />
    <span v-else>{{ extra.evidence }}</span>

    <div class="card-title">参与检查人员</div>
    <a-table bordered :data-source="memberList" :pagination="false" rowKey="userId">
      <a-table-column title="姓名" data-index="userName" align="center" />
      <a-table-column title="类型" align="center">
        <template slot-scope="text">
          <span v-if="text.type === 'groupLeader'">检查组长</span>
          <span v-if="text.type === 'groupMember'">检查组组员</span>
          <span v-if="text.type === 'unitMember'">受检单位成员</span>
          <span v-if="text.type === 'projectMember'">受检项目人员</span>

          <span v-if="text.type === 'doubleChecker'">复查人</span>
          <span v-if="text.type === 'inspectManager'">检查负责人</span>
          <span v-if="text.type === 'rectifyManager'">整改负责人</span>
          <span v-if="text.type === 'rectifier'">整改人</span>

        </template>
      </a-table-column>
      <a-table-column title="职责分工" data-index="duty" />
    </a-table>
  </div>
</template>


<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import moment from "moment";

import Images from "@/views/technical-quality/security-check/components/images.vue";

export default {
  components: {
    Images
  },

  computed: {
    ...mapState("approval", ["detail"]),

    ...mapGetters("approval", ["isChargeOfRectification", "disabled"]),

    extra() {
      return this.detail.extra
        ? {
            ...this.detail.extra,
            attachments: this.detail.extra.attachments
              ? this.detail.extra.attachments.split(",")
              : []
          }
        : {};
    },
    memberList() {
      return Array.isArray(this.extra.memberList) ? this.extra.memberList : [];
    },
    problemList() {
      return Array.isArray(this.extra.problemList)
        ? this.extra.problemList
        : [];
    }
  },

  data() {
    return {
      form: this.$form.createForm(this),
      activeIndex: -1,
      activeText: {},
      images: []
    };
  },

  watch: {
    activeIndex() {
      if (this.activeIndex !== -1) {
        if (
          typeof this.activeText.rectifyPicture === "string" &&
          this.activeText.rectifyPicture
        ) {
          this.images = this.activeText.rectifyPicture.split(",");
        }
      }
    }
  },

  methods: {
    moment,

    ...mapMutations("approval", ["setDetail"]),

    onChange(e) {
      console.log("e", e.target.value);

      this.setDetail({
        ...this.detail,
        extra: this.detail.extra
          ? {
              ...this.detail.extra,
              evidence: e.target.value
            }
          : {}
      });
    },
    edit(text, index) {
      this.activeIndex = index;
      this.activeText = text;
    },
    cancel() {
      this.activeIndex = -1;
      this.activeText = {};
      this.images = [];
      this.form.resetFields();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);

          const problemList = [...this.problemList];

          problemList.splice(this.activeIndex, 1, {
            ...this.activeText,
            ...values,
            finishTime: values.finishTime.format("YYYY-MM-DD"),
            rectifyPicture: this.images.join(",")
          });

          this.setDetail({
            ...this.detail,
            extra: this.detail.extra
              ? {
                  ...this.detail.extra,
                  problemList
                }
              : {}
          });

          this.cancel();
        }
      });
    }
  }
};
</script>
  
  
<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.card-title {
  padding: 12px 0;
  font-weight: bold;
}

.content {
  padding: 8px;
  border: 1px solid #e8e8e8;
}
</style>