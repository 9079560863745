<template>
  <div>
    <div class="flex">
      <div class="flex-1 content">
        {{detail.name}}
        (元)
      </div>

      <div class="flex-1 content">{{ total ? Number(total).toLocaleString() : total }}</div>

      <div class="flex-1 flex flex-col">
        <div class="flex-1 content">
          开票
          <Invoice :type="detail.name">
            <a-icon type="right-circle" class="icon-btn" style="color: #1890ff" />
          </Invoice>
        </div>
        <div class="flex-1 content">回款</div>
      </div>

      <div class="flex-1">
        <div class="content">金额(元)</div>
        <div class="content">比例(%)</div>
        <div class="content">金额(元)</div>
        <div class="content">比例(%)</div>
      </div>

      <div class="flex-1">
        <div
          class="content"
        >{{ detail. invoiceAmt ? Number(detail. invoiceAmt).toLocaleString() : 0 }}</div>
        <div class="content">{{ ratio.invoiceR }}</div>
        <div class="content">{{ detail. payedAmt ? Number(detail. payedAmt).toLocaleString() : 0 }}</div>
        <div class="content">{{ ratio.payedR }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatRatio } from "@/utils/formatRatio.js";
import Invoice from "./Invoice.vue";
export default {
  components: {
    Invoice
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    total() {
      return typeof this.detail.contractAmt === "number"
        ? this.detail.contractAmt
        : 0;
    },

    ratio() {
      return {
        invoiceR: formatRatio(this.detail.invoiceAmt, this.total),
        payedR: formatRatio(this.detail.payedAmt, this.total)
      };
    }
  }
};
</script>

<style lang="less" scoped>
.content {
  padding: 8px;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px -1px -1px 0px;
}

.icon-btn {
  cursor: pointer;
  margin-left: 5px;
}
</style>
