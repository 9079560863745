<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">移交人</div>
      <div class="">
        {{ detail.extra.userName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">移交部门</div>
      <div class="">
        {{ detail.extra.deptName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">移交日期</div>
      <div class="">
        {{ detail.extra.date }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="1">
      <div slot="label" class="center">最高保密级别</div>
      <div class="">
        <DataDictFinder
          dictType="secretLevel"
          :dictValue="detail.extra.topLevel"
        />
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">档案移交说明</div>
      <div class="">
        {{ detail.extra.remarks }}
      </div>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
  },
};
</script>

