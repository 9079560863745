<template>
  <div id="package-pay-table-task">
    <a-table
      :dataSource="list"
      :pagination="false"
      bordered
      :columns="columns"
      :locale="locale"
    >
      <template #title>
        <div class="title-container">
          <span class="title">{{ title }}</span>
          <span class="unit">单位：元</span>
        </div>
      </template>

      <template slot="itemName" slot-scope="text, record">
        <a-input v-model="record.itemName" />
      </template>

      <template slot="payableAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.payableAmt"
        />
      </template>

      <template slot="prePayAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.prePayAmt"
        />
      </template>

      <template slot="watEleAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.watEleAmt"
        />
      </template>

      <template slot="grtAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.grtAmt"
        />
      </template>

      <template slot="sctAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.sctAmt"
        />
      </template>

      <template slot="otherAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.otherAmt"
        />
      </template>

      <template slot="sum" slot-scope="text, record">
        <a-input-number :formatter="$inputNumberFormat" v-model="record.sum" />
      </template>

      <template slot="paidAmt" slot-scope="text, record">
        <a-input-number
          :formatter="$inputNumberFormat"
          v-model="record.paidAmt"
        />
      </template>
    </a-table>

    <div class="test flex">
      <div style="width: 300px; border-left: 1px solid #e8e8e8">合计</div>
      <div style="width: 70px">{{ totalObj.payableAmt }}</div>
      <div style="width: 70px">{{ totalObj.prePayAmt }}</div>
      <div style="width: 70px">{{ totalObj.watEleAmt }}</div>
      <div style="width: 70px">{{ totalObj.grtAmt }}</div>
      <div style="width: 70px">{{ totalObj.sctAmt }}</div>
      <div style="width: 70px">{{ totalObj.otherAmt }}</div>
      <div style="width: 70px">{{ totalObj.sum }}</div>
      <div style="width: 70px">{{ totalObj.paidAmt }}</div>
    </div>

    <div class="test flex">
      <div style="width: 300px; border-left: 1px solid #e8e8e8">
        本次实付金额(元)
      </div>
      <div style="width: 210px">{{ totalObj.paidAmt }}</div>
      <div style="width: 140px">累计已付金额(元)</div>
      <div style="width: 210px">{{ sumObj.totalPaidAmt }}</div>
    </div>

    <div class="test flex">
      <div style="width: 300px; border-left: 1px solid #e8e8e8">
        本次实付百分比(%)
      </div>
      <div style="width: 210px">{{ sumObj.paidRatio }}</div>
      <div style="width: 140px">累计付款百分比(%)</div>
      <div style="width: 210px">{{ sumObj.totalPaidRatio }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    // 初始值
    defaultData: {
      type: Array,
      default: () => [],
    },
    sumObj: {
      type: Object,
      default: () => ({
        paidAmt: 0,
        paidRatio: 0,
        totalPaidAmt: 0,
        totalPaidRatio: 0,
      }),
    },
  },
  data() {
    return {
      list: [],
      locale: { emptyText: " " }, //设置空数据的展示
      columns: [
        {
          title: "序号",
          dataIndex: "key",
          width: 50,
          align: "center",
        },
        {
          title: "款项名称",
          dataIndex: "itemName",
          align: "center",
          width: 250,
          scopedSlots: { customRender: "itemName" },
        },
        {
          title: "本次应付金额",
          dataIndex: "payableAmt",
          align: "center",
          width: 70,
          scopedSlots: { customRender: "payableAmt" },
        },
        {
          title: "应扣款项",
          align: "center",
          children: [
            {
              title: "预付款",
              dataIndex: "prePayAmt",
              key: "prePayAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "prePayAmt" },
            },
            {
              title: "水电费",
              dataIndex: "watEleAmt",
              key: "watEleAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "watEleAmt" },
            },
            {
              title: "质保金",
              dataIndex: "grtAmt",
              key: "grtAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "grtAmt" },
            },
            {
              title: "安全保证金",
              dataIndex: "sctAmt",
              key: "sctAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "sctAmt" },
            },
            {
              title: "其他",
              dataIndex: "otherAmt",
              key: "otherAmt",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "otherAmt" },
            },
            {
              title: "合计",
              dataIndex: "sum",
              key: "sum",
              align: "center",
              width: 70,
              scopedSlots: { customRender: "sum" },
            },
          ],
        },
        {
          title: "本次实付金额",
          dataIndex: "paidAmt",
          align: "center",
          width: 70,
          scopedSlots: { customRender: "paidAmt" },
        },
      ],
      totalObj: {
        payableAmt: 0,
        prePayAmt: 0,
        watEleAmt: 0,
        grtAmt: 0,
        sctAmt: 0,
        otherAmt: 0,
        sum: 0,
        paidAmt: 0,
      },
      previousPay: 0,
    };
  },
  watch: {
    // 初始值异步获取到，因此监听初始值，并设置初始值
    defaultData: {
      handler(val) {
        console.log(val);
        this.list = val;
        // 添加key
        this.list.forEach((item, index) => {
          item.key = index + 1;
        });

        // 计算合计
        this.changeList();
        this.calcTotal();
      },
      immediate: true,
    },
  },

  methods: {
    // 修改list内部字段：total
    changeList() {
      this.list.forEach((item) => {
        item.sum =
          Number(item.prePayAmt || "") +
          Number(item.watEleAmt || "") +
          Number(item.grtAmt || "") +
          Number(item.sctAmt || "") +
          Number(item.otherAmt || "");
      });
    },

    // 计算合计子项
    calcTotal() {
      for (const key in this.totalObj) {
        this.totalObj[key] = this.list.reduce((pre, cur) => {
          return pre + (cur[key] || 0);
        }, 0);
      }
    },
  },
};
</script>
<style lang="less" scoped>
#package-pay-table-task {
  width: 860px;
  .title-container {
    text-align: center;
    padding: 5px;
    position: relative;

    .title {
      font-size: 1.2em;
      font-weight: bolder;
    }
    .unit {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #1890ff;
    }
  }

  .test {
    width: 860px;
    margin: 0 auto;
    div {
      border-bottom: 1px solid #e8e8e8;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      text-align: center;
      padding: 5px;
    }
  }
}
</style>

<style lang="less">
#package-pay-table-task {
  margin: 0 auto;

  .ant-table-tbody > tr > td {
    padding: 0;
  }
  .ant-input {
    padding: 2px;
    margin-right: 2px;
    border: none;
    text-align: center;
  }
  .ant-input-number {
    width: 60px;
    border: none;
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-input {
      padding: 0;
      text-align: center;
    }
  }
}
</style>
