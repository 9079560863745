<template>
  <div>
    <div class="panel">
      <div class="label">项目编号</div>
      <div class="value1">
        {{ data.designCode }}
      </div>

      <div class="label">招标编号</div>
      <div class="value2">
        {{ data.bidCode}}
      </div>

      <div class="label">工程名称</div>
      <div class="value3">
        {{ data.projectName}}
      </div>

      <div class="label">标段名称</div>
      <div class="value3">
        {{ data.bidName}}
      </div>

      <div class="label">开标日期</div>
      <div class="value1">
        {{ moment(data.bidStartDate).format('YYYY-MM-DD HH:mm:ss') }}
      </div>

      <div class="label">截标日期</div>
      <div class="value2">
        {{ moment(data.bidEndDate).format('YYYY-MM-DD HH:mm:ss') }}
      </div>

      <div class="label">数量及单位(个)</div>
      <div class="value1">
        {{ data.countUnit}}
      </div>

      <div class="label">要求到货时间</div>
      <div class="value2">
        {{ moment(data.prdArrivalDate).format('YYYY-MM-DD HH:mm:ss')}}
      </div>

      <div class="label">受邀公司</div>
      <div class="value3">
        {{ invitedCompany }}
      </div>

      <div class="label">主要内容</div>
      <div class="value3">
        {{ data.bidInvitedContent}}
      </div>

      <div class="label">附件</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachmentList)">
          <a v-for="(item) in data.attachmentList"
             :key="item.id"
             target="_blank"
             :href="item.completePath"
             style="display:block;">
            {{item.name}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.purchaseData
        ? this.detail.extra.purchaseData
        : {};
    },
    invitedCompany() {
      const company = JSON.parse(this.data.invitedCompany);
      return Array.isArray(company)
        ? company
            .map((item) => {
              return item.name;
            })
            .join(",")
        : "";
    },
  },
  methods: {
    moment() {
      return moment();
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>

