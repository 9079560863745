<template>
  <div>
    <a-descriptions bordered size="small" :column="2">
      <a-descriptions-item>
        <div slot="label" class="center">销方名称</div>
        <div>
          <a-select
            style="width: 300px"
            :value="invoiceInfos.salesUnitId"
            @change="onSaleChange"
          >
            <a-select-option
              v-for="item in saleList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">购方名称</div>
        <div>
          {{ info.buyerInfo ? info.buyerInfo.name : "" }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">开票人</div>
        <div>
          {{ user.userName }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">开票日期</div>
        <div>
          <a-date-picker @change="onDateChange" :defaultValue="defaultValue" />
        </div>
      </a-descriptions-item>
    </a-descriptions>

    <Padding />

    <div class="right">
      <a-space>
        <Add />
        <MultipleAdd />
        <a-button type='danger' size='small' @click="deleteAll">批量删除</a-button>
      </a-space>
    </div>
    <Padding />

    <a-table bordered :data-source="invoiceList" :pagination="false"
     rowKey="invoiceCode"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }"
    >
      <a-table-column title="发票号码" data-index="invoiceCode" align="center">
      </a-table-column>
      <a-table-column
        title="发票金额(元)"
        data-index="invoicePrice"
        align="right"
      >
        <template slot-scope="invoicePrice">
          <span>
            {{ invoicePrice }}
          </span>
        </template>
      </a-table-column>
      <a-table-column title="税点" data-index="taxRate" align="right">
        <template slot-scope="taxRate">{{ taxRate }}%</template>
      </a-table-column>

      <a-table-column title="旧发票号码" align="center">
        <template slot-scope="text">
          <span style="color: #1890ff; cursor: pointer">{{
            text.oldInvoiceCode
          }}</span>
        </template>
      </a-table-column>

      <a-table-column title="业务内容" data-index="business"> </a-table-column>
      <a-table-column title="发票附件" align="center">
        <template slot-scope="text">
          <div v-if="typeof text.attachments === 'string'">
            <a
              v-for="(item, index) in text.attachments.split(',')"
              :key="index"
              target="_blank"
              :href="item"
            >
              {{ $getFileName(item) }}
            </a>
          </div>
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text, record, index">
          <a-space>
            <Edit :text="text" :index="index" />
            <a href="#" class="danger" @click.prevent="deleteText(index)"
              >删除</a
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { fetchList } from "@/api/setting/sale";
import Add from "./Add.vue";
import MultipleAdd from "./multiple-add.vue";
import Edit from "./Edit.vue";
import moment from "moment";
export default {
  components: {
    Add,
    MultipleAdd,
    Edit,
  },

  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      saleList: [], // 销方列表
      defaultValue: moment(),

      selectedRowKeys: [],
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("approval", ["invoiceList"]),

    invoiceInfos() {
      return this.info.invoiceInfos ? this.info.invoiceInfos : {};
    },
  },

  mounted() {
    fetchList().then((res) => {
      if (Array.isArray(res.list)) {
        this.saleList = res.list;
      }
    });
  },

  methods: {

    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },

    deleteAll() {
      if (this.selectedRowKeys.length === 0){
        this.$message.error("请选择发票")
        return;
      }
      this.selectedRowKeys.forEach(key => {
        const index = this.invoiceList.findIndex(item => item.invoiceCode === key)
        if (index > -1) {
        this.invoiceList.splice(index, 1)
        }
      });
      this.addInvoice([...this.invoiceList])
      this.selectedRowKeys = []
      
    },

    deleteText(index) {
      this.invoiceList.splice(index, 1);
      this.addInvoice([...this.invoiceList]);
    },

    ...mapMutations("approval", [
      "addInvoice",
      "addSale",
      "addDrawer",
      "addDrawDate",
    ]),

    // 选择销方
    onSaleChange(id) {
      const obj = this.saleList.find((item) => item.id === id);
      this.addSale(obj);
    },

    onDateChange(date) {
      console.log("date", date);
      if (date) {
        this.addDrawer(this.user.userName);
        this.addDrawDate(date.format("YYYY-MM-DD HH:mm:ss"));
      }
    },
  },
};
</script>