<template>
  <div>
    <div
      v-for="(item, index) in detail.extra.phaseProgressData.phaseProgresses"
      :key="index"
    >
      <Title
        >{{ item.phaseName }}

        <span style="padding-left: 4px" v-if="item.quota"
          >({{ item.quota }}%)</span
        >
      </Title>

      <a-space>
        <span>当前进度：</span>
        <span class="progress">{{ item.progress }} %</span>
      </a-space>

      <Padding />

      <FileList
        :phaseCode="item.phaseCode"
        :list="Array.isArray(item.outputList) ? item.outputList : []"
      />
    </div>

    <Title>收款进度</Title>

    <a-table bordered :dataSource="planReceiptData" :pagination="false">
      <a-table-column title="编号" align="center">
        <template slot-scope="text">
          {{ text.code }}
        </template>
      </a-table-column>
      <a-table-column title="收款项名称">
        <template slot-scope="text">
          {{ text.name }}
        </template>
      </a-table-column>
      <a-table-column title="收款比例" align="right">
        <template slot-scope="text">
          <span>{{ text.ratio }}%</span>
        </template>
      </a-table-column>
      <a-table-column title="收款金额(万元)" align="right">
        <template slot-scope="text">
          <Money :money="text.planReceipt" />
        </template>
      </a-table-column>

      <a-table-column title="进度确认" align="center">
        <template slot-scope="text">
          <a-checkbox :checked="text.isFinished === 1 ? true : false" />
        </template>
      </a-table-column>
    </a-table>
    <Padding />
  </div>
</template>

<script>
import Title from "../Title";
import FileList from "./file-list.vue";
import { mapState } from "vuex";
export default {
  components: {
    Title,
    FileList,
  },
  computed: {
    ...mapState("approval", ["detail"]),
    planReceiptData() {
      if (
        this.detail.extra &&
        this.detail.extra.phaseProgressData &&
        Array.isArray(this.detail.extra.phaseProgressData.planReceiptData)
      ) {
        return this.detail.extra.phaseProgressData.planReceiptData;
      }
      return [];
    },
  },
};
</script>

<style lang="less" scoped>
.progress {
  font-size: 16px;
  font-weight: bold;
  color: #1890ff;
}
</style>






