<template>
  <div>
    <Title>阶段可分配数值汇总</Title>
    <div class="total">
      <span class="money-color">
        <Money :money="value - cost" />
      </span>
      <span class="unit">万元</span>
      <span v-if="cost" class="cost">
        <span style="padding-left: 24px">(已扣除专业分包成本：</span>
        <span style="color: #1890ff; font-size: 18px; padding-right: 4px">
          <Money :money="cost" />
        </span>
        <span>万元)</span>
      </span>
    </div>
  </div>
</template>

<script>
import Title from "../../Title";

export default {
  components: {
    Title,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    cost: {},
  },
};
</script>

<style lang="less" scoped>
.total {
  margin-left: 18px;
  font-size: 24px;
  .cost {
    font-size: 14px;
  }
  .unit {
    font-size: 12px;
    padding-left: 8px;
  }
}
</style>