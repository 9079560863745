<template>
    <div>
      <div class="name">
        <span>项目名称：</span><span>{{data.projectName}}</span>
      </div>
      <div class="panel">
        <div class="label">标段名称</div>
        <div class="value3">
          {{ data.name }}
        </div>
  
        <div class="label no-border-bottom">开标地点</div>
        <div class="value1 no-border-bottom">
          {{ data.bidAddress}}
        </div>
  
        <div class="label no-border-bottom">开标时间</div>
        <div class="value2 no-border-bottom">
          {{ data.bidDate}}
        </div>
      </div>
  
      <a-table bordered :dataSource="registers" :pagination="false">
          <a-table-column title="序号" width="50px" align="center">
            <template slot-scope="text, row, index">
                {{ index + 1 }}
            </template>
          </a-table-column>
          <a-table-column title="投标单位名称" data-index="user" align="center"/>
          <a-table-column title="报价/万元" data-index="quoteFirst"  align="center"/>
          <a-table-column title="二次报价/万元" data-index="quoteSec"  align="center"/>
          <a-table-column title="付款方式是否相应" data-index="isPaymentResponse"  align="center">
            <template slot-scope="text">
                  <span v-if="text">是</span>
                  <span v-else>否</span>
            </template>
          </a-table-column>
          <a-table-column title="资信承诺是否符合" data-index="isQualification"  align="center">
            <template slot-scope="text">
                  <span v-if="text">是</span>
                  <span v-else>否</span>
            </template>
          </a-table-column>
          <a-table-column title="供货范围是否符合" data-index="isSupplyScope"  align="center">
            <template slot-scope="text">
                  <span v-if="text">是</span>
                  <span v-else>否</span>
            </template>
          </a-table-column>
          <a-table-column title="商务/技术要求是否符合" data-index="isTecBusiness"  align="center">
            <template slot-scope="text">
                  <span v-if="text">是</span>
                  <span v-else>否</span>
            </template>
          </a-table-column>
          <a-table-column title="投标单位答疑回复" data-index="responseQuestion"  align="center">
          </a-table-column>
      </a-table>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    computed: {
      ...mapState("approval", ["detail"]),
      data() {
        return this.detail.extra ? this.detail.extra : {};
      },
      registers(){
        return this.data.registers;
      }
    },
  };
  </script>
  
  
  <style lang="less" scoped>
    .name{
        margin-bottom: 20px;
        span{
            font-weight: 800;
            font-size: 16px;
        }
        span:last-child{
            color: #1890ff;
        }
    }

  .panel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid #e8e8e8;
  
    & > div{
      padding: 8px;
      border-bottom: 1px solid #e8e8e8;
    }
    .no-border-bottom{
      border-bottom: none;
    }
  
    .label {
      background-color: #fafafa;
      border-right: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
  
    .value1 {
      grid-column-start: 2;
      grid-column-end: 4;
    }
    .value2 {
      grid-column-start: 5;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
    .value3 {
      grid-column-start: 2;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  }
  
  </style>
  
  