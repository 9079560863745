import { render, staticRenderFns } from "./SubSettleComment.vue?vue&type=template&id=7bb4c45a&scoped=true"
import script from "./SubSettleComment.vue?vue&type=script&lang=js"
export * from "./SubSettleComment.vue?vue&type=script&lang=js"
import style0 from "./SubSettleComment.vue?vue&type=style&index=0&id=7bb4c45a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb4c45a",
  null
  
)

export default component.exports