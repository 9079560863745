<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">项目名称</div>
      <div>
        {{ info.projectInfo.name }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">设计编号</div>
      <div>
        {{ info.projectInfo.designCode }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">合同性质</div>
      <div>
        <DataDictFinder dictType="prjProperty" :dictValue="info.projectInfo.projectType" />
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">是否总院项目</div>
      <div>
        <DataDictFinder dictType="boolean" :dictValue="String(info.projectInfo.productHead)" />
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">项目归属</div>
      <div>
        {{ info.projectInfo.productionUnitName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">所属区域指挥部</div>
      <div>
        {{ info.projectInfo.areaName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">合作单位</div>
      <div>
        {{ info.projectInfo.partner }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">发包人</div>
      <div>
        {{
                info.projectInfo.partyAname
                ? info.projectInfo.partyAname
                : info.projectInfo.partyaname
                }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">合同总价（元）</div>
      <div>
        {{ info.projectInfo.projectAmount }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">已开发票（元）</div>
      <div>
        {{
                info.projectInfo.designCode === "0000000000"
                ? "--"
                : info.projectInfo.invoiceAmount
                }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">是否集团项目</div>
      <div>
        <DataDictFinder dictType="boolean" :dictValue="String(info.projectInfo.isGroupProject)" />
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">集团名称</div>
      <div>
        {{ info.projectInfo.groupName }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item :span="2">
      <div slot="label" class="center">项目进展情况</div>
      <div>
        {{ info.projectInfo.prjProgressDesc }}
      </div>
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>