<template>
  <div>
    <Title>出借档案清单</Title>
    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="档案编号" data-index="code"> </a-table-column>
      <a-table-column title="档案名称" data-index="name"> </a-table-column>

      <a-table-column title="类型">
        <template slot-scope="text">
          {{ getTypeText(text.type) }}
        </template>
      </a-table-column>
      <a-table-column title="保密级别" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="secretLevel" :dictValue="text.level" />
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import archiveType from "@/mixins/archive-type";
import Title from "../Title";
import { mapState } from "vuex";

export default {
  mixins: [archiveType],

  components: {
    Title,
  },

  computed: {
    ...mapState("approval", ["detail"]),

    list() {
      return this.detail.extra?.archives ?? [];
    },
  },
};
</script>