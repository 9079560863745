<template>
  <div>
    <div class="panel">
      <div class="content">
        <p>采购询价函</p>
        <div>
          <p style="text-indent:2em;">(受邀报价单位):{{data.invitedCompanyList}}</p>
          <p style="text-indent:4em;">我单位拟以询价采购方式进行下列货物的批量采购，请按一下要求
            于{{fileArrivalDate.year()}}年{{fileArrivalDate.month()}}月{{fileArrivalDate.date()}}日（星期） {{fileArrivalDate.day()}}时将报价文件封送我司{{data.fileSendAddr}}</p>
        </div>

        <div class="item">
          <p>一、拟采购货物一览表</p>
          <a-table bordered
                   :data-source="tableList"
                   :pagination="false"
                   :loading="loading"
                   style="margin:0 20px;">
            <a-table-column title="序号"
                            width="50px"
                            align="center">
              <template slot-scope="text, row, index">
                {{ index + 1 }}
              </template>
            </a-table-column>
            <a-table-column title="货物名称"
                            data-index="name"
                            align="center" />
            <a-table-column title="型号规格、主要技术参数及标准配置"
                            data-index="config"
                            align="center" />
            <a-table-column title="数量"
                            data-index="quantity"
                            align="center" />
            <a-table-column title="备注"
                            data-index="extra"
                            align="center" />
          </a-table>
        </div>

        <div class="item">
          <p>二、支付方式</p>
          <div>
            {{data.payMethod}}微信
          </div>
        </div>

        <div class="item">
          <p>三、供货周期</p>
          <div>
            {{data.deliveryCycle}}微信
          </div>
        </div>

        <div class="item">
          <p>四、售后及其他要求</p>
          <div>
            {{data.afterSalesRemark}}售后及其他要求售后及其他要求售后及其他要求售后及其他要求售后及其他要求售后及其他要求售后及其他要求售后及其他要求售后及其他要求
          </div>
        </div>

        <div class="item">
          <p>联系人:{{data.contact}}</p>
          <p style="text-align: center;">日期:{{data.time}}</p>
        </div>

      </div>

      <div class="label">附件</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachmentList)">
          <a v-for="(item) in data.attachmentList"
             :key="item.id"
             target="_blank"
             :href="item.completePath"
             style="display:block;">
            {{item.name}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      loading: false,
      tableList: [
        {
          name: "1",
          config: "jj",
          quantity: "数量",
          extra: "其他备注",
        },
      ],
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.purchaseData
        ? this.detail.extra.purchaseData
        : {};
    },
    fileArrivalDate() {
      return moment(this.data.fileArrivalDate);
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .content {
    grid-column-start: 1;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    .item {
      margin-bottom: 20px;
    }
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>

