<template>
  <div id="organize-design-approval">
    <div class="panel">
      <div class="label">工程名称</div>
      <div class="value1">
        {{ data.projectName }}
      </div>

      <div class="label">项目编号</div>
      <div class="value2">
        {{ data.designCode}}
      </div>

      <div class="label">相关附件</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachmentList)">
          <a v-for="(item) in data.attachmentList"
             :key="item.id"
             target="_blank"
             :href="item.completePath"
             style="display:block;">
            {{item.name}}
          </a>
        </div>
      </div>
    </div>

    <Padding size="large" />

    <div class="title">施工组织设计所有审批意见</div>
    <a-table bordered
             :data-source="dataList"
             class="table"
             :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }">
      <a-table-column title="序号">
        <template slot-scope="text, record, index">
          {{ (current - 1) * pageSize + (index + 1) }}
        </template>
      </a-table-column>
      <a-table-column title="审批意见"
                      data-index="review" />
      <a-table-column title="处理意见"
                      data-index="solution" />
    </a-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      total: 0,
      current: 1,
      pageSize: 5,
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.constructionData
        ? this.detail.extra.constructionData
        : {};
    },
    dataList() {
      let content = JSON.parse(this.data.content);

      if (!content) {
        return [];
      }

      content.forEach((item) => {
        item[item.type] = item.comment;
      });

      return content;
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.title {
  border-left: 4px solid #1890ff;
  padding-left: 5px;
  margin-bottom: 10px;
}

.table {
  margin: 10px 40px;
}
</style>

<style lang="less">
#organize-design-approval {
  .ant-table-body {
    th,
    td {
      text-align: center;
    }
  }
}
</style>