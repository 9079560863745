<template>
    <div>
      <div class="panel">
        <div class="label">议题编号</div>
        <div class="value1">{{ extra.number }}</div>
        <div class="label">上报部门</div>
        <div class="value2">{{ extra.deptName }}</div>
        <div class="label">部门领导</div>
        <div class="value1">{{ extra.deptMaster }}</div>
        <div class="label">填报人</div>
        <div class="value2">{{ extra.createBy }}</div>
        <div class="label">议题名称</div>
        <div class="value3">{{ extra.name }}</div>
        <div class="label">议题扼要</div>
        <div class="value3">{{ extra.brief }}</div>
      </div>
      <Padding />
    </div>
  </template>
    
  <script>
  import { mapState } from "vuex";
  import { getName } from "@/utils/clock";
  
  export default {
    computed: {
      ...mapState("approval", ["detail"]),
      extra() {
        return this.detail.extra
          ? {
              ...this.detail.extra,
            }
          : {};
      },
    },
    methods: {
      getName,
  
      renderStaff(str) {
        let msg = "";
        try {
          const list = JSON.parse(str);
          msg = list.map((item) => item.userName).join("，");
        } catch (error) {
          msg = str;
        }
  
        return msg;
      },
    },
  };
  </script>
    
    
  <style lang="less" scoped>
  .panel {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    border-top: 1px solid #e8e8e8;
  
    & > div {
      padding: 8px;
      border-bottom: 1px solid #e8e8e8;
    }
  
    .label {
      background-color: #fafafa;
      border-right: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
  
    .value1 {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  
    .value2 {
      grid-column-start: 5;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  
    .value3 {
      grid-column-start: 2;
      grid-column-end: 7;
      border-right: 1px solid #e8e8e8;
    }
  }
  </style>
    
    