<template>
  <div>
    <div class="panel">
      <div class="label">检查类型</div>
      <div class="value3">
        <DataDictFinder dictType="qualityInspectType" :dictValue="extra.type" />
      </div>

      <div class="label">检查实施单位</div>
      <div class="value1">{{ extra.unitName }}</div>

      <div class="label">检查时间段</div>
      <div class="value2">{{ extra.startTime + " ~ " + extra.endTime }}</div>

      <div class="label">受检单位</div>
      <div class="value3">{{ extra.inspectUnitName }}</div>

      <div class="label">受检项目</div>
      <div class="value3">{{ extra.inspectProjectName }}</div>

      <div class="label">受检部位</div>
      <div class="value3">{{ extra.inspectPart }}</div>
      <div class="label">检查内容</div>
      <div class="value3">{{ extra.inspectContent }}</div>
    </div>


      <div class="card-title">参与检查人员</div>
    <a-table bordered :data-source="memberList" :pagination="false" rowKey="userId">
      <a-table-column title="姓名" data-index="userName" align="center" />
      <a-table-column title="类型" align="center">
        <template slot-scope="text">
          <span v-if="text.type === 'groupLeader'">检查组长</span>
          <span v-if="text.type === 'groupMember'">检查组组员</span>
          <span v-if="text.type === 'unitMember'">受检单位成员</span>
          <span v-if="text.type === 'projectMember'">受检项目人员</span>

          <span v-if="text.type === 'doubleChecker'">复查人</span>
          <span v-if="text.type === 'inspectManager'">检查负责人</span>
          <span v-if="text.type === 'rectifyManager'">整改负责人</span>
          <span v-if="text.type === 'rectifier'">整改人</span>

        </template>
      </a-table-column>
      <a-table-column title="职责分工" data-index="duty" />
    </a-table>


    <div class="card-header">
        <div style="font-weight: bold">受检资料</div>
        <Upload @change='onChange' v-if="ofc_quality_project_member" />
    </div>

    <a-table bordered :data-source="fileList" :pagination="false" rowKey="userId">

            <a-table-column title="文件名">
        <template slot-scope="text">
              <a
                :href="text.url"
                target="_blank"
                style="padding-right: 4px"
              >{{$getFileName(text.url)}}</a>
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center">
        <template slot-scope="text,rows,index">
          <a href="#" class="danger" @click.prevent="remove(index)" v-if="ofc_quality_project_member">删除</a>
        </template>
      </a-table-column>
    </a-table>


  </div>
</template>


<script>
import Upload from './upload.vue'
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Upload
  },

  computed: {
    ...mapState("approval", ["detail"]),
    ...mapGetters('approval', ['ofc_quality_project_member']),
    extra() {
      return this.detail.extra
        ? this.detail.extra
        : {};
    },
    memberList() {
      return Array.isArray(this.extra.memberList) ? this.extra.memberList : [];
    },
    fileList() {
        if ( typeof this.extra.props === 'string' && this.extra.props) {
          return this.extra.props.split(',').map(item => {
            return {
              url: item,
            }
          })
        } else {
          return []
        }
    }
  },
  methods: {
    ...mapMutations('approval', ['setDetail']),
    onChange(payload) {
      console.log(payload)
      const  props = [...this.fileList.map(item => item.url),...payload].join(',');
      console.log('props', props)

      this.setDetail({
        ...this.detail,
        extra: {
          ...this.extra,
          props
        }
      })
    },

    remove(index) {
      console.log(index)

      const arr = [...this.fileList];
      arr.splice(index,1)
      console.log('arr',arr)

          this.setDetail({
            ...this.detail,
            extra: {
              ...this.extra,
              props: arr.map(item => item.url).join(',')
            }
          })

    }
  }
};
</script>
  
  
<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.card-header {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-title {
  padding: 12px 0;
  font-weight: bold;
}

.content {
  padding: 8px;
  border: 1px solid #e8e8e8;
}
</style>