<template>
  <div>
    <div class="panel">
      <div class="label">用户名</div>
      <div class="value3">
        {{ data.code }}
      </div>

      <div class="label">所属公司</div>
      <div class="value3">
        {{ data.company}}
      </div>

      <div class="label">授权委托书</div>
      <div class="value3">
        <div v-if="Array.isArray(data.attachments)">
            <a
                v-for="(item) in data.attachments"
                :key="item.id"
                target="_blank"
                :href="item.completePath" style="display:block;"
            >
                {{item.name}}
            </a>
        </div>
      </div>

      <div class="label">联系人</div>
      <div class="value3">
        {{ data.contact }}
      </div>

      <div class="label">手机号</div>
      <div class="value3">
        {{ data.mobile }}
      </div>

      <div class="label">营业执照</div>
      <div class="value3">
        {{data.licenseCode}}
      </div>
    </div>
    <Padding />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra ? this.detail.extra : {};
    }
  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }
  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

</style>

