<template>
  <div style="margin-bottom: 1em">
    <input
      ref="upload"
      type="file"
      @change="onChange"
      :multiple="true"
      style="display: none"
    />

    <a-button type='primary' @click="onClick" :loading='loading'>上传</a-button>
  </div>
</template>

<script>
import { uploadFile } from "@/api/upload";
export default {

  data() {
    return {
      loading: false
    };
  },

  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("files", files[i]);
      }

      this.loading = true;

      uploadFile(data)
        .then(res => {
          console.log("upload res", res);
          if (Array.isArray(res)) {
            const arr = res.map(item => item.completePath);
            this.$emit('change',arr)
          }
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },

  }
};
</script>