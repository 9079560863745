<template>
  <a-descriptions bordered size="small" :column="2">
    <a-descriptions-item>
      <div slot="label" class="center">单位名称</div>
      <div>
        {{ buyerInfo.name }}
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">客商编码</div>
      <div>
        {{ buyerInfo.code }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">开户行</div>
      <div>
        {{ buyerInfo.bank }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">银行账号</div>
      <div>
        {{ buyerInfo.account }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">开票金额（元）</div>
      <div>
        <span
          v-if="parseFloat(buyerInfo.overPlanReceipt) > 0"
          style="color: #f00"
        >
          {{ buyerInfo.invoiceAmount }}(超额{{ buyerInfo.overPlanReceipt }}元)
        </span>
        <span v-else>
          {{ buyerInfo.invoiceAmount }}
        </span>
      </div>
    </a-descriptions-item>
    <a-descriptions-item>
      <div slot="label" class="center">开票内容</div>
      <div>
        {{ buyerInfo.invoiceContent }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">发票类别</div>
      <div>
        <DataDictFinder
          dictType="invoiceType"
          :dictValue="buyerInfo.invoiceType"
        />
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">纳税人识别号</div>
      <div>
        {{ buyerInfo.taxpayerIdNumber }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">联系电话</div>
      <div>
        {{ buyerInfo.telephone }}
      </div>
    </a-descriptions-item>

    <a-descriptions-item>
      <div slot="label" class="center">联系地址</div>
      <div>
        {{ buyerInfo.address }}
      </div>
    </a-descriptions-item>

       <a-descriptions-item :span="2">
      <div slot="label" class="center">发票备注</div>
      <div>
        {{ buyerInfo.invoiceRemark }}
      </div>
    </a-descriptions-item>



  </a-descriptions>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    buyerInfo() {
      return this.info.buyerInfo ? this.info.buyerInfo : {};
    },
  },
};
</script>