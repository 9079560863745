<template>
  <div>
    <Title>合同额</Title>
    <div class="total">
      <span class="money-color">
        <Money :money="detail.extra.contractData.contractAmount" />
      </span>
      <span class="unit">万元</span>
    </div>

    <Title>分包明细</Title>
    <a-table :data-source="detail.extra.contractData.subPackages" :pagination="false" bordered>
      <a-table-column title="分包名称" data-index="name" />
      <a-table-column title="分包类型">
        <template slot-scope="text">
          <div class="left">
            <DataDictFinder v-if="text.isneeded === 1" dictType="subType" :dictValue="text.type" />
            <DataDictFinder v-if="text.isneeded === 0" dictType="notNeedSubType" :dictValue="text.type" />
            <DataDictFinder v-if="text.isneeded === 2" dictType="generalSubType" :dictValue="text.type" />
            <template v-if="text.major">
              <span> - </span>
              <DataDictFinder dictType="majorTypes" :dictValue="text.major" />
            </template>
          </div>
        </template>
      </a-table-column>
      <a-table-column title="分包界定">
        <template slot-scope="text">
          <div>
            <span v-if="text.isneeded === 0" style="color: #f00">
              设计类-非必要分包
            </span>
            <span v-if="text.isneeded === 1" style="color: #1890ff">
              设计类-必要分包
            </span>
            <span v-if="text.isneeded === 2" style="color: green">
              总承包类分包
            </span>
          </div>
        </template>
      </a-table-column>

      <a-table-column title="公司" data-index="companyName" width="240px" ellipsis="true"></a-table-column>

      <a-table-column title="分包状态" data-index="status" align="center">
        <template slot-scope="status">
          <div>
            <DataDictFinder dictType="subpackageStatus" :dictValue="status" iconType="badge" badgeType="color" />
          </div>
        </template>
      </a-table-column>

      <a-table-column title="分包金额(万元)" data-index="planAmount" align="right" width="100px">
        <template slot-scope="planAmount">
          <Money :money="planAmount" />
        </template>
      </a-table-column>

      <a-table-column key="files" title="附件">
        <template slot-scope="text">
          <span v-if="text.attachments">
            <a v-for="(item, index) in text.attachments.split(',')" :key="index" :href="item" download target="_blank">
              {{ $getFileName(item) }}
            </a>
          </span>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import Title from "../Title";
import { mapState } from "vuex";
export default {
  components: {
    Title,
  },
  computed: {
    ...mapState("approval", ["detail"]),
  },
};
</script>

<style lang="less" scoped>
.total {
  margin-left: 18px;
  font-size: 24px;

  .unit {
    font-size: 12px;
    padding-left: 8px;
  }
}
</style>