<template>
  <a-modal
    title="流程跟踪"
    :visible="visible"
    :footer="null"
    width="1000px"
    @cancel="cancel"
  >
    <Flow v-if="visible" />
    <a-divider />

    <a-table bordered :data-source="allHistory" :pagination="false">
      <a-table-column
        title="发送时间"
        data-index="startTime"
        align="center"
      ></a-table-column>
      <a-table-column
        title="审核人"
        data-index="nextUser"
        align="center"
      ></a-table-column>
      <a-table-column
        title="处理时间"
        data-index="nextTime"
        align="center"
      ></a-table-column>
      <a-table-column title="审批意见" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="taskStatus"
            :dictValue="text.nextOperation"
            iconType="badge"
            badgeType="color"
          />
        </template>
      </a-table-column>
      <a-table-column title="批语" data-index="comment"></a-table-column>
    </a-table>
  </a-modal>
</template>


<script>
import { mapState, mapActions } from "vuex";
import Flow from "./flow.vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Flow,
  },

  watch: {
    visible() {
      if (this.visible) {
        this.getLog();
      }
    },
  },

  computed: {
    ...mapState("approval", ["history"]),

    allHistory() {
      const firstItem = this.history[0] || {};
      const arr = [
        {
          startTime: firstItem.endTime,
          nextUser: firstItem.userName,
          nextTime: firstItem.endTime,
          nextOperation: firstItem.status,
          comment: firstItem.comment,
        },
      ];
      for (let i = 0; i < this.history.length; i++) {
        if (i === this.history.length - 1) {
          break;
        }

        const currentItem = this.history[i];
        let nextItem = {};
        if (i !== this.history.length - 1) {
          nextItem = this.history[i + 1];
        }

        arr.push({
          startTime: currentItem.endTime,
          nextUser: nextItem.userName,
          nextTime: nextItem.endTime ? nextItem.endTime : nextItem.startTime,
          nextOperation: nextItem.status,
          comment: nextItem.comment,
        });
      }
      return arr;
    },
  },

  methods: {
    ...mapActions("approval", ["getLog"]),

    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>