<template>
  <div>
    <Title>阶段可分配数值</Title>
    <table class="table" cellspacing="0">
      <tr>
        <th>阶段名称</th>
        <th class="text-right">阶段占比</th>
        <th class="text-right">阶段可分配数值(万元)</th>
        <th class="text-right">人员</th>
        <th class="text-right">汇总可分配数值(万元)</th>
      </tr>
      <template v-for="(item, idx) in newList">
        <template v-if="Array.isArray(item.staffOutputList)">
          <tr
            v-for="(staff, index) in item.staffOutputList"
            :key="`${idx}-${index}`"
          >
            <td :rowspan="item.staffOutputList.length" v-if="index === 0">
              <span>
                <DataDictFinder
                  dictType="allocateType"
                  :dictValue="item.majorCode"
                />
                {{ item.majorCode === "other" ? "专业占比" : "" }}
                {{ item.majorCode === "合计" ? "合计" : "" }}
              </span>
            </td>

            <td
              class="text-right"
              :rowspan="item.staffOutputList.length"
              v-if="index === 0"
            >
              <span> {{ item.typeRatio }}% </span>
            </td>

            <td
              class="text-right money-color"
              :rowspan="item.staffOutputList.length"
              v-if="index === 0"
            >
              <span>
                <Money :money="item.majorAmount" />
              </span>
            </td>

            <td class="text-right">
              {{ staff.userName ? staff.userName : "--" }}
            </td>
            <td class="text-right money-color">
              <Money :money="staff.allocateAmount" />
            </td>
          </tr>
        </template>
      </template>
    </table>
  </div>
</template>

<script>
import Title from "../../Title";

export default {
  components: {
    Title,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    newList() {
      const arr = this.list.map((item) => {
        let staffList = item.staffOutputList;
        if (Array.isArray(staffList) && staffList.length > 0) {
          console.log("");
        } else {
          staffList = [{}];
        }
        return {
          ...item,
          staffOutputList: staffList,
        };
      });

      let typeRatio = 0;
      let majorAmount = 0;
      let staffMoney = 0;
      arr.forEach((element) => {
        typeRatio += element.typeRatio;
        majorAmount += element.majorAmount;

        // element.staffOutputList.forEach((staff) => {
        //   if (staff.allocateAmount) {
        //     staffMoney += staff.allocateAmount;
        //   }
        // });
      });
      return [
        ...arr,
        {
          majorCode: "合计",
          typeRatio: Math.floor(typeRatio),
          majorAmount: Math.round(majorAmount),
          staffOutputList: [
            {
              allocateAmount: Math.round(staffMoney),
            },
          ],
        },
      ];
    },

    lastElement() {
      if (this.list.length > 0) {
        return this.list[this.list.length - 1];
      } else {
        return {};
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table {
  width: 100%;
  th,
  td {
    padding: 8px;
    border: 1px solid #d9d9d9;
    min-width: 100px;
  }
  th {
    background-color: #f9f9f9;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
}
</style>