<template>
  <div class="form-header center">
    <img class="logo" src="@/assets/small-logo.png" alt="logo" />
    <div class="title">
      <div>南京市市政设计研究院有限责任公司</div>
      <DataDictFinder
        dictType="taskType"
        :dictValue="detail.processDefinitionKey"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
  },
  mounted(){
    console.log(this.detail)
    

  }
};
</script>

<style lang="less" scoped>
.form-header {
  .logo {
    height: 120px;
    margin-right: 30px;
  }
  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>