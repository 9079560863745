<template>
  <div>
    <div class="panel">
      <div class="label">检查类型</div>
      <div class="value3">
        <DataDictFinder dictType="qualityInspectType" :dictValue="extra.type" />
      </div>

      <div class="label">检查实施单位</div>
      <div class="value1">{{ extra.unitName }}</div>

      <div class="label">检查时间段</div>
      <div class="value2">{{ extra.startTime + " ~ " + extra.endTime }}</div>

      <div class="label">受检单位</div>
      <div class="value3">{{ extra.inspectUnitName }}</div>

      <div class="label">受检项目</div>
      <div class="value3">{{ extra.inspectProjectName }}</div>

      <div class="label">检查实施依据</div>
      <div class="value3">{{ extra.evidence }}</div>
    </div>

    <div class="card-title">参与检查人员</div>
    <a-table bordered :data-source="memberList" :pagination="false" rowKey="userId">
      <a-table-column title="姓名" data-index="userName" align="center" />
      <a-table-column title="类型" align="center">
        <template slot-scope="text">
          <span v-if="text.type === 'groupLeader'">检查组长</span>
          <span v-if="text.type === 'groupMember'">检查组组员</span>
          <span v-if="text.type === 'unitMember'">受检单位成员</span>
          <span v-if="text.type === 'projectMember'">受检项目人员</span>
          <span v-if="text.type === 'doubleChecker'">复查人</span>
          <span v-if="text.type === 'inspectManager'">检查负责人</span>
          <span v-if="text.type === 'rectifyManager'">整改负责人</span>
          <span v-if="text.type === 'rectifier'">整改人</span>
        </template>
      </a-table-column>
      <a-table-column title="职责分工" data-index="duty" />
    </a-table>

    <div class="card-title">检查计划</div>
    <div class="content">{{ extra.planContent }}</div>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra
        ? {
            ...this.detail.extra,
            attachments: this.detail.extra.attachments
              ? this.detail.extra.attachments.split(",")
              : []
          }
        : {};
    },
    memberList() {
      return Array.isArray(this.extra.memberList) ? this.extra.memberList : [];
    }
  }
};
</script>
  
  
<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}

.card-title {
  padding: 12px 0;
  font-weight: bold;
}

.content {
  padding: 8px;
  border: 1px solid #e8e8e8;
}
</style>