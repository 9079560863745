<template>
  <div>
    <div class="panel">
      <div class="label">发起人</div>
      <div class="value1">{{ extra.applicant }}</div>
      <div class="label">发起部门</div>
      <div class="value2">{{ extra.applicantDept }}</div>

      <div class="label">接收人</div>
      <div class="value1">{{ extra.receiver }}</div>
      <div class="label">接收部门</div>
      <div class="value2">{{ extra.receiverDept }}</div>

      <div class="label">协作内容</div>
      <div class="value3" style="line-height: 1.8">
        <div v-html="extra.remark"></div>
      </div>

      <div class="label">附件</div>
      <div class="value3">
        <span v-for="(file, index) in extra.attachment" :key="file">
          <a :href="file" target="_blank">{{ $getFileName(file) }}</a>
          <span style="color: #1890ff" v-if="index !== extra.attachment.length - 1">，</span>
        </span>
      </div>
    </div>
    <Padding />
  </div>
</template>
  
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("approval", ["detail"]),
    extra() {
      return this.detail.extra
        ? {
            ...this.detail.extra,
            remark: this.detail.extra.remark.replace(/\n/gi, "<br>"),
            attachment: this.detail.extra.attachment
              ? this.detail.extra.attachment.split(",")
              : []
          }
        : {};
    }
  }
};
</script>
  
  
<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }
}
</style>
  
  