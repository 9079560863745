<template>
    <div>
        <a-table bordered :dataSource="list" :pagination="false">
            <a-table-column title="文件名">
                <template slot-scope="text">
                    <a :href="text.completePath" target="_blank">
                        {{ text.name }}
                    </a>
                </template>
            </a-table-column>
        </a-table>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => []
        }
    }
}
</script>