<template>
  <div class="panel">
    <div class="label title2 bd-r-none">
      <div class="flex-2 center flex">
        {{detail.type}}
        分包合同编号
      </div>
      <span class="flex-1"></span>
    </div>
    <div class="label title3" style="padding:0;">{{ detail.code }}</div>
    <div class="label">分包合同名称</div>
    <div class="value1">{{ detail.name }}</div>
    <div class="label">分包单位</div>
    <div class="value2">{{ detail.org }}</div>
    <div class="label">付款条件</div>
    <div class="value1">{{ detail.payCondition }}</div>
    <div class="label">合同金额(元)</div>
    <div
      class="value2"
    >{{ detail.contractAmt ? Number(detail.contractAmt).toLocaleString() : detail.contractAmt }}</div>
    <div class="label">累计收票金额(元)</div>
    <div
      class="value1"
    >{{ detail.sumInvoiceAmt ? Number(detail.sumInvoiceAmt).toLocaleString() : detail.sumInvoiceAmt }}</div>
    <div class="label">累计支付金额(元)</div>
    <div
      class="value2"
    >{{ detail.sumPayedAmt ? Number(detail.sumPayedAmt).toLocaleString() : detail.sumPayedAmt }}</div>
    <div class="label">支付比例</div>
    <div class="value1">{{ payRatio }}%</div>
    <div class="label">待支付金额(元)</div>
    <div class="value2">{{ detail.amtDue ? Number(detail.amtDue).toLocaleString() : detail.amtDue }}</div>
  </div>
</template>

<script>
import { formatRatio } from "@/utils/formatRatio.js";
export default {
  props: {
    detail: {
      //初始值
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    payRatio() {
      if (
        typeof this.detail.sumPayedAmt === "number" &&
        typeof this.detail.contractAmt === "number"
      ) {
        return formatRatio(this.detail.sumPayedAmt, this.detail.contractAmt);
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;

  & > div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .title2 {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .title3 {
    grid-column-start: 4;
    grid-column-end: 7;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value1,
  .value2,
  .value3 {
    padding: 0;
  }
}
</style>
