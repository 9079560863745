<template>
  <div>
    <div class="title">审批详情</div>

    <a-form-item label="整改情况"
                 :label-col="{span:2}"
                 :wrapper-col="{span:22}">
      <a-input v-model="inputValue"
               @change="inputChange" />
    </a-form-item>
    <a-form-item :label-col="{ span: 2 }"
                 :wrapper-col="{ span: 22 }"
                 class="file-container">
      <span slot="label"
            class="">相关附件</span>
      <div class="link-list">
        <a-checkbox-group :value="selectedFileList"
                          @change="onSelectFile">
          <div class="link"
               v-for="item in approvalList"
               :key="item.id">
            <a-checkbox :value="item.id">
              <a target="_blank"
                 download
                 :href="item.completePath">{{
                  item.name}}</a>
            </a-checkbox>
          </div>
        </a-checkbox-group>

        <div class="control-bar">
          <FileUpload @uploaded="uploaded">
            <div class="control">
              <a-icon type="upload" />
            </div>
          </FileUpload>

          <div class="control"
               @click="deleteFile">
            <a-icon type="delete" />
          </div>
        </div>
      </div>
    </a-form-item>
  </div>
</template>
<script>
import FileUpload from "@/components/file-upload";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      // fileList: [], // 审批详情的附件列表
      selectedFileList: [],
      inputValue: "",
    };
  },
  computed: {
    ...mapState("approval", ["detail"]),
    ...mapState("approvalDetail", ["approvalList"]),
    data() {
      return this.detail.extra.constructionData
        ? this.detail.extra.constructionData
        : {};
    },
  },
  mounted() {
    // 回显审批详情
    console.log(this.data);

    // 设置vuex初始值，用于本次提交
    this.inputValue = this.data.rectificationMeasure || "";
    this.setRectificationMeasure(this.inputValue);

    // 返回只包含type为approvalDetail的元素
    let fileList =
      this.data.attachmentList?.filter((item) => {
        return item.type === "approvalDetail";
      }) || [];
    this.setAttachment(fileList);
  },
  methods: {
    ...mapMutations("approvalDetail", [
      "setRectificationMeasure",
      "setAttachment",
      "delApprovalFile",
    ]),
    inputChange() {
      this.setRectificationMeasure(this.inputValue);
    },
    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      console.log(list);
      let fileList = [...this.approvalList, ...list];
      this.setAttachment(fileList);
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.approvalList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.delApprovalFile(index);
        }
      });
      this.selectedFileList = [];
    },
  },
  destroyed() {
    this.setRectificationMeasure("");
    this.setAttachment([]);
  },
};
</script>
<style lang="less" scoped>
.file-container {
  display: flex;
  align-items: center;
}

.title {
  border-left: 4px solid #1890ff;
  padding-left: 5px;
  margin: 16px 0 10px;
}
</style>
