<template>
  <div>
    <a-descriptions bordered size="small" :column="2">
      <a-descriptions-item :span="2">
        <div slot="label" class="center">退换票原因</div>
        <div>
          {{ info.refundChangeInfo.reason }}
        </div>
      </a-descriptions-item>
    </a-descriptions>

    <Padding />

    <div v-if="Array.isArray(info.refundChangeInfo.oldInvoices)">
      <a-table
        bordered
        :data-source="info.refundChangeInfo.oldInvoices"
        :pagination="false"
      >
        <a-table-column title="原发票号码" data-index="oldInvoiceNo" />
        <a-table-column title="原发票金额(元)" align='right'>
          <template slot-scope="text">
            <span v-if="parseFloat(text.oldInvoicePrice) > 0" style="color: #1890ff">
              {{ parseFloat(text.oldInvoicePrice).toLocaleString() }}
            </span>
            <span v-else style="color: red">
                  {{ parseFloat(text.oldInvoicePrice).toLocaleString() }}
            </span>
          </template>
        </a-table-column>
        <a-table-column title="原开票单位" data-index="oldInvoiceBuyer" />
        <a-table-column title="原开票内容" data-index="oldInvoiceContenet" />

        <a-table-column title="退票类型" data-index="refundType" v-if="info.refundChangeInfo.oldInvoices.length > 0 && info.refundChangeInfo.oldInvoices[0].refundType" />
        <a-table-column title="退票金额(元)"  align='right' v-if="info.refundChangeInfo.oldInvoices.length > 0 && info.refundChangeInfo.oldInvoices[0].refundType">
          <template slot-scope="text">
                    <span  style="color: red">
              {{ parseFloat(text.refundPrice).toLocaleString() }}
            </span>
          </template>
        </a-table-column>

      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>