<template>
  <div class="panel">
    <div class="label">工程名称</div>
    <div class="value1">
      {{ data.projectName }}
    </div>

    <div class="label">项目编号</div>
    <div class="value2">
      {{ data.designCode }}
    </div>

    <div class="label">合同名称</div>
    <div class="value1">
      {{ subpackExpenseApp.contractName }}
    </div>

    <div class="label">合同编号</div>
    <div class="value2">
      {{ subpackExpenseApp.contractCode }}
    </div>

    <div class="label">主要内容</div>
    <div class="value3 desc">
      <p>1、我公司与
        <span class="underline"> {{ subpackExpenseApp.owner }} </span>
        (发包方)签订的该工程合同总价款为
        <span class="underline"> {{ subpackExpenseApp.contractAmt }} </span>
        万元，目前我公司已收到工程费
        <span class="underline"> {{ subpackExpenseApp.engFeeReceived }} </span>
        万元，合计收到工程费
        <span class="underline"> {{ subpackExpenseApp.engFeeSum }} </span>
        万元。
      </p>
      <p> 2、根据
        <span class="underline"> {{ subpackExpenseApp.contractor }} </span>（分包方）与我公司签订的
        <span class="underline"> {{ subpackExpenseApp.subcontractName }} </span>分包合同，其总价款为
        <span class="underline"> {{ subpackExpenseApp.subcontractAmt }} </span>万元，已支付
        <span class="underline"> {{ subpackExpenseApp.subcontractPaidAmt }} </span>万元，根据支付方式，此次需要支付
        <span class="underline"> {{ subpackExpenseApp.amtDueCurrent }} </span>万元，扣除税费，
        <span class="underline"> {{ subpackExpenseApp.taxPayable }} </span>万元，实际支付
        <span class="underline"> {{ subpackExpenseApp.amtDueActual }} </span>万元，请领导审核批准。
      </p>

    </div>

    <div class="label">相关附件</div>
    <div class="value3">
      <div v-if="Array.isArray(data.attachmentList)">
        <a v-for="(item) in data.attachmentList" :key="item.id" target="_blank" :href="item.completePath"
          style="display:block;">
          {{ item.name }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("approval", ["detail"]),
    data() {
      return this.detail.extra.balanceData ? this.detail.extra.balanceData : {};
    },
    subpackExpenseApp() {
      return this.data.subpackExpenseApp;
    },
  },
};
</script>


<style lang="less" scoped>
.panel {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #e8e8e8;
  margin-bottom: 8px;

  &>div {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
  }

  .title {
    grid-column-start: 1;
    grid-column-end: 7;
    background-color: #afc9ed;
    text-align: center;
  }

  .label {
    background-color: #fafafa;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .value1 {
    grid-column-start: 2;
    grid-column-end: 4;
  }

  .value2 {
    grid-column-start: 5;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .value3 {
    grid-column-start: 2;
    grid-column-end: 7;
    border-right: 1px solid #e8e8e8;
  }

  .title2 {
    grid-column-start: 1;
    grid-column-end: 7;
    background-color: #fff;
    text-align: center;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }

  .border-left {
    border-left: 1px solid #e8e8e8;
  }

  .border-right {
    border-right: 1px solid #e8e8e8;
  }
}

.underline {
  padding: 2px 0;
  border-bottom: 1px solid #000;
}

.desc p {
  text-indent: 2em;
}
</style>
