<template>
  <div>
    <span>
      <slot />
    </span>

    <a-modal title="加签"
             :visible="visible"
             :footer="null"
             @cancel="show">
      <a-form :form="form"
              @submit="handleSubmit">
        <a-form-item label="选择加签方式">
          <a-radio-group @change="onRadioSelect"
                         v-decorator="[
              'signType',
              {
                initialValue: signType,
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]">
            <a-radio v-for="item in signTypeList"
                     :key="item.value"
                     :value="item.value"
                     style="margin-bottom: 10px">
              {{ item.name }}
              <img :src="item.url"
                   style="width: 120px; height: 50px; margin-left: 30px" />
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="审批后是否结束任务"
                     v-show="isSet">
          <a-switch checked-children="是"
                    un-checked-children="否"
                    v-decorator="[
              'onSet',
              {
                initialValue: false,
                valuePropName: 'checked',
              },
            ]"></a-switch>
        </a-form-item>

        <EmployeeSelector :single="false"
                          @change="onPersonSelect"
                          :value="assignees">
          <a-button type="primary"
                    size="small"
                    class="button">
            选择成员{{ assigneesLen > 0 ? `(${assigneesLen})` : "" }}
          </a-button>
          <span style="margin-left: 10px"
                v-if="assigneesLen > 0">{{
            assigneesName
          }}</span>
        </EmployeeSelector>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary"
                      html-type="submit">确定</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import EmployeeSelector from "@/components/employee-selector";
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    value: {
      // 默认值
      type: Array,
      default: () => [],
    },
  },
  components: {
    EmployeeSelector,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      signTypeList: [
        {
          name: "会签",
          value: "counter_sign",
          url: require("@/assets/approval-assign/meet.png"),
        },
        {
          name: "串行加签",
          value: "sequential_sign",
          url: require("@/assets/approval-assign/line.png"),
        },
        {
          name: "并行加签",
          value: "parallel_sign",
          url: require("@/assets/approval-assign/together.png"),
        },
      ],
      isSet: false, //是否显示选择按钮（设置是否立即结束当前任务，不结束则可查看会签的其他人的审核记录）
    };
  },

  watch: {
    visible() {
      if (this.signType === "counter_sign") {
        this.isSet = true;
      }
    },
  },

  computed: {
    ...mapState("approval", ["assignees", "signType"]),

    ...mapState("employeeSelector", ["list"]),

    assigneesName() {
      if (!Array.isArray(this.assignees)) {
        return "";
      }
      return this.assignees.map((item) => item.name).join(",");
    },
    assigneesLen() {
      return this.assignees?.length || 0;
    },
  },
  mounted() {
    if (this.list.length === 0) {
      this.getList().then(() => {
        this.getSignData();
      });
    } else {
      this.getSignData();
    }
  },

  methods: {
    ...mapActions("approval", ["getSign"]),
    ...mapMutations("approval", ["setSignUser", "setSignType", "setOnSet"]),

    ...mapActions("employeeSelector", ["getList"]),

    getSignData() {
      this.getSign().then((res) => {
        if (res) {
          if (res.signType === "counter_sign") {
            this.isSet = true;
          }
          if (Array.isArray(res.assignees)) {
            this.setSignUser(
              res.assignees.map((id) => {
                return {
                  userId: id,
                  name: this.list.find((element) => element.userId === id)
                    ?.name,
                };
              })
            );
          }
          this.setSignType(res.signType || "");
        }
      });
    },
    onPersonSelect(arr) {
      this.setSignUser(arr);
    },
    onRadioSelect(e) {
      console.log(e.target.value);
      if (e.target.value === "counter_sign") {
        this.isSet = true;
      } else {
        this.isSet = false;
      }
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        // 若不为会签，则onset默认为true
        if (values.signType !== "counter_sign") {
          values.onSet = true;
          this.setOnSet(true);
        }
        if (!err) {
          this.setSignType(values.signType);
          this.setOnSet(values.onSet);
          this.$emit("show");
        }
      });
    },
    cancel() {
      this.form.resetFields();

      this.$emit("show");
    },
    show() {
      this.$emit("show");
    },
  },
};
</script>
<style lang="less" scoped>
</style>
